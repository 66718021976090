import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Spinner from '../ProgressBar/Spinner';
import { resetPassword } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import "./ResetForm.css";
import { useLocation, useHistory } from 'react-router-dom';
import QueryString from 'qs';

function ResetForm() {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const [values, setValues] = useState({
      password: '',
      confirmPassword: ''
  });
  const [open, setOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({status:'', genericMessage:'', errors:''});
  const [isPaswordReset, setPasswordReset] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  

    const resetPass = (event) => {
        event.preventDefault();

        let paramsData = QueryString.parse(search, { ignoreQueryPrefix: true });
        
        if(!values.password || !values.confirmPassword)
        {
            setOpen(true);
            setUploadResponse({status:'error', genericMessage: ['FORM_FIELD_DATA_MISSING']});
            return;
        }
        else if(values.password.length < 6)
        {
          setOpen(true);
          setUploadResponse({status:'error', genericMessage: ["Password length must be atleast 6 characters"]});
          return;
        }
        else if(values.password !== values.confirmPassword){
            setOpen(true);
            setUploadResponse({status:'error', genericMessage: ["Passwords you entered don't match"]});
            return;
        }
        
        setLoading(true);
        
        dispatch(resetPassword({Password: values.password, AccessCode: paramsData.accessCode}))
          .unwrap()
          .then((response) => {
            setUploadResponse(response);
            setOpen(true);
            setLoading(false);
            setPasswordReset(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setUploadResponse(error)
            setOpen(true);
            setPasswordReset(false);
          });
    }

    const handleRedirect = (event) => {
      event.preventDefault();
      history.push('/login');
    };

    const handleInputChange = (event) => {
      let formValues = values;
      switch (event.target.id) {
        case 'password':
          formValues.password = event.target.value;
          break;
        case 'confirmPassword':
          formValues.confirmPassword = event.target.value;
          break;
        default:
          break;
      }
      setValues(formValues);
    };

    return (
      <div>
        { loading?<Spinner/>:null }
        <div className="resetForm">
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={uploadResponse && uploadResponse.status === 'SUCCESS' ? 'success' : 'error'} sx={{ width: '100%' }}>
                  {t(uploadResponse.genericMessage ? uploadResponse.genericMessage : "Something went wrong, Please try again.")}
              </Alert>
          </Snackbar>

            <div className="resetFormContent">
                {
                  !isPaswordReset &&
                  <form onSubmit={resetPass} autoComplete='off'>
                      <h1>Reset Password</h1>
                      <input 
                          required
                          type="password"
                          placeholder="Enter Password"
                          id='password'
                          onChange={handleInputChange}
                      />
                      <input 
                          required
                          type="password"
                          placeholder="Confirm Password"
                          id='confirmPassword'
                          onChange={handleInputChange}
                      />
                      <button type="submit">Reset Now</button>
                  </form>
                }

                {
                  isPaswordReset &&
                  <form onSubmit={handleRedirect}>
                    <h1>Success!</h1>
                    <p>The password has been successfully reset, Please try to login now.</p>
                    <button type="submit">Login Now</button>
                  </form>
                }
            </div>
            <div className="resetFormFadeBottom" />
        </div>
      </div>
        
    )
}

export default ResetForm
