import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar , InputLabel, MenuItem, Select ,TextField, Checkbox, ListItemText } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import {useTranslation} from "react-i18next";
import './UploadEvent.css';
import userService from '../../services/user.service';
import { getTeams, getInstructors, getVideoSubType } from '../../slices/uploadData';
import Spinner from '../ProgressBar/Spinner';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getAllTags } from '../../slices/tags';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function UploadLive() {
    const {t} = useTranslation();
    const [days, setDays] = useState([]);
    const [formData, setFormData] = useState({IsRecurringEvent: false});
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date());
    const [endtime, setEndTime] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({status:'',successMessage:'',errors:[]});
    const token  = useSelector((state) => state.message.token);
    const Teams = useSelector((state) => state.uploadData.teams);
    const { tags } = useSelector((state) => state.allTags);
    const Instructors = useSelector((state) => state.uploadData.instructors);
    let videoSubTypes = useSelector((state) => state.uploadData.subtypes);
    const { isLoading } = useSelector((state) => state.uploadData);
    const dispatch=useDispatch();

    const setTagsNameData = (selected) => {
        let namesArray=[];
        
        selected.forEach(item => {
            tags.forEach(obj => {
                if(item===obj.id)
                    namesArray.push(t(obj.name))
            })
        })
        return namesArray.join(", ");
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    useEffect(() => {
      dispatch(getTeams());
      dispatch(getInstructors());
      dispatch(getAllTags());
      // eslint-disable-next-line
    },[token]);

    useEffect(() => {
      if(formData.videoTypeId !== undefined){
        dispatch(getVideoSubType(formData.videoTypeId));
      }
      // eslint-disable-next-line
    }, [formData.videoTypeId, token])

    useEffect(() => {
      if(formData.IsRecurringEvent) {
      setFormData({...formData,StartTime: time, EndTime: endtime})
      }
    },[formData.IsRecurringEvent, token])

    const onOptionChange = (event,id) => {
        switch (id) {
            case 'event-title':
                setFormData({...formData, VideoLiveEventTitle: event.target.value})
                break;
            case 'video-url':
                setFormData({...formData, VideoLiveEventEmbedUrl: event.target.value})
                break;
            case 'instructor-select':
                setFormData({...formData, UserId: event.target.value})
                break;
            case 'type-select':
                setFormData({...formData, videoTypeId: event.target.value, videoSubTypeId: ''})
                break;
            case 'select-subType':
                setFormData({...formData, videoSubTypeId: event.target.value})
                break;
            case 'language-select':
                setFormData({...formData, language: event.target.value})
                break;
            case 'date-picker':
                setDate(event);
                setFormData({...formData, VideoLiveEventDate: event, IsRecurringEvent: false})
                break;
            case 'time-picker':
                setTime(event);
                console.log("Event Value: ",event)
                setFormData({...formData, StartTime: event})
                break;
            case 'time-picker-end-time':
                setEndTime(event);
                setFormData({...formData, EndTime: event})
                break;
            case 'note-input':
                setFormData({...formData, notes: event.target.value})
                break;
            case 'tag-select':
              const {
                target: { value },
              } = event;
              setFormData({...formData, tagList: typeof value === 'string' ? value.split(',') : value})  
              console.log("Form Data: ",formData);
                break;
            case 'select-days':
                setDays(typeof value === 'string' ? event.target.value.split(',') : event.target.value);
                setFormData({...formData, VideoLiveEventDays: typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value})
                break;
            case 'is-recurring':
                setFormData({...formData, IsRecurringEvent: event.target.checked})
                break;
            case 'difficulty-select':
                setFormData({...formData, videoLevelId: event.target.value})
                break;
            default:
                break;
        }
    };

    const uploadEvent = (event) => {
      event.preventDefault();
      if (
        !formData.VideoLiveEventTitle ||
        !formData.VideoLiveEventEmbedUrl ||
        !formData.videoTypeId ||
        !time ||
        formData.IsRecurringEvent ? !formData.VideoLiveEventDays : !date ||
        !formData.VideoLiveEventDate ||
        !formData.language
      ) {
        setOpen(true);
        setUploadResponse({
          status: "error",
          errors: ["FORM_FIELD_DATA_MISSING"],
        });
        return;
      }

      if(formData.IsRecurringEvent) {
        formData.VideoLiveEventDate = time.toISOString();
      } else if(!formData.IsRecurringEvent) {
        formData.VideoLiveEventDate =  date.toISOString().substring(0, 10) + time.toISOString().substring(10)
      }

      if(formData.VideoLiveEventDays){
        let temp = [];
        formData.VideoLiveEventDays.forEach(element => {
          temp.push((formData.VideoLiveEventDays.indexOf(element)+1).toString());
        });
        formData.VideoLiveEventDays = temp;
      }
         
      userService
        .uploadLiveEvent(formData)
        .then((response) => {
          setFormData({});
          setTime("");
          setEndTime("");
          setDays([]);
          setUploadResponse(response.data);
          setOpen(true);
          videoSubTypes="";
        })
        .catch((error) => {
          console.log(error);
          setUploadResponse(error.response.data);
          setOpen(true);
        });
    };

    const eventDays = ["Monday","Tuesday","Wednesday","Thursday","Friday"];

        return (
          <div>
            { isLoading ? <Spinner/> : null }
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={
                  uploadResponse && uploadResponse.status === "SUCCESS"
                    ? "success"
                    : "error"
                }
                sx={{ width: "100%" }}
              >
                {t(
                  uploadResponse.status === "SUCCESS"
                    ? uploadResponse.successMessage
                    : uploadResponse.errors[0]
                )}
              </Alert>
            </Snackbar>
            <form
              className='upload-form'
              noValidate
              autoComplete='off'
              onSubmit={uploadEvent}
            >
              <div className='align-items-center'>
                <InputLabel id='live-event-label' className='required'>
                  {t("Title")}
                </InputLabel>
                <TextField
                  required
                  className='upload-form-input'
                  placeholder={t("Event Title")}
                  value={
                    formData.VideoLiveEventTitle
                      ? formData.VideoLiveEventTitle
                      : ""
                  }
                  inputProps={{ "aria-label": "description" }}
                  id='event-title'
                  onChange={(event) => onOptionChange(event, "event-title")}
                />
              </div>
              <div className='align-items-center'>
                <InputLabel id='url-key-label' className='required'>
                  {t("URL key")}
                </InputLabel>
                <TextField
                  required
                  className='upload-form-input'
                  placeholder={t("URL key")}
                  value={
                    formData.VideoLiveEventEmbedUrl
                      ? formData.VideoLiveEventEmbedUrl
                      : ""
                  }
                  inputProps={{ "aria-label": "description" }}
                  id='video-url'
                  onChange={(event) => onOptionChange(event, "video-url")}
                />
              </div>
              <div className='align-items-center'>
                        <InputLabel id="instructor-select-label" className="required">{t('Instructor')}</InputLabel>
                        <Select
                        placeholder={t('Instructor')}
                        labelid="instructor-select-label"
                        id="instructor-select"
                        className="select-input"
                        value={formData.UserId ? formData.UserId : ""}
                        onChange={(event) => onOptionChange(event,'instructor-select')}
                        required
                        displayEmpty
                        renderValue={(selected) => {
                          let instructor;
                          if ((selected === undefined || selected.length === 0) && !formData.UserId) {
                            return <>{t('Select Instructor')}</>;
                          } else if(formData.UserId){
                              instructor = Instructors && Instructors.find(element => {
                                  return element.id === formData.UserId;
                              });
                          } else if(formData.UserId && !selected){
                              instructor = Instructors && Instructors.find(element => {
                                  return element.id === formData.UserId;
                              });
                          }
                          return t(instructor.firstName) +" "+ t(instructor.lastName);
                        }}
                        >
                            {Instructors.map((element,index) => {
                                return <MenuItem key={index} value={element.id}>{element.firstName} {element.lastName}</MenuItem>
                            })}
                        </Select>
                    </div>
              <div className='align-items-center'>
                <InputLabel id='type-select-label' className='required'>
                  {t("Type")}
                </InputLabel>
                <Select
                  labelid='type-select-label'
                  id='type-select'
                  className='select-input'
                  value={formData.videoTypeId ? formData.videoTypeId : ""}
                  onChange={(event) => onOptionChange(event, "type-select")}
                  required
                  displayEmpty
                  renderValue={(selected) => {
                    let type;
                    if ((selected === undefined || selected.length === 0) && !formData.videoTypeId) {
                      return <>{t('Select Type')}</>;
                    } else if(formData.videoTypeId){
                        type = Teams && Teams.find(element => {
                            return element.id === formData.videoTypeId;
                        });
                    } else if(formData.videoTypeId && !selected){
                        type = Teams && Teams.find(element => {
                            return element.id === formData.videoTypeId;
                        });
                    }
                    return `${t(type.type)}`;
                  }}
                >
                  {Teams &&
                    Teams.length > 0 &&
                    Teams.map((element, index) => {
                      return (
                        <MenuItem key={index} value={element.id}>
                          {t(element.type)}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>

              {videoSubTypes && videoSubTypes.length > 0 &&
                <div className='align-items-center'>
                  <InputLabel id='type-select-label'>
                    {t("Sub Type")}
                  </InputLabel>
                  <Select
                    labelid='type-select-label'
                    id='type-select'
                    className='select-input'
                    value={formData.videoSubTypeId ? formData.videoSubTypeId : ""}
                    onChange={(event) => onOptionChange(event, "select-subType")}
                    required
                    displayEmpty
                            renderValue={(selected) => {
                                let subType;
                                if ((selected === undefined || selected.length === 0) && !formData.videoSubTypeId) {
                                  return <>{t('Select Sub Type')}</>;
                                } else if(formData.videoSubTypeId && selected){
                                    subType = videoSubTypes.find(element => {
                                        return element.id === selected && selected === formData.videoSubTypeId;
                                    });
                                } else if(formData.videoSubTypeId && !selected){
                                    subType = videoSubTypes.find(element => {
                                        return element.id === formData.videoSubTypeId;
                                    });
                                }
                                return `${t(subType.subType)}`;
                              }}
                  >
                    {videoSubTypes &&
                      videoSubTypes.length > 0 &&
                      videoSubTypes.map((element, index) => {
                        return (
                          <MenuItem key={index} value={element.id}>
                            {t(element.subType)}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              }

              <div className='align-items-center'>
                <InputLabel id='language-select-label' className='required'>
                  {t("Language")}
                </InputLabel>
                <Select
                  labelid='language-select-label'
                  id='language-select'
                  className='select-input'
                  value={formData.language ? formData.language : ""}
                  onChange={(event) => onOptionChange(event, "language-select")}
                  required
                  displayEmpty
                  renderValue={(selected) => {
                    let language;
                    if ((selected === undefined || selected.length === 0) && !formData.language) {
                      return <>{t('Select Language')}</>;
                    } else if(formData.language && selected && formData.language === selected){
                        language = formData.language === 'da' ? 'Danish' : 'English'
                    } else if(formData.language && !selected){
                        language = formData.language === 'da' ? 'Danish' : 'English'
                    }
                    return `${t(language)}`;
                }}
                >
                  <MenuItem value='en'>{t("English")}</MenuItem>
                  <MenuItem value='da'>{t("Danish")}</MenuItem>
                </Select>
              </div>

              <div className='align-items-center'>
                <InputLabel id='date-select-label' className='required'>
                  {t("Start Time")}
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    id='time-picker'
                    onChange={(event) => onOptionChange(event, "time-picker")}
                    value={
                      formData.StartTime === undefined ||
                      formData.StartTime === ""
                        ? ""
                        : time
                    }
                    inputFormat='hh:mm'
                    required
                    renderInput={(params) => (
                      <TextField
                        className='upload-form-date-picker'
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className='align-items-center'>
                <InputLabel id='date-select-label' className='required'>
                  {t("End Time")}
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    id='time-picker'
                    onChange={(event) => onOptionChange(event, "time-picker-end-time")}
                    value={
                      formData.EndTime === undefined ||
                      formData.EndTime === ""
                        ? ""
                        : endtime
                    }
                    inputFormat='hh:mm'
                    required
                    renderInput={(params) => (
                      <TextField
                        className='upload-form-date-picker'
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div
                className='align-items-center'
                style={{ marginRight: "347px" }}
              >
                <InputLabel id='isRecurring-label'>
                  {t("Is Recurring")}
                </InputLabel>
                <Checkbox
                  onChange={(event) => onOptionChange(event, "is-recurring")}
                />
              </div>
              {!formData.IsRecurringEvent ? 
                <div className='align-items-center'>
                          <InputLabel id="date-select-label" className="required">{t('Event Date')}</InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  id="date-picker"
                                  inputFormat="dd/MM/yyyy"
                                  onChange={(event) => onOptionChange(event,'date-picker')}
                                  value={formData.VideoLiveEventDate === undefined || formData.VideoLiveEventDate === "" ? "" : date}
                                  required
                                  renderInput={(params) => <TextField className='upload-form-date-picker' {...params} />}
                              />
                          </LocalizationProvider>
                </div> : ""
              }
              {formData.IsRecurringEvent ?
                <div className='align-items-center'>
                  <InputLabel id='day-select-label' className='required'>
                    {t("Event Days")}
                  </InputLabel>
                  <Select
                    labelid='day-select-label'
                    id='day-select'
                    className='select-input'
                    value={days}
                    multiple
                    renderValue={(selected) => selected.join(", ")}
                    onChange={(event) => onOptionChange(event, "select-days")}
                    required
                  >
                    {eventDays.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={days.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div> : ""
              }

              <div className='align-items-center'>
                <InputLabel id='note-input-label'>{t("Notes")}</InputLabel>
                <TextField
                  id='note-input'
                  labelid='note-input-label'
                  multiline
                  rows={3}
                  value={formData.notes ? formData.notes : ""}
                  variant='standard'
                  onChange={(event) => onOptionChange(event, "note-input")}
                  className='notes-input'
                  placeholder={t("Notes")}
                />
              </div>

              <div className='align-items-center'>
                <InputLabel id='url-key-label'>{t("Tag/Focus")}</InputLabel>
                {/* <TextField
                  className='upload-form-input'
                  placeholder={t("Tag/Focus")}
                  value={formData.tag ? formData.tag : ""}
                  inputProps={{ "aria-label": "description" }}
                  id='tag-select'
                  onChange={(event) => onOptionChange(event, "tag-select")}
                /> */}
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={formData.tagList ? formData.tagList : []}
                    onChange={(event) => onOptionChange(event, "tag-select")}
                    input={<OutlinedInput/>}
                    className="live-event-tags-input"
                    renderValue={(selected) => setTagsNameData(selected)}
                    MenuProps={MenuProps}
                    >
                    {tags.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={formData.tagList ? ((formData.tagList.indexOf(item.id) > -1) ? true : false) : false}/>
                        <ListItemText primary={t(item.name)} />
                        </MenuItem>
                    ))}
                </Select>
              </div>

              <div className='align-items-center upload-button-block'>
                <button type='submit' className='upload-button'>
                  Upload
                </button>
              </div>
            </form>
          </div>
        );
    
};

export default UploadLive;
