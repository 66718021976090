import React, { useEffect } from 'react';
import "./LoginScreen.css";

// Billeder
import Footer from '../../components/Footer/Footer';
import NavForm from '../../components/Navbar/NavForm';
import LoginForm from '../../components/Login/LoginForm';


function LoginScreen() {
    useEffect(() => {
        document.title = `Log ind`;
      });

    return (
        <div className="loginScreen">
            <NavForm />
            <LoginForm />
            <Footer />
        </div>  
    );
}

export default LoginScreen;
