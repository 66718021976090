import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import userService from "../services/user.service";

export const getUserData = createAsyncThunk(
  "userData/getUserData",
  async (thunkAPI) => {
    try {
      const response = await userService.getCurrentUser();
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const clearUserData = createAsyncThunk(
  "userData/clearUserData",
  async (thunkAPI) => {
    return;
  }
);

const initialState = { userDetails: {} };

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  extraReducers: {
    [getUserData.fulfilled]: (state, action) => {
      state.userDetails = {...action.payload};
    },
    [getUserData.rejected]: (state, action) => {
    },
    [clearUserData.fulfilled]: (state, action) => {
      state.userDetails = {};
    },
    [clearUserData.rejected]: (state, action) => {
    }
  },
});

const { reducer } = userDataSlice;
export default reducer;