import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import "./Nav.css";
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    CssBaseline,
    InputBase,
    Menu,
    MenuItem,
    IconButton,
    Divider,
    Button,
    Hidden,
    Drawer
  } from "@mui/material";
  import SearchIcon from '@mui/icons-material/Search';
  import CloseIcon from '@mui/icons-material/Close';
  import MenuIcon from '@mui/icons-material/Menu';

// Pictures
import logo from "../../img/GetUpp_logo_GREY_BLUE.png";
import avatar from "../../img/user-profile.png";
import en from "../../img/flags/en.png";
import da from "../../img/flags/da.png";
import CheckIcon from '@mui/icons-material/Check';
import {useTranslation} from "react-i18next";
import LanguageOutlined from '@mui/icons-material/Language';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { logout } from '../../slices/auth';
import { useSelector,useDispatch } from 'react-redux';
import common from "../../common/common";
import {getUserData} from '../../slices/userData';

function Nav() {
    const {t, i18n} = useTranslation();
    const [anchorel, setAnchorel] = useState(null);
    const isMenuOpen = Boolean(anchorel);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDrawer, setDrawerOpen] = useState(false);
    const [isSearch, setSearchBarOpen] = useState(false);
    const dispatch = useDispatch();
    const {userDetails} = useSelector((state) => state.userData);
    const token  = useSelector((state) => state.message.token);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const fetchUserData = async () => {
        await dispatch(getUserData());
    }

    useEffect(() => {
        fetchUserData();
    }, [token]);

    const handleMenuClose = () => {
        setAnchorel(null);
    };
    const handleLanguageMenuOpen = (event) => {
        setAnchorel(event.currentTarget);
    };
    const handleProfileMenuChange = (value) => {
        setAnchorEl(null);
    };
    const handleLanguageChange = (value) => {
        i18n.changeLanguage(value);
        localStorage.setItem("language_key", value);
        setAnchorel(null);
    };

    const handleDrawerToggle = () => {
        let open = openDrawer;
        setDrawerOpen(!open);
    }

    const openSearchBar = () => {
        let temp = isSearch;
        setSearchBarOpen(!temp);
    };

    const logOut = useCallback(() => {
        dispatch(logout());
      }, [dispatch]);
    
      useEffect(() => {
        common.on("logout", () => {
          logOut();
        });
    
        return () => {
          common.remove("logout");
        };
      }, [logOut]);

    return (    
        <AppBar position="sticky" className="navbar">
            <CssBaseline />
            <Toolbar>
                <Link to='/' className='align-items-center'>
                    <img className="navLogo" src={logo} alt=""/>
                </Link>
                <Hidden smUp>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={handleDrawerToggle}
                    className="navIconHide"
                    >
                    <MenuIcon />
                </IconButton>
                </Hidden>
                <Hidden smUp>
                    <Drawer
                        variant="temporary"
                        anchor={'right'}
                        open={openDrawer}
                        onClose={handleDrawerToggle}
                        className='mobile-drawer'
                    >
                        <MenuItem style={{paddingBottom:0}}><span className="align-items-center"><AccountCircleIcon style={{marginRight:'5px',color:'#404040'}} />{userDetails.firstName} {userDetails.lastName}</span></MenuItem>
                        <Divider />
                        <Link onClick={handleDrawerToggle} to="/all-videos" className={`navItems ${location.pathname === '/all-videos' ? 'active' : ''}`}>{t('All Videos')}</Link>
                        <Link onClick={handleDrawerToggle} to="/favorites" className={`navItems ${location.pathname === '/favorites' ? 'active' : ''}`}>{t('Favorites')}</Link>
                        {/* <Link onClick={handleDrawerToggle} to="/skema" className={`navItems ${location.pathname === '/skema' ? 'active' : ''}`}>{t('Live sessions')}</Link> */}
                        <Divider />
                        <MenuItem  onClick={logOut}>Logout</MenuItem>
                    </Drawer>
                </Hidden>
                <Hidden smDown>
                    <div className="navList">
                        <Link to="/all-videos" className={`navItems ${location.pathname === '/all-videos' ? 'active' : ''}`}>{t('All Videos')}</Link>
                        <Link to="/favorites" className={`navItems ${location.pathname === '/favorites' ? 'active' : ''}`}>{t('Favorites')}</Link>
                        {/* <Link to="/skema" className={`navItems ${location.pathname === '/skema' ? 'active' : ''}`}>{t('Live sessions')}</Link> */}
                        <Link to="/sessions" className={`navItems ${location.pathname === '/sessions' ? 'active' : ''}`}>{t('Training Sessions')}</Link>
                    </div>
                    
                    <div className='navAvatar'>
                        <Button
                            aria-owns={anchorEl ? "profile-menu" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            onMouseOver={handleClick}
                            edge="end"
                            aria-label="profile selected by current user"
                            aria-controls="profile-menu"
                            className="navAvatar"
                        >
                            <img className="navAvatar" src={avatar} alt="" />
                        </Button>
                        <Menu
                            id="profile-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{ onMouseLeave: handleClose }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            style={{marginTop: '2.2em'}}
                        >
                            <MenuItem  onClick={() => handleProfileMenuChange()} style={{margin:'5px 0'}}><span className="align-items-center"><AccountCircleIcon style={{marginRight:'5px',color:'#404040'}} />{userDetails.firstName} {userDetails.lastName}</span></MenuItem>
                            <Divider />
                            <MenuItem onClick={logOut}>{t("Logout")}</MenuItem>
                        </Menu>
                    </div>
                </Hidden>

                <Hidden mdUp>
                    {/* mobile search bar */}
                    {isSearch &&<div className="mobile-search-block searchIcon">
                         
                            <InputBase
                            placeholder="Search…"
                            classes={{
                                root: "inputRoot",
                                input: "inputInput",
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            />
                    </div> 
                    }
                    {isSearch && 
                        <div className="mobile-search-close-icon">
                            <CloseIcon onClick={openSearchBar} />
                        </div>
                    }
                    <div className="mobile-searchIcon">
                        <SearchIcon onClick={openSearchBar} />
                    </div>  
                </Hidden>

                <IconButton
                    className='language-icon'
                    edge="end"
                    aria-label="language selected by current user"
                    aria-controls="change-language-menu"
                    aria-haspopup="true"
                    onClick={handleLanguageMenuOpen}
                    color="inherit"
                    >
                        <LanguageOutlined />
                    </IconButton>
                    <Menu
                    anchorel={anchorel}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id="change-language-menu"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    style={{marginTop: '2.2em'}}
                    >
                        <MenuItem onClick={() => handleLanguageChange('en')} value="en"><img className='flag-icon' src={en} alt=""/> English {i18n.language === 'en' && <CheckIcon className='selected-language'/>}</MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('da')} value="da"><img className='flag-icon' src={da} alt=""/> Danish {i18n.language === 'da' && <CheckIcon className='selected-language'/>}</MenuItem>
                    </Menu>
                    
            </Toolbar>
        </AppBar>  
    );
}

export default Nav;