import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import userService from "../services/user.service";

export const getAllSessions = createAsyncThunk(
  "allSessions/getAllSessions",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getSessions(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const setPage = createAsyncThunk(
  "allSessions/setPage",
  async (page, thunkAPI) => {
      return page;
  }
);

const initialState = { sessions: [], isFetching: false, isFetched: false, page: 1 };

const allSessionsSlice = createSlice({
  name: "allSessions",
  initialState,
  extraReducers: {
    [getAllSessions.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;

      state.sessions = state.page === 1 ? [...action.payload] : [...state.sessions, ...action.payload];

      if(action.payload.length > 0)
        state.page =  state.page + 1;
    },
    [getAllSessions.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [setPage.fulfilled]: (state, action) => {
      state.page = action.payload;
    },
    [setPage.rejected]: (state, action) => {
    }
  },
});

const { reducer } = allSessionsSlice;
export default reducer;
