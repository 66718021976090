import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import userService from "../services/user.service";

export const getAllVideos = createAsyncThunk(
  "allVideos/getAllVideos",
  async (data, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(data);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllTags = createAsyncThunk(
  "allVideos/getAllTags",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getTags();
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const setPage = createAsyncThunk(
  "allVideos/setPage",
  async (page, thunkAPI) => {
      return page;
  }
);

const initialState = { videos: [], tags:[], isFetching: true, page: 1 };

const allVideosSlice = createSlice({
  name: "allVideos",
  initialState,
  reducers: {
    addToFavorites:(state, action)=>{      
      state.videos.forEach(item => {
        if(item.id === action.payload.vid)
          item.isFavourite=true;
      })
    },
    removeFromFavorites:(state, action)=>{      
      state.videos.forEach(item => {
        if(item.id === action.payload.vid)
          item.isFavourite=false;
      })
    },
    removeVideo:(state, action)=>{      
      state.videos = state.videos.filter(item => item.id !== action.payload.vid);
    },
  },
  extraReducers: {
    [getAllVideos.fulfilled]: (state, action) => {
      state.isFetching = false;

      state.videos = state.page === 1 ? [...action.payload] : [...state.videos, ...action.payload];

      if(action.payload.length > 0){
        state.page = state.page + 1;
      }
    },
    [getAllVideos.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [setPage.fulfilled]: (state, action) => {
      state.page = action.payload;
    },
    [setPage.rejected]: (state, action) => {
    },
    [getAllTags.fulfilled]: (state, action) => {
      state.tags = action.payload;
    },
    [getAllTags.rejected]: (state, action) => {
    }
  },
});

export var { addToFavorites, removeFromFavorites, removeVideo } = allVideosSlice.actions;
const { reducer } = allVideosSlice;
export default reducer;
