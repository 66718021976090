import React from 'react';
import Footer from '../../components/Footer/Footer';
import AdminNav from '../../components/Navbar/Admin-nav';
import AdminRoutes from '../../Routes/AdminRoutes';

function AdminScreen() {
        return (
            <div>
                <AdminNav />
                <AdminRoutes />
                <Footer />
            </div>
        );
    
};

export default AdminScreen;
