import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// views
import AdminScreen from "../components/Admin/Admin-dashboard";
import AddUserComponent from "../components/Admin/Add-user";
import AddCategory from "../components/Admin/Add-category";
import AddTag from "../components/Admin/Add-tag";
import Allvideos from "../components/Allvideos/Allvideos";
import UploadScreen from "../components/Upload/Upload";
import ListTag from "../components/Admin/List-Tag";
import Sessions from "../components/Sessions/Sessions";
import SessionPlayer from "../components/SessionPlayer/SessionPlayer";
import UploadSession from "../components/Upload Session/Upload-session";
import UploadLive from "../components/Upload Live Event/UploadEvent";

function AdminRoutes() {
  let location = useLocation();

  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [location]);

  return (
    <Switch>
      <Route path="/add-user" component={AddUserComponent} />
      <Route path="/user/:id" component={AddUserComponent} />
      <Route path="/admin-dashboard" component={AdminScreen} />
      <Route path="/add-category" component={AddCategory} />
      <Route path="/add-tag" component={AddTag} />
      <Route path="/all-videos/:typeId?" component={Allvideos} />
      <Route path="/all-videos" component={Allvideos} />
      <Route path="/upload/:id?" exact component={UploadScreen} />
      <Route path="/upload" exact component={UploadScreen} />
      <Route path="/tags/:id" exact component={AddTag} />
      <Route path="/tags/" exact component={ListTag} />
      <Route path="/sessions/:id" component={UploadSession} />
      <Route path="/upload-session" component={UploadSession} />
      <Route path="/upload-live-event" component={UploadLive} />
      <Route path="/sessions" component={Sessions} />
      <Route path="/session-player/:sessionId" component={SessionPlayer} />
      <Redirect to="/admin-dashboard" />
    </Switch>
  );
}

export default AdminRoutes;
