import React, { useEffect, useState } from 'react';
import "./NavForm.css"

// Pictures
import logo from "../../img/GetUpp_logo_hvid.png";
import { Link } from 'react-router-dom';

function NavForm() {
    const [show, handleShow] = useState(false);
    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            handleShow(true);
        } else {
            handleShow(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);

    
    return (
        <div>
            <div className={`navForm ${show && "navFormWhite"}`}>
                    <Link to='/'>
                        <img className="navFormLogo" src={logo} alt=""/>
                    </Link>
                </div>
        </div>
    )
}

export default NavForm

