import { PlayArrow } from "@mui/icons-material";
import { Badge, Card, CardContent, Dialog, Grid, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { LeftArrow, RightArrow } from "./CarrouselArrow/Carrousel-arrow";
import "./Carrousel.css";
import { t } from "i18next";
import ReplayVideo from "../Player/ReplayVideo";
import { useDispatch, useSelector } from "react-redux";
import ReactAddToCalendar from "../ReactAddToCalendar/ReactAddToCalendar";
import "../ReactAddToCalendar/styles/ReactAddToCalendar.scss";

export function Carrousel({
  getVideos,
  setVideos,
  addVideoToFavorites,
  removeVideoFromFavorites,
  type,
  data,
  userId,
  typeId,
  levelId,
}) {
  let list = data ? data : [];
  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const token = useSelector((state) => state.message.token);
  let icon = { "calendar-plus-o": "left" };

  const { videos, page } = useSelector((state) => {
    let newState = state;
    switch (type) {
      case "favorites":
        newState = state.dashboardCarrouselVideos.favorite;
        break;
      case "last-played":
        newState = state.dashboardCarrouselVideos.lastPlayedVideos;
        break;
      case "latest-videos":
        newState = state.dashboardCarrouselVideos.latestVideos;
        break;
      case "Active Breaks":
        newState = state.dashboardCarrouselVideos.activeBreaks;
        break;
      case "Work Out":
        newState = state.dashboardCarrouselVideos.workOut;
        break;
      case "Yoga":
        newState = state.dashboardCarrouselVideos.yoga;
        break;
      case "Respiratory Training":
        newState = state.dashboardCarrouselVideos.respiratoryTraining;
        break;
      case "Powerstand":
        newState = state.dashboardCarrouselVideos.powerstand;
        break;
      case "Ergonomics":
        newState = state.dashboardCarrouselVideos.ergonomics;
        break;
      case "Mindboost":
        newState = state.dashboardCarrouselVideos.mindboost;
        break;
      case "Pit stop":
        newState = state.dashboardCarrouselVideos.pitStop;
        break;
      case "Pulse and power":
        newState = state.dashboardCarrouselVideos.pulseAndPower;
        break;
      case "Stretching break":
        newState = state.dashboardCarrouselVideos.stretchingBreaks;
        break;
      case "Training":
        newState = state.dashboardCarrouselVideos.training;
        break;
      case "After Work - Puls og styrke":
        newState = state.dashboardCarrouselVideos.afterWorkPulsAndPower;
        break;
      default:
        break;
    }
    return newState;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (data === undefined || !data) fetchData();
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
    // eslint-disable-next-line
  }, [isFetching, token]);

  const fetchMoreListItems = () => {
    fetchData();
    setIsFetching(false);
  };

  const fetchData = async () => {
    let queryParams = `page=${page}`;
    // if(typeId) queryParams = `page=${page}&typeId=${typeId}`;
    if (typeId) queryParams = { page: page, typeId: typeId };
    setTimeout(async () => {
      dispatch(
        getVideos(
          type === "latestVideos"
            ? ""
            : type === "favorites"
            ? { page: page, favourites: true }
            : queryParams
        )
      );
    }, 1000);
  };

  function VideoCard({ item, key }) {
    const visibility = React.useContext(VisibilityContext);

    return (
      <Card
        className="video-card"
        tabIndex={0}
        style={{ position: "relative", boxShadow: "none" }}
      >
        <div className="overlay"></div>
        <button
          disabled={false}
          style={{
            display: "none",
          }}
          className="arrow-buttons play-button"
          onClick={() => {
            setOpen(true);
            setSelectedVideo(item);
          }}
        >
          <PlayArrow />
        </button>
        <CardContent className="video-card-content">
          <img src={item.thumbnailUrl} alt="" className="video-slider-media" />
          <p className="video-scroll-menu-text font-weight-bold">
            {t(item.videoType)}
          </p>
          <p className="video-scroll-menu-text">{`${item.firstName} ${item.lastName}`}</p>
          <ReactAddToCalendar
            className="react-all-videos-add-to-calendar"
            buttonTemplate={icon}
            event={{
              btnTitle: "",
              title: item.videoTitle,
              description: `${item.notes ? item.notes + " " : ""}Video Url:${
                item.videoUrl
              }`,
              location: "Denmark",
              startTime: item.startTime,
              endTime: item.startTime,
            }}
          />

          {!item.isFavourite && (
            <button
              className="favorite-button"
              onClick={() => {
                addVideoToFavorites(
                  item.sessionId
                    ? {
                        sessionVideoId: item.id,
                        videoType: item.videoType,
                        videoTypeId: item.videoTypeId,
                        isFavourite: true,
                      }
                    : {
                        videoId: item.id,
                        videoType: item.videoType,
                        videoTypeId: item.videoTypeId,
                        isFavourite: true,
                      }
                );
              }}
            >
              <FavoriteBorderIcon className="favorite-icon" />
            </button>
          )}
          {item.isFavourite && (
            <button
              className="favorite-button"
              onClick={() => {
                removeVideoFromFavorites(
                  item.sessionId
                    ? {
                        sessionVideoId: item.id,
                        videoType: item.videoType,
                        videoTypeId: item.videoTypeId,
                        isFavourite: false,
                      }
                    : {
                        videoId: item.id,
                        videoType: item.videoType,
                        videoTypeId: item.videoTypeId,
                        isFavourite: false,
                      }
                );
              }}
            >
              <FavoriteIcon className="favorite-icon" />
            </button>
          )}
        </CardContent>
      </Card>
    );
  }

  const getItems = () =>
    Array(20)
      .fill(0)
      .map((_, ind) => ({ id: `element-${ind}` }));

  const createScrollMenu = (data) => {
    return (
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={
          <RightArrow
            changeStateValue={() => {
              setIsFetching(true);
            }}
          />
        }
        className="scroll-menu"
      >
        {data.map((item, index) => (
          <VideoCard item={item} itemId={"video-" + index} key={index} />
        ))}
      </ScrollMenu>
    );
  };

  return (
    <div className="horizontal-scroll-menu">
      <Dialog
        fullScreen
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <ReplayVideo
          onClose={() => {
            setOpen(false);
          }}
          selectedVideo={selectedVideo}
        />
      </Dialog>

      {videos &&
        videos !== undefined &&
        videos.length > 0 &&
        createScrollMenu(videos)}
      {/* {(!videos || videos === undefined || videos.length === 0) && (
        <Grid
          item
          className="font-weight-bold scroll-menu-title"
          style={{ fontWeight: "normal" }}
        >
          <p>{t("No Videos Found")}</p>
        </Grid>
      )} */}
    </div>
  );
}
