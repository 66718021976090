import React, { useEffect } from 'react';
import './ReplayVideo.css';
import { Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link} from 'react-router-dom';
import userService from '../../services/user.service';

function ReplayVideo(props) {
    const video = props.selectedVideo;
    let data = video.sessionId ? {sessionVideoId: video.id} : {videoId: video.id}
    useEffect(() => {
        userService.addToLastPlayed(data)
        .then((response) => {
            console.log("Played Video: ", response)
            })
            .catch((error) => {
                console.log(error);
            });
    })

    const createReplayVideoPlayer = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{padding:'1em 0.5em 1em 1em'}}>
                    {props.backLink === undefined && 
                        <button className='arrow-back-button' onClick={() => props.onClose()}>
                            <ArrowBackIcon className='arrow-back-icon'/>
                        </button>
                    }
                    {props && props.backLink && props.backLink.length && 
                        <Link to={props.backLink}>
                            <button className='arrow-back-button'>
                                <ArrowBackIcon className='arrow-back-icon'/>
                            </button>
                        </Link>
                    }
                    <div className='replay-videoplayer-grid'>
                        <div style={{padding:'45% 0 0 0',position:'relative'}}>
                            <iframe src={decodeURIComponent(video.iframeUrl.replace(/&amp;/g, '&'))} frameBorder="0" title="replayPlayer" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',borderRadius:'4px'}}></iframe>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
    return (
        <div className="Replay-Video-Player">
            {createReplayVideoPlayer()}
        </div>
    )
}

export default ReplayVideo;
