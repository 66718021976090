import React, {Suspense, useEffect, useState} from 'react';
import {TextField, Dialog, Grid, FormControl, FormLabel, Card, CardContent, Button, Hidden, Snackbar, Alert, ListItemText, OutlinedInput, MenuItem, Select, Badge, Tooltip, Checkbox } from '@mui/material';
import {useTranslation} from "react-i18next";
import './Favorites.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { PlayArrow,ArrowUpward } from '@mui/icons-material';
import ReplayVideo from '../Player/ReplayVideo';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import userService from '../../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { getTeams, getInstructors } from '../../slices/uploadData';
import { getAllTags } from '../../slices/tags';
import { getFavoriteVideos, setPage } from '../../slices/favorites';
import moment from 'moment';
import Spinner from '../ProgressBar/Spinner';
import { removeFromFavorites } from '../../slices/favorites';
import { removeFromFavorites as removeFromFavorite, removeVideo } from '../../slices/all-videos';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {getVideoById} from '../../slices/uploadVideo';
import {useHistory} from "react-router-dom";
import { refreshLikesDislikes } from "../../slices/dashboard-carrousel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Favorites() {
   
    const {t} = useTranslation();
    const Teams = useSelector((state) => state.uploadData.teams);
    const instructors = useSelector((state) => state.uploadData.instructors);
	const [isFetching, setIsFetching] = useState(false);
    const [visible, setVisible] = useState(false)
    const [open, setOpen] = useState(false);
    const [selectedInstructor, setInstructor] = useState(null);
    const [selectedTeam, setTeam] = useState(null);
    const [selectedLanguage, setLanguage] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [date, setDate] = useState(null);
    const [showInstructor, setInstructorVisibility] = useState(false);
    const [showTeam, setTeamVisibility] = useState(false);
    const [showLanguage, setLanguageVisibility] = useState(false);
    const [showDate, setDateVisibility] = useState(false);
    const [showDuration, setDurationVisibility] = useState(false);
    const [showTags, setTagsVisibility] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({status:'',successMessage:'',errors:[]});
    const { tags } = useSelector((state) => state.allTags);
    const token  = useSelector((state) => state.message.token);
    const { page, videos } = useSelector((state) => state.favoriteVideos);
    const isLoading = useSelector((state) => state.favoriteVideos.isFetching);
    const [duration, setDuration] = useState("");
    const [tagsData, setTags] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

    const {userDetails} = useSelector((state) => state.userData);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const role = userDetails ? userDetails.userType : '';

    const handleTagsChange = async (event) => {     
        await dispatch(setPage(1));
        const {
          target: { value },
        } = event;
        setTags(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const setTagsNameData = (selected) => {
        if(selected[0] === t('Select') && selected.length === 1) 
        {
            return <p>{t('Select')}</p>;
        }
          let namesArray=[];
            selected.forEach(item => {
                tags.forEach(obj => {
                    if(item===obj.id)
                        namesArray.push(t(obj.name))
                })
            })
            return namesArray.join(", ");
    };

    const handleEditVideo = async (listItem) => {        
        if(listItem.id && listItem.sessionId)
            dispatch(getVideoById({"sessionVideoId": listItem.id}));
        else if(listItem.id && !listItem.sessionId)
            dispatch(getVideoById({"videoId": listItem.id}));

        history.push("/upload/"+listItem.id);
    };

    const handleRadioOptionChange = (event,id) => {
      switch (id) {
        case 'instructor':
            setInstructor(event.target.value);
            fetchData(event.target.value,selectedTeam,selectedLanguage,1,date,duration);
            break;
        case 'team':
            setTeam(event.target.value);
            fetchData(selectedInstructor,event.target.value,selectedLanguage,1,date,duration);
            break;
        case 'language':
            setLanguage(event.target.value);
            fetchData(selectedInstructor,selectedTeam,event.target.value,1,date,duration);
            break;
        case 'date-picker':
            setDate(() => {return event;});
            fetchData(selectedInstructor,selectedTeam,selectedLanguage,1,moment(event).format("YYYY-MM-DD"),duration);
            break;
        case 'duration':
            setDuration(event.target.value);
            fetchData(selectedInstructor,selectedTeam,selectedLanguage,1,date,event.target.value);
            break;
        default:
            break;
      }
      dispatch(setPage(1));
    };

    const resetData = async () => {
        await dispatch(setPage(1));
        setInstructor(null);
        setTeam(null);
        setLanguage(null);
        setDate(null);
        setDuration("");
        setTags([]);
    }
    
    const clearFilters = async () => {
        await resetData();
    }

	useEffect(() => {
        fetchData();
		window.addEventListener('scroll', handleScroll);
        dispatch(getTeams());
        dispatch(getInstructors());       
        dispatch(getAllTags());
        // eslint-disable-next-line
	}, [selectedTeam, selectedInstructor, selectedLanguage, duration, tagsData, token]);

	const handleScroll = () => {
		if (
			Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight ||
			isFetching
		)
        return;
		setIsFetching(true);
	};

	const fetchData = async (instructor,team,language,currentPage,selectedDate, duration) => {        
        let data = {
            ...((instructor || selectedInstructor) && {"userId": instructor ? instructor : (selectedInstructor ? selectedInstructor : '')}),
            ...((team || selectedTeam) && {"typeId": team? team : (selectedTeam ? selectedTeam : '')}),
            ...((language || selectedLanguage) && {"language": language ? language : (selectedLanguage ? selectedLanguage : '')}),
            ...((selectedDate) && {"date": selectedDate ? selectedDate : ''}),
            ...((duration) && {"durationRange": duration ? duration : ''}),
            ...((tagsData && tagsData.length > 0) && {"tagIdList": tagsData}),
            "favourites": true,
            "page": currentPage ? currentPage : page,
        }

        setTimeout(async () => {
            await dispatch(getFavoriteVideos(data));
            setIsFetching(false);
        }, 1000);
	};

    useEffect(() => {
		if (!isFetching) return;
		fetchMoreListItems();
        // eslint-disable-next-line
	}, [token, isFetching]);

    const fetchMoreListItems = () => {
		fetchData();
		setIsFetching(false);
	};
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  const expandFilterPanel = (id,show) =>{
      switch (id) {
        case 'instructor':
            setInstructorVisibility(() => {return show});
        break;
        case 'team':
            setTeamVisibility(() => {return show});
        break;
        case 'language':
            setLanguageVisibility(() => {return show});
        break;
        case 'date':
            setDateVisibility(() => {return show});
        break;
        case 'duration':
            setDurationVisibility(() => {return show});
        break;
        case 'tags':
            setTagsVisibility(() => {return show});
        break;
        default:
            break;
      }
  }

  const addVideoToFavorites = (data) => {
    userService.addVideoToFavorites(data)
      .then((response) => {
        setUploadResponse(response.data);
        setOpenMessage(true);
      })
      .catch((error) => {
        console.log(error);
        setUploadResponse(error.response.data)
        setOpenMessage(true);
      });
};
const removeVideoFromFavorites = (data) => {
    userService.removeVideoFromFavorites(data)
      .then((response) => {
        setUploadResponse(response.data);
        setOpenMessage(true);
        if(response.data.status === "SUCCESS")
            dispatch(removeFromFavorites(data.sessionVideoId ? {vid: data.sessionVideoId} : {vid:data.videoId}));
            dispatch(removeFromFavorite(data.sessionVideoId ? {vid: data.sessionVideoId} : {vid:data.videoId}));
            dispatch(refreshLikesDislikes(data));
      })
      .catch((error) => {
        console.log(error);
        setUploadResponse(error.response.data)
        setOpenMessage(true);
      });
};

const deleteVideoFromFavorites = (data) => {
    userService.deleteVideo(data).then(response => {
        setUploadResponse(response.data);
        setOpenMessage(true);
        if(response.data.status === "SUCCESS"){
            dispatch(removeVideo(data.sessionVideoId ? {vid: data.sessionVideoId} : {vid:data.videoId}));
            dispatch(removeFromFavorites(data.sessionVideoId ? {vid: data.sessionVideoId} : {vid:data.videoId}));
        }
    }).catch(error => {
        console.log(error);
        setUploadResponse(error.response.data)
        setOpenMessage(true);
    })
};
  
  window.addEventListener('scroll', toggleVisible);

    const createFilters = () => {
        return(
            <div>
                <Button color="primary" className="scroll-to-top-button" style={{display: visible ? 'inline' : 'none'}}>
                    <ArrowUpward onClick={scrollToTop} 
                    style={{display: visible ? 'inline' : 'none'}} />
                </Button>

                <p className='clear-filter'>{ (selectedInstructor || selectedTeam || selectedLanguage || date || duration || tagsData.length > 0) && 
                    <Button size="small" onClick={clearFilters} variant="outlined" style={{borderRadius:'20px', fontSize:'10px', fontWeight:'bold', backgroundColor:'#404040', color:'#ffffff', borderColor:'#404040'}} startIcon={<NotInterestedIcon style={{fontSize:'16px', verticalAlign:'top'}}/>}>
                        <span>{t('Clear filters')}</span>
                    </Button>}
                </p>

                <FormControl component="fieldset" className='form-control'>
                    <FormLabel component="legend" className="filter-label">{t('Category')}
                        <Hidden mdUp>
                            {!showTeam ? <ExpandMoreIcon onClick={() => expandFilterPanel('team',true)} className="expand-icon"/>
                            : <ExpandLessIcon onClick={() => expandFilterPanel('team',false)} className="expand-icon"/>}
                        </Hidden>
                        <p className='clear-filter-btn'>{ ( selectedTeam ) && 
                            <Button size="small" onClick={async () => { await dispatch(setPage(1))
                            setTeam(null)}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                            startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                            </Button>}
                        </p>
                    </FormLabel>
                    <Select
                    labelId="demo-multiple-checkbox-label"
                    id='team'
                    value={selectedTeam ? selectedTeam : t('Select')}
                    onChange={(event) => handleRadioOptionChange(event,'team')}
                    input={<OutlinedInput/>}
                    className={`${!showTeam && 'd-none'} filter-radio-group tags-input`}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        let namesArray=""
                            Teams.forEach(obj => {
                                if(selected===obj.id)
                                    namesArray = obj.type
                            })
                        return namesArray ? namesArray : t('Select')
                    }}
                    >
                    {Teams && Teams.length > 0 &&
                        Teams.map((team, index) => {
                        return <MenuItem key={index} value={team.id}>
                        <ListItemText primary={t(team.type)} />
                        </MenuItem> 
                        }
                    )}
                    </Select>
                </FormControl>
                <FormControl component="fieldset" className='form-control'>
                <FormLabel component="legend" className="filter-label">{t('Tags')}
                    <Hidden mdUp>
                        {!showTags ? <ExpandMoreIcon onClick={() => expandFilterPanel('tags',true)} className="expand-icon"/>
                        : <ExpandLessIcon onClick={() => expandFilterPanel('tags',false)} className="expand-icon"/>}
                    </Hidden>
                    <p className='clear-filter-btn'>{ ( tagsData.length > 0 ) && 
                        <Button size="small" onClick={async () => { await dispatch(setPage(1))
                            setTags([])}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                        startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                        </Button>}
                    </p>
                </FormLabel>
                
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={tagsData && tagsData.length > 0 ? tagsData : [t('Select')]}
                    onChange={handleTagsChange}
                    input={<OutlinedInput/>}
                    className={`${!showTags && 'd-none'} filter-radio-group tags-input`}
                    renderValue={(selected) => {return setTagsNameData(selected);}}
                    MenuProps={MenuProps}
                    >
                    {tags.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={tagsData.indexOf(item.id) > -1} />
                        <ListItemText primary={t(item.name)} />
                        </MenuItem>
                    ))}
                </Select>
                        
                </FormControl>
                <FormControl component="fieldset" className='form-control'>
                    <FormLabel component="legend" className="filter-label">{t('Duration')}
                        <Hidden mdUp>
                            {!showDuration ? <ExpandMoreIcon onClick={() => expandFilterPanel('duration',true)} className="expand-icon"/>
                            : <ExpandLessIcon onClick={() => expandFilterPanel('duration',false)} className="expand-icon"/>}
                        </Hidden>
                        <p className='clear-filter-btn'>{ ( duration ) && 
                            <Button size="small" onClick={async () => { await dispatch(setPage(1))
                                setDuration("")}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                            startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                            </Button>}
                        </p>
                    </FormLabel>
                    <Select
                    id='duration'
                    className={`${!showDuration && 'd-none'} filter-radio-group tags-input`}
                    value={duration ? duration : t('Select')}
                    onChange={(event) => handleRadioOptionChange(event,'duration')}
                    input={<OutlinedInput/>}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        return selected ? selected : t('Select')
                    }}
                    >
                        
                    <MenuItem value="0-5" className="filter-options" >
                        <ListItemText onClick={(event) => event.target.value===duration ? setDuration("") : null} primary="0-5 min" />
                    </MenuItem>
                    <MenuItem value="5-15" className="filter-options">
                        <ListItemText onClick={(event) => event.target.value===duration ? setDuration("") : null} primary="5-15 min" />
                    </MenuItem>
                    <MenuItem value="15-30" className="filter-options">
                        <ListItemText onClick={(event) => event.target.value===duration ? setDuration("") : null} primary="15-30 min" />
                    </MenuItem>
                    <MenuItem value="30-60" className="filter-options">
                        <ListItemText onClick={(event) => event.target.value===duration ? setDuration("") : null} primary="30-60 min" />
                    </MenuItem>
                    <MenuItem value="over 60" className="filter-options">
                        <ListItemText onClick={(event) => event.target.value===duration ? setDuration("") : null} primary="Over 60 min" />
                    </MenuItem>
                    </Select>
                </FormControl>

                <FormControl component="fieldset" className='form-control'>
                    <FormLabel component="legend" className="filter-label">{t('Instructor')} 
                        <Hidden mdUp>
                            {!showInstructor ? <ExpandMoreIcon onClick={() => expandFilterPanel('instructor',true)} className="expand-icon"/>
                            : <ExpandLessIcon onClick={() => expandFilterPanel('instructor',false)} className="expand-icon"/>}
                        </Hidden>
                        <p className='clear-filter-btn'>{ ( selectedInstructor ) && 
                            <Button size="small" onClick={async () => { await dispatch(setPage(1))
                                setInstructor(null)}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                            startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                            </Button>}
                        </p>
                    </FormLabel>
                    <Select
                    id='instructor'
                    className={`${!showInstructor && 'd-none'} filter-radio-group tags-input`}
                    value={selectedInstructor ? selectedInstructor : t('Select')}
                    onChange={(event) => handleRadioOptionChange(event,'instructor')}
                    input={<OutlinedInput/>}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        let namesArray=""
                            instructors.forEach(obj => {
                                if(selected===obj.id)
                                    namesArray = obj.firstName +' '+ obj.lastName
                            })
                        return namesArray ? namesArray : t('Select')
                    }}
                    >
                    {instructors && instructors.length > 0 && 
                        instructors.map((instructor,index) => {
                            return <MenuItem key={index} value={instructor.id} onClick={(event) => event.target.value===selectedInstructor ? setInstructor(null) : null}>
                            <ListItemText primary={instructor.firstName +' '+ instructor.lastName} />
                            </MenuItem>
                    })}
                    </Select>
                </FormControl>
                
                <FormControl component="fieldset" className='form-control'>
                <FormLabel component="legend" className="filter-label">{t('Language')}
                    <Hidden mdUp>
                        {!showLanguage ? <ExpandMoreIcon onClick={() => expandFilterPanel('language',true)} className="expand-icon"/>
                        : <ExpandLessIcon onClick={() => expandFilterPanel('language',false)} className="expand-icon"/>}
                    </Hidden>
                    <p className='clear-filter-btn'>{ ( selectedLanguage ) && 
                        <Button size="small" onClick={async () => { await dispatch(setPage(1))
                            setLanguage(null)}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                        startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                        </Button>}
                    </p>
                </FormLabel>
                <Select
                    id='language'
                    className={`${!showLanguage && 'd-none'} filter-radio-group tags-input`}
                    aria-label="Language"
                    value={selectedLanguage ? selectedLanguage : t('Select')}
                    onChange={(event) => handleRadioOptionChange(event,'language')}
                    input={<OutlinedInput/>}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        return selected ? selected : t('Select')
                    }}
                    >
                    <MenuItem value="da" onClick={(event) => event.target.value===selectedLanguage ? setLanguage(null) : null}>
                        <ListItemText primary={`${t('Danish')}`}></ListItemText>
                    </MenuItem>
                    <MenuItem value="en" onClick={(event) => event.target.value===selectedLanguage ? setLanguage(null) : null}>
                        <ListItemText primary={`${t('English')}`}></ListItemText>
                    </MenuItem>
                    
                </Select>
                </FormControl>
                <FormControl component="fieldset" className='form-control'>
                <FormLabel component="legend" className="filter-label">{t('Date')}
                    <Hidden mdUp>
                        {!showDate ? <ExpandMoreIcon onClick={() => expandFilterPanel('date',true)} className="expand-icon"/>
                        : <ExpandLessIcon onClick={() => expandFilterPanel('date',false)} className="expand-icon"/>}
                    </Hidden>
                    <p className='clear-filter-btn'>{ ( date ) && 
                        <Button size="small" onClick={async () => { await dispatch(setPage(1))
                            setDate(null)}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                        startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                        </Button>}
                    </p>
                </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                className={`${!showDate && 'd-none'} filter-radio-group`}
                                inputFormat="dd/MM/yyyy"
                                id="date"
                                onChange={(event) => handleRadioOptionChange(event,'date-picker')}
                                value={date}
                                required
                                renderInput={(params) => <TextField className={`${!showDate && 'd-none'} filter-radio-group favorites-page-date-picker`} {...params} />}
                            />
                        </LocalizationProvider>
                </FormControl>
            </div>
        );
    };

        return (
            <div>
                <Snackbar open={openMessage} autoHideDuration={6000} onClose={() => {setOpenMessage(false)}}>
                    <Alert onClose={() => {setOpenMessage(false)}} severity={uploadResponse && uploadResponse.status === 'SUCCESS' ? 'success' : 'error'} sx={{ width: '100%' }}>
                        {t(uploadResponse.status === 'SUCCESS' ? uploadResponse.successMessage : uploadResponse.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again.")}
                    </Alert>
                </Snackbar>

                { isLoading ? <Spinner/> : null }

                <Dialog fullScreen onClose={() => {setOpen(false)}} open={open}>
                    <ReplayVideo onClose={() => {setOpen(false)}} selectedVideo={selectedVideo}/>
                </Dialog>

                <Grid container className="favorites-page">
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        {createFilters()}
                    </Grid>
                    <Grid item xs={12} sm={12} md={8.7} lg={8.7} className='all-video-list'>
                        <p className='font-weight-bold video-block-title'>{t('Favorite Videos')}</p>
                        <Grid container spacing={3}>
                            {(!videos || videos.length === 0) && 
                                <Grid item><p>{t('No Videos Found')}</p></Grid>
                            }
                            {videos.map((listItem,index) => {
                                if(listItem.isFavourite) {
                                return <Grid item xs={12} sm={6} md={4} lg={3} key={listItem.videoUrl+index}>
                                    <Card className="video-card" style={{position:'relative'}}>
                                        <div className='overlay'></div>
                                        <button
                                            disabled={false}
                                            style={{
                                            display:'none'
                                            }}
                                            className="arrow-buttons play-button"
                                            onClick={() => {setOpen(true);setSelectedVideo(listItem);}}
                                        >
                                            <PlayArrow />
                                        </button>
                                        <CardContent className='video-card-content'>
                                            <Suspense fallback={<img src={listItem.thumbnailUrl} alt='Avatar' className='video-slider-media' />}>
                                                <img src={listItem.thumbnailUrl} alt='Avatar' className='video-slider-media' />
                                            </Suspense>
                                            <p className='video-scroll-menu-text font-weight-bold'>{t(listItem.videoType)}</p>
                                            <p className='video-scroll-menu-text'>{`${listItem.firstName} ${listItem.lastName}`}</p>
                                            {(userDetails && isLoggedIn) && role === 'Admin' && <button className='edit-button' onClick={()=>{handleEditVideo(listItem)}}><EditIcon /></button> }
                                            {/* <button className='delete-button' onClick={()=>{deleteVideoFromFavorites( listItem.sessionId ? {sessionVideoId: listItem.id} : {videoId: listItem.id} )}}><DeleteIcon /></button> */}
                                            {!listItem.isFavourite && <button className='favorite-button' onClick={() => addVideoToFavorites(listItem.sessionId ? {sessionVideoId: listItem.id} : {videoId: listItem.id} )}><FavoriteBorderIcon className='favorite-icon'/></button>}
                                            {listItem.isFavourite && <button className='favorite-button' onClick={() => removeVideoFromFavorites(listItem.sessionId ? {sessionVideoId: listItem.id, videoType: listItem.videoType, videoTypeId: listItem.videoTypeId, isFavourite: false} : {videoId: listItem.id, videoType: listItem.videoType, videoTypeId: listItem.videoTypeId, isFavourite: false} )}><FavoriteIcon className='favorite-icon'/></button>}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                }
                                return null;
                            })}
                        </Grid>
                        {isFetching && <h3>Fetching more videos...</h3>}
                    </Grid>
                </Grid>
            </div>
        );
    
};

export default Favorites;
