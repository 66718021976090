import React, { useEffect } from 'react';
import "./ResetScreen.css";

// Billeder
import Footer from '../../components/Footer/Footer';
import NavForm from '../../components/Navbar/NavForm';
import ResetForm from '../../components/Reset/ResetForm';


function ResetScreen() {
    useEffect(() => {
        document.title = `Nulstille kodeord`;
      });

    return (
        <div className="resetScreen">
            <NavForm />
            <ResetForm />
            <Footer />
        </div>  
    );
}

export default ResetScreen;
