import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Spinner from '../ProgressBar/Spinner';
import { login } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import "./LoginForm.css";
// import Link from '@mui/material/Link';
import { Link } from 'react-router-dom';

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
      username: '',
      password: ''
  });
  const [open, setOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', error_description:''});

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  

    const loginUser = (event) => {
        event.preventDefault();
        if(!values.username || !values.password){
            setOpen(true);
            setUploadResponse({status:'error',errors: ['FORM_FIELD_DATA_MISSING']});
            return;
        }
        setLoading(true);
        dispatch(login(values))
          .unwrap()
          .then((response) => {
            setUploadResponse(response.data);
            setOpen(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setUploadResponse(error)
            setOpen(true);
          });
    }

    const handleInputChange = (event) => {
      let formValues = values;
      switch (event.target.id) {
        case 'email':
          formValues.username = event.target.value;
          break;
        case 'password':
          formValues.password = event.target.value;
          break;
        default:
          break;
      }
      setValues(formValues);
    };

    return (
      <div>
        { loading?<Spinner/>:null }
        <div className="loginForm">
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={uploadResponse && uploadResponse.status === 'SUCCESS' ? 'success' : 'error'} sx={{ width: '100%' }}>
                  {t(uploadResponse.status === 'SUCCESS' ? uploadResponse.successMessage : uploadResponse.error_description)}
              </Alert>
          </Snackbar>

            <div className="loginFormContent">
                <form onSubmit={loginUser} autoComplete='off'>
                    <h1>Log ind</h1>
                    <input 
                        required
                        type="email"
                        placeholder="Email"
                        id='email'
                        onChange={handleInputChange}
                        autoComplete='new-email'
                    />
                    <input id='password' autoComplete='new-password' placeholder="Password" type="password" onChange={handleInputChange}/>
                    <div className="options-container">
                      <label><input type="checkbox"/> Husk mig!</label>
                      <Link id="forgot-password-link" to="/forgot-password">Forgot Password?</Link>
                    </div>
                    <button type="submit">Log ind</button>

                </form>
            </div>
            <div className="loginFormFadeBottom" />
        </div>
      </div>
        
    )
}

export default LoginForm
