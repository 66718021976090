import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import userService from "../services/user.service";

export const getLastPlayedVideos = createAsyncThunk(
  "lastPlayedVideos/getLastPlayedVideos",
  async (data, thunkAPI) => {
    try {
      const response = await userService.getLastPlayedVideos(data);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const setLastPlayedVideosPage = createAsyncThunk(
  "lastPlayedVideos/setLastPlayedVideosPage",
  async (page, thunkAPI) => {
      return page;
  }
);

const initialState = { videos: [], isFetching: false, isFetched: false, page: 1 };

const lastPlayedVideosSlice = createSlice({
  name: "lastPlayedVideos",
  initialState,
  extraReducers: {
    [getLastPlayedVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      state.videos = state.page === 1 ? action.payload : [...state.videos, ...action.payload];
    },
    [getLastPlayedVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    }
  },
});

const { reducer } = lastPlayedVideosSlice;
export default reducer;
