import React from 'react';
import './Player.css';
import {Grid, Hidden} from '@mui/material';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

function Player(props) {
    const videoUrl = useSelector((state) => state.selectedVideoUrl.selectedVideoUrl)

    const createLiveVideoPlayer = () => {
        return (
            <Grid container spacing={1} columns={16}>
                <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                <Grid item xs={14} sm={14} md={9} lg={9}>
                    <p className='player-category'>{t('Live Event')}</p>
                    <div className='player-grid'>
                        <div style={{padding:'56.25% 0 0 0',position:'relative'}}>
                            <iframe src={videoUrl} title="livePlayer" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',borderRadius:'4px'}}></iframe>
                        </div>
                    </div>
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                </Hidden>
                <Grid item xs={14} sm={14} md={6} lg={6} className='chat-grid'>
                    <iframe src="https://vimeo.com/event/1703761/chat/" title="chatgrid" style={{borderRadius:'4px'}} width="100%" height="500px" frameBorder="0"></iframe>
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                </Hidden>
            </Grid>
        );
    }
    
    const createReplayVideoPlayer = () => {
        return (
            <Grid container spacing={1} columns={16}>
                <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                <Grid item xs={10} sm={14} md={14} lg={14}>
                    <p className='player-category'>Category</p>
                    <div className='player-grid'>
                        <div style={{padding:'56.25% 0 0 0',position:'relative'}}>
                            <iframe src="https://vimeo.com/event/1703761/embed" title="replayPlayer" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',borderRadius:'4px'}}></iframe>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            </Grid>
        );
    }
    return (
        <div className="Player">
            {props.videoType && props.videoType !== 'live' ? createReplayVideoPlayer() : createLiveVideoPlayer()}
        </div>
    )
}

export default Player;
