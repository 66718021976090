import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Snackbar,
  InputLabel,
  MenuItem,
  Checkbox,
  Select,
  TextField,
  ListItemText,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import { useTranslation } from "react-i18next";
import "./Upload.css";
import moment from "moment";
import {
  getTeams,
  getInstructors,
  getVideoSubType,
} from "../../slices/uploadData";
import Spinner from "../ProgressBar/Spinner";
import OutlinedInput from "@mui/material/OutlinedInput";
import { getAllTags } from "../../slices/tags";
import {
  uploadVideoData,
  updateVideo,
  updateSessionVideo,
  clearFilledData,
} from "../../slices/uploadVideo";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Upload(props) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const token = useSelector((state) => state.message.token);
  const Teams = useSelector((state) => state.uploadData.teams);
  const Instructors = useSelector((state) => state.uploadData.instructors);
  let videoSubTypes = useSelector((state) => state.uploadData.subtypes);
  const { isLoading } = useSelector((state) => state.uploadData);
  const { filledData } = useSelector((state) => state.uploadVideo);
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state.allTags);
  const { id } = useParams();

  const setTagsNameData = (selected) => {
    let namesArray = [];

    selected.forEach((item) => {
      tags.forEach((obj) => {
        if (item === obj.id) namesArray.push(t(obj.name));
      });
    });
    //console.log(namesArray)
    return namesArray.join(", ");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    dispatch(getTeams());
    dispatch(getInstructors());
    dispatch(getAllTags());

    if (!props.match.params.id && filledData) dispatch(clearFilledData());
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (filledData) setFormData({ ...filledData });

    if (filledData.tags && filledData.tags[0] === null)
      setFormData({ ...filledData, tags: [] });

    if (
      filledData.tags &&
      filledData.tags.length > 0 &&
      filledData.tags[0] !== null
    )
      setFormData({
        ...filledData,
        tags: filledData.tags.map((item) => {
          return item.id;
        }),
      });

    // eslint-disable-next-line
  }, [filledData]);

  useEffect(() => {
    if (formData.videoTypeId !== undefined) {
      dispatch(getVideoSubType(formData.videoTypeId));
    }
    // eslint-disable-next-line
  }, [formData.videoTypeId, token]);

  const onOptionChange = (event, id) => {
    let FD = formData;
    switch (id) {
      case "video-url":
        FD.videoUrl = event.target.value;
        break;
      case "instructor-select":
        FD.userId = event.target.value;
        break;
      case "team-select":
        FD.videoTypeId = event.target.value;
        // FD.videoSubTypeId = ""
        break;
      case "select-subType":
        FD.videoSubTypeId = event.target.value;
        break;
      case "language-select":
        FD.language = event.target.value;
        break;
      case "date-picker":
        setDate(event.toISOString().substring(0, 10));
        FD.date = event.toISOString().substring(0, 10);
        break;
      case "time-picker":
        setTime(event);
        FD.startTime = event.toISOString();
        break;
      case "note-input":
        FD.notes = event.target.value;
        break;
      case "tag-select":
        FD.tags = event.target.value;
        break;
      case "subtitleLanguage-select":
        FD.subtitleLanguage = event.target.value.join(",");
        break;
      default:
        break;
    }
    setFormData(() => {
      return { ...formData, ...FD };
    });
  };

  const uploadVideo = async (event) => {
    event.preventDefault();
    if (formData.videoSubTypeId === "") delete formData.videoSubTypeId;
    if (
      !formData.videoUrl ||
      !formData.userId ||
      !formData.videoTypeId ||
      !formData.date ||
      !formData.startTime ||
      (videoSubTypes && videoSubTypes.length > 0 && !formData.videoSubTypeId)
    ) {
      setOpen(true);
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      return;
    }

    let data = { ...formData, tagList: formData.tags };
    delete data.tags;

    await dispatch(uploadVideoData(data)).then((response) => {
      setFormData({});
      setDate("");
      setTime("");
      videoSubTypes = "";
      setUploadResponse(response.payload);
      setOpen(true);
    });
  };

  const handleUpdateVideo = async () => {
    if (formData.videoSubTypeId === "") delete formData.videoSubTypeId;
    if (
      !formData.videoUrl ||
      !formData.userId ||
      !formData.videoTypeId ||
      !formData.date ||
      !formData.startTime ||
      (videoSubTypes && videoSubTypes.length > 0 && !formData.videoSubTypeId)
    ) {
      setOpen(true);
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      return;
    }

    let data = {
      Id: formData.id,
      ...(filledData.sessionId && { sessionId: formData.sessionId }),
      videoUrl: formData.videoUrl,
      videoTypeId: formData.videoTypeId,
      ...(videoSubTypes &&
        videoSubTypes.length > 0 && {
          videoSubTypeId: formData.videoSubTypeId,
        }),
      language: formData.language,
      userId: formData.userId,
      startTime: formData.startTime,
      date: formData.date,
      notes: formData.notes,
      tagList: formData.tags,
      subtitleLanguage: formData.subtitleLanguage
        ? formData.subtitleLanguage
        : "",
    };

    if (filledData.id && filledData.sessionId) {
      await dispatch(updateSessionVideo(data)).then((response) => {
        setUploadResponse(response.payload);
        setOpen(true);
      });
    } else if (filledData.id && !filledData.sessionId) {
      await dispatch(updateVideo(data)).then((response) => {
        setUploadResponse(response.payload);
        setOpen(true);
      });
    }
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={
            uploadResponse && uploadResponse.status === "SUCCESS"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {t(
            uploadResponse.status === "SUCCESS"
              ? uploadResponse.successMessage
              : uploadResponse.errors
              ? uploadResponse.errors[0]
              : "Something went wrong, Please try again."
          )}
        </Alert>
      </Snackbar>

      {isLoading ? <Spinner /> : null}

      <form className="upload-form" noValidate autoComplete="off">
        <div className="align-items-center">
          <InputLabel id="url-key-label" className="required">
            {t("URL key")}
          </InputLabel>
          <TextField
            required
            className="upload-form-input"
            placeholder={t("URL key")}
            value={formData.videoUrl ? formData.videoUrl : ""}
            inputProps={{ "aria-label": "description" }}
            id="video-url"
            onChange={(event) => onOptionChange(event, "video-url")}
          />
        </div>
        <div className="align-items-center">
          <InputLabel id="instructor-select-label" className="required">
            {t("Instructor")}
          </InputLabel>
          <Select
            placeholder={t("Instructor")}
            labelid="instructor-select-label"
            id="instructor-select"
            className="select-input"
            value={formData.userId ? formData.userId : ""}
            onChange={(event) => onOptionChange(event, "instructor-select")}
            required
            displayEmpty
            renderValue={(selected) => {
              let instructor;
              if (
                (selected === undefined || selected.length === 0) &&
                !formData.userId
              ) {
                return <>{t("Select Instructor")}</>;
              } else if (formData.userId && selected) {
                instructor = Instructors.find((element) => {
                  return (
                    element.id === selected && selected === formData.userId
                  );
                });
              } else if (formData.userId && !selected) {
                instructor = Instructors.find((element) => {
                  return element.id === formData.userId;
                });
              }
              if (instructor) {
                return `${instructor.firstName} ${instructor.lastName}`;
              } else {
                return `${t("Deleted instructor")}`;
              }
            }}
          >
            {Instructors.map((element, index) => {
              return (
                <MenuItem key={index} value={element.id}>
                  {element.firstName} {element.lastName}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div className="align-items-center">
          <InputLabel id="team-select-label" className="required">
            {t("Type")}
          </InputLabel>
          <Select
            labelid="team-select-label"
            id="team-select"
            className="select-input"
            value={formData.videoTypeId ? formData.videoTypeId : ""}
            onChange={(event) => onOptionChange(event, "team-select")}
            required
            displayEmpty
            renderValue={(selected) => {
              let type;
              if (
                (selected === undefined || selected.length === 0) &&
                !formData.videoTypeId
              ) {
                return <>{t("Select Type")}</>;
              } else if (formData.videoTypeId) {
                type =
                  Teams &&
                  Teams.find((element) => {
                    return element.id === formData.videoTypeId;
                  });
              } else if (formData.videoTypeId && !selected) {
                type =
                  Teams &&
                  Teams.find((element) => {
                    return element.id === formData.videoTypeId;
                  });
              }
              return `${t(type.type)}`;
            }}
          >
            {Teams &&
              Teams.length > 0 &&
              Teams.map((element, index) => {
                return (
                  <MenuItem key={index} value={element.id}>
                    {t(element.type)}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        {videoSubTypes && videoSubTypes.length > 0 && (
          <div className="align-items-center">
            <InputLabel id="team-select-label">{t("Sub Type")}</InputLabel>
            <Select
              labelid="team-select-label"
              id="select-subType"
              className="select-input"
              value={formData.videoSubTypeId ? formData.videoSubTypeId : ""}
              onChange={(event) => onOptionChange(event, "select-subType")}
              required
              displayEmpty
              renderValue={(selected) => {
                let subType;
                if (
                  (selected === undefined || selected.length === 0) &&
                  !formData.videoSubTypeId
                ) {
                  return <>{t("Select Sub Type")}</>;
                } else if (formData.videoSubTypeId && selected) {
                  subType = videoSubTypes.find((element) => {
                    return (
                      element.id === selected &&
                      selected === formData.videoSubTypeId
                    );
                  });
                } else if (formData.videoSubTypeId && !selected) {
                  subType = videoSubTypes.find((element) => {
                    return element.id === formData.videoSubTypeId;
                  });
                }
                return `${t(subType ? subType.subType : "")}`;
              }}
            >
              {videoSubTypes &&
                videoSubTypes.length > 0 &&
                videoSubTypes.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element.id}>
                      {t(element.subType)}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        )}
        <div className="align-items-center">
          <InputLabel id="language-select-label" className="required">
            {t("Language")}
          </InputLabel>
          <Select
            labelid="language-select-label"
            id="language-select"
            className="select-input"
            displayEmpty
            value={formData.language ? formData.language : ""}
            onChange={(event) => onOptionChange(event, "language-select")}
            required
            renderValue={(selected) => {
              let language;
              if (
                (selected === undefined || selected.length === 0) &&
                !formData.language
              ) {
                return <>{t("Select Language")}</>;
              } else if (
                formData.language &&
                selected &&
                formData.language === selected
              ) {
                language = formData.language === "da" ? "Danish" : "English";
              } else if (formData.language && !selected) {
                language = formData.language === "da" ? "Danish" : "English";
              }
              return `${t(language)}`;
            }}
          >
            <MenuItem value="en">{t("English")}</MenuItem>
            <MenuItem value="da">{t("Danish")}</MenuItem>
          </Select>
        </div>
        <div className="align-items-center">
          <InputLabel id="subtitleLanguage-select-label">
            {t("Subtitle language")}
          </InputLabel>
          <Select
            multiple
            input={<OutlinedInput />}
            labelid="subtitleLanguage-select-label"
            id="subtitleLanguage-select"
            className="select-input"
            displayEmpty
            MenuProps={MenuProps}
            onChange={(event) =>
              onOptionChange(event, "subtitleLanguage-select")
            }
            value={
              formData.subtitleLanguage
                ? formData.subtitleLanguage.split(",")
                : []
            }
            renderValue={(selected) => {
              let language = [];
              selected.forEach((item) => {
                language.push(item === "da" ? t("Danish") : t("English"));
              });
              return language.join(", ");
            }}
          >
            <MenuItem value="en">
              <Checkbox
                checked={
                  formData.subtitleLanguage &&
                  formData.subtitleLanguage.split(",").indexOf("en") > -1
                    ? true
                    : false
                }
              />
              {t("English")}
            </MenuItem>
            <MenuItem value="da">
              <Checkbox
                checked={
                  formData.subtitleLanguage &&
                  formData.subtitleLanguage.split(",").indexOf("da") > -1
                    ? true
                    : false
                }
              />
              {t("Danish")}
            </MenuItem>
          </Select>
        </div>
        <div className="align-items-center">
          <InputLabel id="date-select-label" className="required">
            {t("Date")}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="date-picker"
              inputFormat="dd/MM/yyyy"
              onChange={(event) => onOptionChange(event, "date-picker")}
              value={formData.date ? formData.date.substring(0, 10) : null}
              required
              renderInput={(params) => (
                <TextField className="upload-form-date-picker" {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="align-items-center">
          <InputLabel id="date-select-label" className="required">
            {t("Start Time")}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              id="time-picker"
              onChange={(event) => onOptionChange(event, "time-picker")}
              value={formData.startTime ? formData.startTime : null}
              inputFormat="HH:mm"
              required
              renderInput={(params) => (
                <TextField className="upload-form-date-picker" {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="align-items-center">
          <InputLabel id="note-input-label">{t("Notes")}</InputLabel>
          <TextField
            id="note-input"
            labelid="note-input-label"
            multiline
            rows={3}
            value={formData.notes ? formData.notes : ""}
            variant="standard"
            onChange={(event) => onOptionChange(event, "note-input")}
            className="notes-input"
            placeholder={t("Notes")}
          />
        </div>

        <div className="align-items-center">
          <InputLabel id="url-key-label">{t("Tag/Focus")}</InputLabel>
          {/* <TextField
                    className='upload-form-input'
                    placeholder={t("Tag/Focus")}
                    value={formData.tag ? formData.tag : ""}
                    inputProps={{ "aria-label": "description" }}
                    id='tag-select'
                    onChange={(event) => onOptionChange(event, "tag-select")}
                    /> */}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={formData.tags ? formData.tags : []}
            onChange={(event) => onOptionChange(event, "tag-select")}
            input={<OutlinedInput />}
            className="live-event-tags-input"
            renderValue={(selected) => setTagsNameData(selected)}
            MenuProps={MenuProps}
          >
            {tags.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <Checkbox
                  checked={
                    formData.tags && formData.tags.indexOf(item.id) > -1
                      ? true
                      : false
                  }
                />
                <ListItemText primary={t(item.name)} />
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="align-items-center upload-button-block">
          <button
            type="button"
            onClick={id ? handleUpdateVideo : uploadVideo}
            className="upload-button"
          >
            {id ? "Update" : "Upload"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Upload;
