import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import userService from "../services/user.service";

export const getMostPopularVideos = createAsyncThunk(
  "mostPopularVideos/getMostPopularVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getMostPopularVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const setMostPopularVideosPage = createAsyncThunk(
  "mostPopularVideos/setMostPopularVideosPage",
  async (page, thunkAPI) => {
      return page;
  }
);

const initialState = { videos: [], isFetching: false, isFetched: false, page: 1 };

const mostPopularVideosSlice = createSlice({
  name: "mostPopularVideos",
  initialState,
  extraReducers: {
    [getMostPopularVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      state.videos = [...state.videos, ...action.payload];
      state.page = state.page + 1;
    },
    [getMostPopularVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    }
  },
});

const { reducer } = mostPopularVideosSlice;
export default reducer;
