import React, { Suspense, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useSelector } from "react-redux";

// Routes
import PublicRoute from "../Routes/PublicRoutes";

import InstructorScreen from "./Instructor/Instructor";
import AdminScreen from "./Admin/Admin";
import { useTranslation } from "react-i18next";

const hist = createBrowserHistory();

function App() {
  const { userDetails } = useSelector((state) => state.userData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const role = userDetails ? userDetails.userType : "";
  const { i18n } = useTranslation();

  useEffect(() => {
    var language = localStorage.getItem("language_key");

    if (language) {
      i18n.changeLanguage(language);
    } else if (userDetails && userDetails.language) {
      i18n.changeLanguage(userDetails.language);
      localStorage.setItem("language_key", userDetails.language);
    }
  }, [userDetails, role]);

  return (
    <Router history={hist}>
      <Suspense fallback="loading">
        {userDetails && isLoggedIn ? (
          role !== "Admin" ? (
            <InstructorScreen />
          ) : (
            <AdminScreen />
          )
        ) : (
          <PublicRoute />
        )}
      </Suspense>
    </Router>
  );
}

export default App;
