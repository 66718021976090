import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import userService from "../services/user.service";

export const fetchLiveEvents = createAsyncThunk(
    "fetchLiveEvent/fetchLiveEvents",
    async (queryParams, thunkAPI) => {
      try {
        const response = await userService.fetchLiveEvents(queryParams)
        thunkAPI.dispatch(setMessage(response.data))
        return response.data.details
      } catch (err) {
        thunkAPI.dispatch(setMessage(err.response));
        return thunkAPI.rejectWithValue(err.response.data);
      }
    }
  )
 
  const initialState = { liveEvents: [], isFetching: false, isFetched: false };

  const fetchLiveEventSlice = createSlice({
    name: "fetchLiveEvent",
    initialState,
    extraReducers: {
      [fetchLiveEvents.fulfilled]: (state, action) => {
       state.liveEvents = action.payload
       state.isFetching = false
       state.isFetched = true
      },
      [fetchLiveEvents.rejected]: (state, action) => {
        state.isFetching = false;
        state.isFetched = false;
      }
    },
  });
  
  const { reducer } = fetchLiveEventSlice;
  export default reducer;