import React, { useEffect, useInsertionEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Snackbar,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Pagination,
} from "@mui/material";
import {
  getTeams,
  getInstructors,
  getVideoSubType,
} from "../../slices/uploadData";
import MuiAlert from "@mui/material/Alert";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import "./Upload-session.css";
import userService from "../../services/user.service";
import moment from "moment";
import Spinner from "../ProgressBar/Spinner";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { getAllTags } from "../../slices/tags";
import { setMessage } from "../../slices/message";
import { useParams } from "react-router-dom/";
import { getAllSessions } from "../../slices/all-sessions";
import dayjs from "dayjs";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 270,
    },
  },
};

function UploadSession() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    session: {},
    videos: [],
  });
  const [time, setTime] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [uploadResponse, setUploadResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const token = useSelector((state) => state.message.token);
  const Teams = useSelector((state) => state.uploadData.teams);
  const Instructors = useSelector((state) => state.uploadData.instructors);
  const videoSubTypes = useSelector((state) => state.uploadData.subtypes);
  const { isLoading } = useSelector((state) => state.uploadData);
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state.allTags);
  const { id } = useParams();
  const { sessions } = useSelector((state) => state.allSessions);
  const [allVideo, setAllVideos] = useState([]);
  const [sessionDetails, setSessionDetails] = useState(() => {
    return sessions.find((element) => element.id === id);
  });

  useEffect(() => {
    const fetchSessionDetails = async () => {
      try {
        var newFormData = {
          ...formData,
        };

        newFormData.session = {
          ...sessionDetails,
          tagList:  sessionDetails.tags ? [...sessionDetails.tags.filter((tag) => tag !== null).map((tag) => tag.id)] : [],
        };

        var sessionVideosResponse = await userService.getSessionVideosById(
          sessionDetails.id
        );
        var sessionVideos =
          sessionVideosResponse.data.details &&
          sessionVideosResponse.data.details.map((video) => {
            return {
              ...video,
              tagList: video.tags ?  [...video.tags.filter((tag) => tag !== null).map((tag) => tag.id)] : [],
            };
          });

        newFormData.videos = [...sessionVideos];
        setFormData(newFormData);
        setTime(newFormData.session.startTime);
        setSelectedPage(0);
      } catch (error) {
        console.log(error);
        dispatch(setMessage(error.response));
      }
    };

    if (sessionDetails) {
      fetchSessionDetails();
    }
  }, [sessionDetails]);

  const handleTagsChange = (event) => {
    const updateArr = [...formData.videos];

    const {
      target: { value },
    } = event;

    if (selectedPage === 0) {
      setFormData({
        ...formData,
        session: {
          ...formData.session,
          tagList: typeof value === "string" ? value.split(",") : value,
        },
        videos: updateArr,
      });
    } else {
      updateArr[selectedPage - 1].tagList = event.target.value;
      setFormData({ ...formData, videos: updateArr });
    }
  };

  const setTagsNameData = (selected) => {
    let namesArray = [];

    selected.forEach((item) => {
      tags.forEach((obj) => {
        if (item === obj.id) namesArray.push(t(obj.name));
      });
    });
    return namesArray.join(", ");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAllTags());
    dispatch(getTeams());
    dispatch(getInstructors());
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (
      selectedPage !== 0 &&
      formData.videos[selectedPage - 1].videoTypeId !== ""
    ) {
      dispatch(getVideoSubType(formData.videos[selectedPage - 1].videoTypeId));
    }
    if (
      formData.session &&
      formData.session.videoTypeId !== undefined &&
      selectedPage === 0
    ) {
      dispatch(getVideoSubType(formData.session.videoTypeId));
    }
    // eslint-disable-next-line
  }, [
    selectedPage === 0
      ? formData.session.videoTypeId
      : formData.videos[selectedPage - 1].videoTypeId,
    token,
  ]);

  const onOptionChange = (event, id) => {
    const updateArr = [...formData.videos];
    switch (id) {
      case "sessionTitle":
        setFormData({
          ...formData,
          session: { ...formData.session, sessionTitle: event.target.value },
        });
        break;
      case "video-url":
        updateArr[selectedPage - 1].videoUrl = event.target.value;
        setFormData({ ...formData, videos: updateArr });
        break;
      case "instructor-select":
        if (selectedPage === 0) {
          setFormData({
            ...formData,
            session: {
              ...formData.session,
              userId: event.target.value,
            },
          });
        } else {
          updateArr[selectedPage - 1].userId = event.target.value;
          setFormData({ ...formData, videos: updateArr });
        }
        break;
      case "type-select":
        if (selectedPage === 0) {
          setFormData({
            ...formData,
            session: {
              ...formData.session,
              videoTypeId: event.target.value,
              videoSubTypeId: "",
            },
          });
        } else {
          updateArr[selectedPage - 1].videoTypeId = event.target.value;
          updateArr[selectedPage - 1].videoSubTypeId = "";
          setFormData({ ...formData, videos: updateArr });
        }
        break;
      case "subType-select":
        if (selectedPage === 0) {
          setFormData({
            ...formData,
            session: {
              ...formData.session,
              videoSubTypeId: event.target.value,
            },
          });
        } else {
          updateArr[selectedPage - 1].videoSubTypeId = event.target.value;
          setFormData({ ...formData, videos: updateArr });
        }
        break;
      case "language-select":
        if (selectedPage === 0) {
          setFormData({
            ...formData,
            session: {
              ...formData.session,
              language: event.target.value,
            },
          });
        } else {
          updateArr[selectedPage - 1].language = event.target.value;
          setFormData({ ...formData, videos: updateArr });
        }
        break;
      case "date-picker":
        updateArr[selectedPage - 1].date = dayjs(event).format("YYYY-MM-DD");
        setFormData({ ...formData, videos: updateArr });
        break;
      case "time-picker":
        setTime(event);
        if (selectedPage === 0) {
          setFormData({
            ...formData,
            session: {
              ...formData.session,
              startTime: event.toISOString(),
            },
          });
        } else {
          updateArr[selectedPage - 1].startTime = event.toISOString();
          setFormData({ ...formData, videos: updateArr });
        }
        break;
      case "note-input":
        if (selectedPage === 0) {
          setFormData({
            ...formData,
            session: {
              ...formData.session,
              notes: event.target.value,
            },
            videos: updateArr,
          });
        } else {
          updateArr[selectedPage - 1].notes = event.target.value;
          setFormData({ ...formData, videos: updateArr });
        }
        break;
      case "tag-select":
        // if(selectedPage === 0){
        //     setFormData({
        //         ...formData,
        //         session: {
        //             ...formData.session,
        //           tag: event.target.value,
        //         },
        //         videos: updateArr,
        //       })
        // }else {
        //     updateArr[selectedPage - 1].tag = event.target.value
        //     setFormData({ ...formData, videos: updateArr });
        // }
        break;
      default:
        break;
    }
  };

  const addVideo = () => {
    const validateVideosObj = Object.values(formData.videos).map(
      (values) =>
        values.videoUrl === "" ||
        values.userId === "" ||
        values.videoTypeId === "" ||
        values.language === "" ||
        values.startTime === "" ||
        values.date === ""
    );
    if (
      selectedPage === 0 &&
      (!formData.session.sessionTitle ||
        !formData.session.userId ||
        !formData.session.videoTypeId ||
        !formData.session.language ||
        !formData.session.startTime)
    ) {
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      setOpen(true);
    } else if (
      selectedPage == 0 &&
      videoSubTypes.length &&
      !formData.session.videoSubTypeId
    ) {
      setOpen(true);
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      return;
    } else if (
      selectedPage > 0 &&
      videoSubTypes.length &&
      !formData.videos[selectedPage - 1].videoSubTypeId
    ) {
      setOpen(true);
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      return;
    } else if (formData.videos.length && validateVideosObj.includes(true)) {
      setOpen(true);
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      return;
    } else {
      window.scrollTo(0, 0);
      let videos = formData.videos;
      let fd = {
        videoUrl: "",
        videoTypeId: "",
        videoSubTypeId: null,
        language: "",
        userId: "",
        startTime: "",
        date: "",
        notes: "",
        tagList: [],
      };
      videos.push(fd);
      setSelectedPage(() => selectedPage + 1);
    }
  };

  const uploadVideo = (event) => {
    event.preventDefault();
    // return;
    const validateVideosObj = Object.values(formData.videos).map(
      (values) =>
        values.videoUrl === "" ||
        values.userId === "" ||
        values.videoTypeId === "" ||
        values.language === "" ||
        values.startTime === "" ||
        values.date === ""
    );
    if (
      !formData.session.sessionTitle ||
      !formData.session.userId ||
      !formData.session.language ||
      !formData.session.startTime ||
      !formData.session.videoTypeId
    ) {
      setOpen(true);
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      return;
    }
    if (!formData.videos.length) {
      setUploadResponse({
        status: "error",
        errors: ["Session_video_required"],
      });
      setOpen(true);
      return;
    }
    if (formData.videos.length && validateVideosObj.includes(true)) {
      setOpen(true);
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      return;
    }

    if(!formData.session.videoSubTypeId) {
      formData.session.videoSubTypeId = null;
    }

    formData.videos.forEach((video) => {
      if(!video.videoSubTypeId) {
        video.videoSubTypeId = null;
      }
    });

    if (id) {
      userService
        .updateSession(formData)
        .then((response) => {
          setUploadResponse(response.data);
          setOpen(true);
          // setFormData({ session: {}, videos: [{}] });
          setSelectedPage(0);
          setTime("");
        })
        .catch((error) => {
          dispatch(setMessage(error.response));
          setUploadResponse(error.response);
          setOpen(true);
        });
    } else {
      userService
        .uploadSession(formData)
        .then((response) => {
          setUploadResponse(response.data);
          setOpen(true);
          // setFormData({ session: {}, videos: [{}] });
          setSelectedPage(0);
          setTime("");
        })
        .catch((error) => {
          dispatch(setMessage(error.response));
          setUploadResponse(error.response);
          setOpen(true);
        });
    }
  };

  const createFormData = (video, index) => {
    return (
      <form
        className="upload-form"
        noValidate
        autoComplete="off"
        onSubmit={uploadVideo}
      >
        <div className="align-items-center">
          <HomeIcon
            className={`home-icon ${selectedPage === 0 ? "active" : ""}`}
            onClick={() => {
              setSelectedPage(0);
            }}
          />
          {formData.videos.length >= 1 && (
            <Pagination
              hideNextButton={true}
              hidePrevButton={true}
              className="upload-form-input"
              count={formData.videos.length}
              page={selectedPage}
              onChange={(event, page) => {
                setSelectedPage(page);
              }}
            />
          )}
        </div>
        {selectedPage === 0 && (
          <div className="align-items-center">
            <InputLabel id="session-label" className="required">
              {t("Session Label")}
            </InputLabel>
            <TextField
              required
              className="upload-form-input"
              placeholder={t("Session Label")}
              value={formData.session.sessionTitle}
              inputProps={{ "aria-label": "description" }}
              id="sessionTitle"
              onChange={(event) => onOptionChange(event, "sessionTitle")}
            />
          </div>
        )}
        {selectedPage !== 0 && (
          <div className="align-items-center">
            <InputLabel id="url-key-label" className="required">
              {t("URL key")}
            </InputLabel>
            <TextField
              required
              className="upload-form-input"
              placeholder={t("URL key")}
              value={
                formData.videos[selectedPage - 1].videoUrl &&
                formData.videos[selectedPage - 1].videoUrl
              }
              inputProps={{ "aria-label": "description" }}
              id="video-url"
              onChange={(event) => onOptionChange(event, "video-url")}
            />
          </div>
        )}
        {selectedPage === 0 ? (
          <div className="align-items-center">
            <InputLabel id="instructor-select-label" className="required">
              {t("Instructor")}
            </InputLabel>
            <Select
              displayEmpty
              placeholder={t("Instructor")}
              labelid="instructor-select-label"
              id="instructor-select"
              className="select-input"
              value={formData.session.userId && formData.session.userId}
              renderValue={(selected) => {
                let instructor;
                if (
                  (selected === undefined || selected.length === 0) &&
                  !formData.session.userId
                ) {
                  return <>{t("Select Instructor")}</>;
                } else if (formData.session.userId && selected) {
                  instructor = Instructors.find((element) => {
                    return (
                      element.id === selected &&
                      selected === formData.session.userId
                    );
                  });
                } else if (formData.session.userId && !selected) {
                  instructor = Instructors.find((element) => {
                    return element.id === formData.session.userId;
                  });
                }
                return `${instructor.firstName} ${instructor.lastName}`;
              }}
              onChange={(event) => onOptionChange(event, "instructor-select")}
              required
            >
              <MenuItem disabled value="">
                <>{t("Select Instructor")}</>
              </MenuItem>
              {Instructors.map((element, index) => {
                return (
                  <MenuItem key={index} value={element.id}>
                    {element.firstName} {element.lastName}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        ) : (
          <div className="align-items-center">
            <InputLabel id="instructor-select-label" className="required">
              {t("Instructor")}
            </InputLabel>
            <Select
              displayEmpty
              placeholder={t("Instructor")}
              labelid="instructor-select-label"
              id="instructor-select"
              className="select-input"
              value={
                formData.videos[selectedPage - 1].userId &&
                formData.videos[selectedPage - 1].userId
              }
              renderValue={(selected) => {
                let instructor;
                if (
                  (selected === undefined || selected.length === 0) &&
                  !formData.videos[selectedPage - 1].userId
                ) {
                  return <>{t("Select Instructor")}</>;
                } else if (
                  formData.videos[selectedPage - 1].userId &&
                  selected
                ) {
                  instructor = Instructors.find((element) => {
                    return (
                      element.id === selected &&
                      selected === formData.videos[selectedPage - 1].userId
                    );
                  });
                } else if (
                  formData.videos[selectedPage - 1].userId &&
                  !selected
                ) {
                  instructor = Instructors.find((element) => {
                    return (
                      element.id === formData.videos[selectedPage - 1].userId
                    );
                  });
                }
                return `${instructor.firstName} ${instructor.lastName}`;
              }}
              onChange={(event) => onOptionChange(event, "instructor-select")}
              required
            >
              <MenuItem disabled value="">
                <>{t("Select Instructor")}</>
              </MenuItem>
              {Instructors.map((element, index) => {
                return (
                  <MenuItem key={index} value={element.id}>
                    {element.firstName} {element.lastName}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}

        {selectedPage === 0 ? (
          <div className="align-items-center">
            <InputLabel id="type-select-label" className="required">
              {t("Type")}
            </InputLabel>
            <Select
              displayEmpty
              labelid="type-select-label"
              id="type-select"
              className="select-input"
              value={
                formData.session.videoTypeId && formData.session.videoTypeId
              }
              renderValue={(selected) => {
                let type;
                if (
                  (selected === undefined || selected.length === 0) &&
                  !formData.session.videoTypeId
                ) {
                  return <>{t("Select Type")}</>;
                } else if (formData.session.videoTypeId && selected) {
                  type = Teams.find((element) => {
                    return (
                      element.id === selected &&
                      selected === formData.session.videoTypeId
                    );
                  });
                } else if (formData.session.videoTypeId && !selected) {
                  type = Teams.find((element) => {
                    return element.id === formData.session.videoTypeId;
                  });
                }
                return `${t(type.type)}`;
              }}
              onChange={(event) => onOptionChange(event, "type-select")}
              required
            >
              {Teams &&
                Teams.length > 0 &&
                Teams.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element.id}>
                      {t(element.type)}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        ) : (
          <div className="align-items-center">
            <InputLabel id="type-select-label" className="required">
              {t("Type")}
            </InputLabel>
            <Select
              displayEmpty
              labelid="type-select-label"
              id="type-select"
              className="select-input"
              value={
                formData.videos[selectedPage - 1].videoTypeId &&
                formData.videos[selectedPage - 1].videoTypeId
              }
              renderValue={(selected) => {
                let type;
                if (
                  (selected === undefined || selected.length === 0) &&
                  !formData.videos[selectedPage - 1].videoTypeId
                ) {
                  return <>{t("Select Type")}</>;
                } else if (
                  formData.videos[selectedPage - 1].videoTypeId &&
                  selected
                ) {
                  type = Teams.find((element) => {
                    return (
                      element.id === selected &&
                      selected === formData.videos[selectedPage - 1].videoTypeId
                    );
                  });
                } else if (
                  formData.videos[selectedPage - 1].videoTypeId &&
                  !selected
                ) {
                  type = Teams.find((element) => {
                    return (
                      element.id ===
                      formData.videos[selectedPage - 1].videoTypeId
                    );
                  });
                }
                return `${t(type.type)}`;
              }}
              onChange={(event) => onOptionChange(event, "type-select")}
              required
            >
              {Teams &&
                Teams.length > 0 &&
                Teams.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element.id}>
                      {t(element.type)}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        )}
        {selectedPage === 0 && videoSubTypes.length ? (
          <div className="align-items-center">
            <InputLabel id="team-select-label" className="required">
              {t("Sub Type")}
            </InputLabel>
            <Select
              displayEmpty
              labelid="subType-select-label"
              id="select-subType"
              className="select-input"
              onChange={(event) => onOptionChange(event, "subType-select")}
              renderValue={(selected) => {
                let subType;
                if (
                  formData.session.videoSubTypeId === "" ||
                  formData.session.videoSubTypeId === undefined
                ) {
                  return <>{t("Select Sub Type")}</>;
                } else if (
                  formData.session.videoSubTypeId !== "" &&
                  selectedPage === 0
                ) {
                  subType =
                    videoSubTypes &&
                    videoSubTypes.find((element) => {
                      return element.id === formData.session.videoSubTypeId;
                    });
                } else if (
                  formData.session.videoSubTypeId !== "" &&
                  formData.session.videoSubTypeId !== undefined
                ) {
                  subType =
                    videoSubTypes &&
                    videoSubTypes.find((element) => {
                      return element.id === formData.session.videoSubTypeId;
                    });
                }
                return subType !== undefined ? t(subType.subType) : "";
              }}
              required
            >
              {videoSubTypes &&
                videoSubTypes.length > 0 &&
                videoSubTypes.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element.id}>
                      {t(element.subType)}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        ) : selectedPage !== 0 && videoSubTypes.length ? (
          <div className="align-items-center">
            <InputLabel id="team-select-label" className="required">
              {t("Sub Type")}
            </InputLabel>
            <Select
              displayEmpty
              labelid="subType-select-label"
              id="select-subType"
              className="select-input"
              onChange={(event) => onOptionChange(event, "subType-select")}
              renderValue={(selected) => {
                let subType;
                if (formData.videos[selectedPage - 1].videoSubTypeId === "") {
                  return <>{t("Select Sub Type")}</>;
                } else if (
                  formData.videos[selectedPage - 1].videoSubTypeId !== "" &&
                  selectedPage !== 0 &&
                  selected
                ) {
                  subType =
                    videoSubTypes &&
                    videoSubTypes.find((element) => {
                      return element.id === selected;
                    });
                } else if (
                  formData.videos[selectedPage - 1].videoSubTypeId !== "" &&
                  !selected
                ) {
                  subType =
                    videoSubTypes &&
                    videoSubTypes.find((element) => {
                      return (
                        element.id ===
                        formData.videos[selectedPage - 1].videoSubTypeId
                      );
                    });
                }
                return subType !== undefined ? t(subType.subType) : "";
              }}
              required
            >
              {videoSubTypes &&
                videoSubTypes.length > 0 &&
                videoSubTypes.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element.id}>
                      {t(element.subType)}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        ) : (
          ""
        )}
        {selectedPage === 0 ? (
          <div className="align-items-center">
            <InputLabel id="language-select-label" className="required">
              {t("Language")}
            </InputLabel>
            <Select
              displayEmpty
              labelid="language-select-label"
              id="language-select"
              className="select-input"
              value={formData.session.language && formData.session.language}
              renderValue={(selected) => {
                let language;
                if (
                  (selected === undefined || selected.length === 0) &&
                  !formData.session.language
                ) {
                  return <>{t("Select Language")}</>;
                } else if (
                  formData.session.language &&
                  selected &&
                  formData.session.language === selected
                ) {
                  language =
                    formData.session.language === "da" ? "Danish" : "English";
                } else if (formData.session.language && !selected) {
                  language =
                    formData.session.language === "da" ? "Danish" : "English";
                }
                return `${t(language)}`;
              }}
              onChange={(event) => onOptionChange(event, "language-select")}
              required
            >
              <MenuItem disabled value="">
                <>{t("Select Language")}</>
              </MenuItem>
              <MenuItem value="en">{t("English")}</MenuItem>
              <MenuItem value="da">{t("Danish")}</MenuItem>
            </Select>
          </div>
        ) : (
          <div className="align-items-center">
            <InputLabel id="language-select-label" className="required">
              {t("Language")}
            </InputLabel>
            <Select
              displayEmpty
              labelid="language-select-label"
              id="language-select"
              className="select-input"
              value={
                formData.videos[selectedPage - 1].language &&
                formData.videos[selectedPage - 1].language
              }
              renderValue={(selected) => {
                let language;
                if (
                  (selected === undefined || selected.length === 0) &&
                  !formData.videos[selectedPage - 1].language
                ) {
                  return <>{t("Select Language")}</>;
                } else if (
                  formData.videos[selectedPage - 1].language &&
                  selected &&
                  formData.videos[selectedPage - 1].language === selected
                ) {
                  language =
                    formData.videos[selectedPage - 1].language === "da"
                      ? "Danish"
                      : "English";
                } else if (
                  formData.videos[selectedPage - 1].language &&
                  !selected
                ) {
                  language =
                    formData.videos[selectedPage - 1].language === "da"
                      ? "Danish"
                      : "English";
                }
                return `${t(language)}`;
              }}
              onChange={(event) => onOptionChange(event, "language-select")}
              required
            >
              <MenuItem disabled value="">
                <>{t("Select Language")}</>
              </MenuItem>
              <MenuItem value="en">{t("English")}</MenuItem>
              <MenuItem value="da">{t("Danish")}</MenuItem>
            </Select>
          </div>
        )}

        <>
          {selectedPage !== 0 && (
            <div className="align-items-center">
              <InputLabel id="date-select-label" className="required">
                {t("Date")}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id="date-picker"
                  inputFormat="dd/MM/yyyy"
                  onChange={(event) => onOptionChange(event, "date-picker")}
                  value={
                    formData.videos[selectedPage - 1].date &&
                    formData.videos[selectedPage - 1].date
                  }
                  required
                  renderInput={(params) => (
                    <TextField
                      className="upload-form-date-picker"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          )}
          <div className="align-items-center">
            <InputLabel id="date-select-label" className="required">
              {t("Start Time")}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                id="time-picker"
                onChange={(event) => onOptionChange(event, "time-picker")}
                value={(selectedPage !== 0) ? formData.videos[selectedPage - 1].startTime  : formData.session.startTime ? formData.session.startTime : null }
                views={["hours", "minutes"]}
                inputFormat="HH:mm"
                required
                renderInput={(params) => (
                  <TextField className="upload-form-date-picker" {...params} />
                )}
              />
            </LocalizationProvider>
          </div>
        </>
        {selectedPage === 0 ? (
          <div className="align-items-center">
            <InputLabel id="note-input-label">{t("Notes")}</InputLabel>
            <TextField
              id="note-input"
              labelid="note-input-label"
              multiline
              rows={3}
              value={formData.session.notes && formData.session.notes}
              variant="standard"
              onChange={(event) => onOptionChange(event, "note-input")}
              className="notes-input"
              placeholder={t("Notes")}
            />
          </div>
        ) : (
          <div className="align-items-center">
            <InputLabel id="note-input-label">{t("Notes")}</InputLabel>
            <TextField
              id="note-input"
              labelid="note-input-label"
              multiline
              rows={3}
              value={
                formData.videos[selectedPage - 1].notes &&
                formData.videos[selectedPage - 1].notes
              }
              variant="standard"
              onChange={(event) => onOptionChange(event, "note-input")}
              className="notes-input"
              placeholder={t("Notes")}
            />
          </div>
        )}
        {selectedPage == 0 ? (
          <div className="align-items-center">
            <InputLabel id="url-key-label">{t("Tag/Focus")}</InputLabel>
            {/* <TextField className="upload-form-input" placeholder={t('Tag/Focus')} 
                            value={formData.session.tag && formData.session.tag} 
                            inputProps={{ 'aria-label': 'description' }} id='tag-select' onChange={(event) => onOptionChange(event,'tag-select')}/> */}
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={
                formData.session.tagList && formData.session.tagList.length > 0
                  ? formData.session.tagList
                  : []
              }
              onChange={handleTagsChange}
              input={<OutlinedInput />}
              className="session-tags-input"
              renderValue={(selected) => setTagsNameData(selected)} // setTagsNameData(selected)
              MenuProps={MenuProps}
            >
              {tags.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox
                    checked={
                      formData.session.tagList
                        ? formData.session.tagList.indexOf(item.id) > -1
                          ? true
                          : false
                        : false
                    }
                  />
                  <ListItemText primary={t(item.name)} />
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : (
          <div className="align-items-center">
            <InputLabel id="url-key-label">{t("Tag/Focus")}</InputLabel>
            {/* <TextField className="upload-form-input" placeholder={t('Tag/Focus')} 
                            value={formData.videos[selectedPage - 1].tag && formData.videos[selectedPage - 1].tag} 
                            inputProps={{ 'aria-label': 'description' }} id='tag-select' onChange={(event) => onOptionChange(event,'tag-select')}/> */}
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={
                formData.videos[selectedPage - 1].tagList
                  ? formData.videos[selectedPage - 1].tagList
                  : []
              }
              onChange={handleTagsChange}
              input={<OutlinedInput />}
              className="session-tags-input"
              renderValue={(selected) => setTagsNameData(selected)} // setTagsNameData(selected)
              MenuProps={MenuProps}
            >
              {tags.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox
                    checked={
                      formData.videos[selectedPage - 1].tagList
                        ? formData.videos[selectedPage - 1].tagList.indexOf(
                            item.id
                          ) > -1
                          ? true
                          : false
                        : false
                    }
                  />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        <div className="align-items-center">
          <InputLabel
            id="url-key-label"
            className="align-items-center"
            style={{ justifyContent: "left" }}
          >
            {t("Add Video")}{" "}
            <AddCircleIcon
              onClick={() => addVideo()}
              className="add-video-icon"
            />{" "}
          </InputLabel>
          <TextField
            className="upload-form-input"
            style={{ visibility: "hidden" }}
            placeholder={t("Add Video")}
            inputProps={{ "aria-label": "description" }}
            id="tag-select"
          />
        </div>
        <div className="align-items-center upload-button-block">
          <button type="submit" className="upload-button">
            Upload
          </button>
        </div>
      </form>
    );
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={
            uploadResponse && uploadResponse.status === "SUCCESS"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {t(
            uploadResponse.status === "SUCCESS"
              ? uploadResponse.successMessage
              : uploadResponse.errors
              ? uploadResponse.errors[0]
              : uploadResponse.data && uploadResponse.data.errors
              ? uploadResponse.data.errors[0]
              : "Something went wrong, Please try again"
          )}
        </Alert>
      </Snackbar>

      {isLoading ? <Spinner /> : null}

      {selectedPage === 0
        ? createFormData()
        : formData.videos.length
        ? formData.videos.map((video, index) => {
            if (index === selectedPage - 1) return createFormData(video, index);
          })
        : ""}
    </div>
  );
}

export default UploadSession;
