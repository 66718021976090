import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

// views
import FavoritesScreen from '../components/Favorites/Favorites';
import SkemaScreen from '../components/Skema/Skema';
import DashboardScreen from '../components/Dashboard/Dashboard';
import Allvideos from '../components/Allvideos/Allvideos';
import UploadScreen from '../components/Upload/Upload';
import PlayerScreen from '../components/Player/Player';
import UploadLive from '../components/Upload Live Event/UploadEvent';
import QuickSearch from '../components/Quick-search/Quick-search';
import UploadSession from '../components/Upload Session/Upload-session';
import Sessions from '../components/Sessions/Sessions';
import SessionPlayer from '../components/SessionPlayer/SessionPlayer';
import AutoLogin from '../screens/Login/AutoLogin';

function InstructorRoutes (){

  let location=useLocation();
  
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [location]);

    return (
      <Switch>
        <Route path="/dashboard" component={DashboardScreen} />
        <Route path="/all-videos/:typeId?" component={Allvideos} />
        <Route path="/all-videos" component={Allvideos} />
        <Route path="/favorites" component={FavoritesScreen} />
        <Route path="/skema" component={SkemaScreen} />
        <Route path="/sessions/:id" component={UploadSession} />
        <Route path="/sessions" component={Sessions} />
        <Route path="/session-player/:sessionId" component={SessionPlayer} />
        {/* <Route path="/upload/:id?" component={UploadScreen} /> */}
        {/* <Route path="/upload" component={UploadScreen} /> */}
        {/* <Route path="/upload-session" component={UploadSession} /> */}
        <Route path="/quick-search/:type" component={QuickSearch} />
        <Route path="/player" component={PlayerScreen} />
        {/* <Route path="/upload-live-event" component={UploadLive} /> */}
        <Route exact path="/auto-login" component={AutoLogin} />
        <Redirect to="/dashboard" />
      </Switch>
    );
}

export default InstructorRoutes;