import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import authService from "../../services/auth.service";

function AutoLogin() {
  var { search } = useLocation();
  var [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);
    console.log("urlSearchParams: ", urlSearchParams);
    const refreshToken = urlSearchParams.get("refreshToken");

    if (refreshToken) {
      authService.refreshToken(refreshToken).then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("auth", response.data.access_token);
        }
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 100);
      });
    }

  }, [search]);

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      {!redirectTo && <div>Logging in...</div>}
    </div>
  );
}

export default AutoLogin;
