import React from 'react';
import "./Footer.css";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Pictures
import logo from "../../img/GetUpp_logo_GREY_BLUE.png";
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import {Grid} from '@mui/material';
import { Facebook, LinkedIn } from '@mui/icons-material';

function Footer() {
    const {t} = useTranslation();

    return (
        <div className="footer">
                <div className='overlay'></div>
                <div className="footLogo">
                    <img className="footLogoImg" src={logo} alt=""/>
                </div>
                
                <Grid container spacing={0}  className="footInfo">
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div className="footAdress">
                            <p className="footHead"><strong>OfficeFit A/S</strong></p>
                            <p>
                                Nupark 51<br />
                                7500 Holstebro<br /><br />
                                Cvr. nr. 40654259<br />
                                {/* <a className="footLink" href="http://www.getupp.dk" target="_blank" rel="noreferrer noopener">
                                    www.GetUpp.dk
                                </a> */}
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div className="footContact">
                            <p className="footHead"><strong>{t('Contact and Support')}</strong></p>
                            <p className="footContactInfo">
                                <a href="tel:+4572727257"><FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon> +45 72 72 72 57</a><br/>
                                <a href="mailto:active@officefit.dk"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> active@officefit.dk</a>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <p className="footHead"><strong></strong></p>
                        <p className="footLink">
                            <a href="https://www.iubenda.com/privacy-policy/44229982" target="_blank" rel="noreferrer noopener">
                                {t('Privacy Policy')}
                            </a><br/>
                            <a href="https://www.iubenda.com/privacy-policy/44229982/cookie-policy" target="_blank" rel="noreferrer noopener">
                                {t('Terms and Conditions')}
                            </a><br/>
                            <a href="https://www.iubenda.com/terms-and-conditions/44229982" target="_blank" rel="noreferrer noopener">
                                {t('Cookie Policy')}
                            </a>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div className="footSocial">
                            <p className="footHead"><strong>Find us</strong></p>
                            <a href="https://www.linkedin.com/company/it-s-time-to-getupp" target="_blank" rel="noreferrer noopener">
                                <LinkedIn className="faIconFoot" />
                            </a>
                            <a href="https://www.facebook.com/GetUpp-102981038605030/" target="_blank" rel="noreferrer noopener">
                                <Facebook className="faIconFoot" />
                            </a><br />
                            {/* <a href="https://apps.apple.com/us/app/getupp-motivation/id1542569656" target="_blank" rel="noreferrer noopener"><img className="footStore" src={appStore} alt="" /></a> <br /> */}
                            {/* <a href="https://apps.apple.com/us/app/getupp-motivation/id1542569656" target="_blank" rel="noreferrer noopener">
                                <FontAwesomeIcon className="faIconFoot" icon={faAppStore}></FontAwesomeIcon>
                            </a> */}
                            {/* <a href="https://play.google.com/store/apps/details?id=io.getupp.stayactive" target="_blank" rel="noreferrer noopener"><img className="footStore" src={googlePlay} alt="" /></a> */}
                            {/* <a href="https://apps.apple.com/us/app/getupp-motivation/id1542569656" target="_blank" rel="noreferrer noopener">
                                <FontAwesomeIcon className="faIconFoot" icon={faGooglePlay}></FontAwesomeIcon>
                            </a> <br /> */}
                        </div>
                    </Grid>
                </Grid>
               
                <div className="footInfo">
                    
                </div>
        </div>
    );
}

export default Footer;
