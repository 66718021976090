import configData from "../config/config.json";
import authHeader from "./auth-header";
import axios from "axios";
const VIDEO_API_URL = configData.VIDEOS_URL;
const USER_API_URL = configData.VIDEOS_URL + "user/";
const QUICK_SEARCH_API_URL = configData.VIDEOS_URL + "video/quick/search";
const LAST_PLAYED_VIDEOS_API_URL = configData.VIDEOS_URL + "video/played/fetch";
const MOST_POPULAR_VIDEOS_API_URL =
  configData.VIDEOS_URL + "video/most/popular/";
// Get current user info

const getCurrentUser = () => {
  return axios.get(USER_API_URL, { headers: authHeader() });
};

// tags
const getTags = () => {
  return axios.get(VIDEO_API_URL + `tag/fetch`, { headers: authHeader() });
};

// all-videos
const uploadVideos = (video) => {
  return axios.post(VIDEO_API_URL + "video/save", video, {
    headers: authHeader(),
  });
};
// Update Video
const updateVideo = (data) => {
  return axios.put(VIDEO_API_URL + "video/update", data, {
    headers: authHeader(),
  });
};
// Update session
const updateSessionVideo = (data) => {
  return axios.post(VIDEO_API_URL + "session/updatevideo", data, {
    headers: authHeader(),
  });
};
// Delete video or session
const deleteVideo = (data) => {
  return axios.delete(VIDEO_API_URL + "video/delete", {
    data: data,
    headers: authHeader(),
  });
};
// upload session
const uploadSession = (data) => {
  return axios.post(VIDEO_API_URL + "session/save", data, {
    headers: authHeader(),
  });
};

const updateSession = (data) => {
  return axios.put(VIDEO_API_URL + "session/update", data, {
    headers: authHeader(),
  });
};
const uploadLiveEvent = (data) => {
  return axios.post(VIDEO_API_URL + "video/live/save", data, {
    headers: authHeader(),
  });
};
const getVideoByID = (data) => {
  return axios.post(VIDEO_API_URL + `video/fetch/id`, data, {
    headers: authHeader(),
  });
};
const getAllVideos = (data) => {
  // return axios.get(VIDEO_API_URL + `video/filter`, { headers: authHeader(),'Content-Type': 'application/json', data : JSON.stringify(data)});
  return axios.post(VIDEO_API_URL + `video/filter`, data, {
    headers: authHeader(),
  });
};
const getTeams = () => {
  return axios.get(VIDEO_API_URL + "video/type/fetch", {
    headers: authHeader(),
  });
};
const getInstructors = () => {
  return axios.get(VIDEO_API_URL + "user/fetch/instructor ", {
    headers: authHeader(),
  });
};
// Get Sessions
const getSessions = (data) => {
  return axios.post(VIDEO_API_URL + `session/filter`, data, {
    headers: authHeader(),
  });
};
const getSessionVideosById = (sessionId) => {
  return axios.get(
    VIDEO_API_URL + `session/video/filter?sessionId=${sessionId}`,
    { headers: authHeader() }
  );
};
//favorite videos API
const addVideoToFavorites = (data) => {
  return axios.post(VIDEO_API_URL + `favourite/video/add`, data, {
    headers: authHeader(),
  });
};
const removeVideoFromFavorites = (data) => {
  return axios.post(VIDEO_API_URL + `favourite/video/remove`, data, {
    headers: authHeader(),
  });
};
const getFavoriteVideos = (data) => {
  return axios.post(VIDEO_API_URL + `video/filter`, data, {
    headers: authHeader(),
  });
};
const getFavoritesTeams = () => {
  return axios.get(VIDEO_API_URL + "video/type/fetch?favourites=true", {
    headers: authHeader(),
  });
};
const getFavoritesInstructors = () => {
  return axios.get(VIDEO_API_URL + "user/fetch/instructors?favourites=true", {
    headers: authHeader(),
  });
};

// Quick search API
const getQuickSearchVideos = (queryParams) => {
  return axios.get(QUICK_SEARCH_API_URL + `?${queryParams}`, {
    headers: authHeader(),
  });
};
const getInstructorSearchVideos = (queryParams) => {
  return axios.get(QUICK_SEARCH_API_URL + `?${queryParams}`, {
    headers: authHeader(),
  });
};
const getTypeSearchVideos = (queryParams) => {
  return axios.get(QUICK_SEARCH_API_URL + `?${queryParams}`, {
    headers: authHeader(),
  });
};
// Live Events API
const getLiveEvents = (queryParams) => {
  return axios.get(VIDEO_API_URL + `video/filter?${queryParams}`, {
    headers: authHeader(),
  });
};
const getLiveEventsOfCurrentWeek = (queryParams) => {
  return axios.get(VIDEO_API_URL + `video/filter/?${queryParams}`, {
    headers: authHeader(),
  });
};
// Played session videos
const getPlayedSessionVideos = () => {
  return axios.get(VIDEO_API_URL + `/video/played/add`, {
    headers: authHeader(),
  });
};
// Last Played Videos API
const getLastPlayedVideos = (queryParams) => {
  return axios.get(LAST_PLAYED_VIDEOS_API_URL + `?${queryParams}`, {
    headers: authHeader(),
  });
};
const addToLastPlayed = (data) => {
  return axios.post(VIDEO_API_URL + `video/played/add`, data, {
    headers: authHeader(),
  });
};

// Most Popular Videos API
const getMostPopularVideos = (queryParams) => {
  return axios.get(MOST_POPULAR_VIDEOS_API_URL + `?${queryParams}`, {
    headers: authHeader(),
  });
};

// Video Live Filter
const getVideoLiveFilter = () => {
  return axios.get(VIDEO_API_URL + "video/live/filter", {
    headers: authHeader(),
  });
};
const fetchLiveEvents = (queryParams) => {
  return axios.get(
    VIDEO_API_URL + `video/live/filter/calendar?currentDateTime=${queryParams}`,
    { headers: authHeader() }
  );
};

// Dashboard Carousel
const getDashboardData = (currentDate) => {
  return axios.get(
    VIDEO_API_URL + `video/filter/type/latest?currentDateTime=${currentDate}`,
    { headers: authHeader() }
  );
};
// Get Latest Videos
const getLatestVideos = () => {
  return axios.get(VIDEO_API_URL + "video/filter/uploaddate/latest", {
    headers: authHeader(),
  });
};
// Get Video Sub Types
const getVideoSubType = (queryParams) => {
  return axios.get(
    VIDEO_API_URL + `video/subtype/fetch?videotypeId=${queryParams}`,
    { headers: authHeader() }
  );
};

// Admin - Upload Category
const uploadCategory = (data) => {
  console.log("Data Sent: ", data);
  return axios.post(VIDEO_API_URL + `video/type/subtype/add`, data, {
    headers: authHeader(),
  });
};

// Admin - Upload Tag
const uploadTag = (data) => {
  console.log("Data Sent: ", data);
  return axios.post(VIDEO_API_URL + `tag/save`, data, {
    headers: authHeader(),
  });
};

const deleteSession = (data) => {
  return axios.delete(VIDEO_API_URL + "session/delete", {
    data: data,
    headers: authHeader(),
  });
};

const updateTag = (data) => {
  console.log("Data Sent: ", data);
  return axios.put(VIDEO_API_URL + `tag/update`, data, {
    headers: authHeader(),
  });
};

const deleteTag = (data) => {
  console.log("Data Sent: ", data);
  return axios.delete(VIDEO_API_URL + `tag/delete/${data.id}`, {
    headers: authHeader(),
  });
};

const getTag = (id) => {
  return axios.get(VIDEO_API_URL + `tag/fetch/${id}`, {
    headers: authHeader(),
  });
};

const getUser = (userId) => {
  return axios.get(configData.ACCOUNTS_URL + `${userId}`, { headers: authHeader() });
};

const userService = {
  getCurrentUser,
  getAllVideos,
  getFavoriteVideos,
  getLiveEvents,
  getLiveEventsOfCurrentWeek,
  getLastPlayedVideos,
  getMostPopularVideos,
  getTeams,
  getInstructors,
  getFavoritesTeams,
  getFavoritesInstructors,
  // getDifficultyLevels,
  uploadVideos,
  uploadLiveEvent,
  addVideoToFavorites,
  removeVideoFromFavorites,
  addToLastPlayed,
  getQuickSearchVideos,
  getInstructorSearchVideos,
  getSessionVideosById,
  getTypeSearchVideos,
  getVideoLiveFilter,
  fetchLiveEvents,
  getSessions,
  getDashboardData,
  getLatestVideos,
  getVideoSubType,
  uploadSession,
  uploadCategory,
  uploadTag,
  getSessionVideosById,
  getTags,
  getVideoByID,
  updateVideo,
  updateSessionVideo,
  deleteVideo,
  getUser,
  updateSession,
  deleteSession,
  updateTag,
  deleteTag,
  getTag,
};

export default userService;
