import { createSlice } from "@reduxjs/toolkit";

const initialState = {selectedVideoUrl: ""}

export const selectedVideoUrlSlice = createSlice({
    name: "selectedVideoUrl",
    initialState,
    reducers:{
        videoUrl: (state, action) => {
            state.selectedVideoUrl = action.payload
        }
    }
})

export const { videoUrl } = selectedVideoUrlSlice.actions;

export default selectedVideoUrlSlice.reducer