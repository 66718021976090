import React, { useState, useEffect } from 'react';
import { InputLabel, TextField, Snackbar, Alert, Typography } from '@mui/material';
import {useTranslation} from "react-i18next";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './Add-category.css';
import Checkbox from '@mui/material/Checkbox';
import userService from '../../services/user.service';
import { setMessage } from '../../slices/message';
import { useDispatch } from 'react-redux';

function AddCategory() {
    const [values, setValues] = React.useState([]);
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({status:'',successMessage:'',errors:[]});
    const [typeName, setTypeName] = useState("");
    const [hasSubtype, setHasSubtype] = React.useState(false);
    const dispatch=useDispatch();

    useEffect(() => {
		addValue();
        // eslint-disable-next-line
	}, []);

    const addValue = () => {
        setValues([...values, ""]);
    };

    const handleValueChange = (index, e) => {
        const updatedValues = values.map((value, i) => {
        if (i === index) {
            return e.target.value;
        } else {
            return value;
        }
        });
        setValues(updatedValues);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const handleChange = (event) => {
        setHasSubtype(event.target.checked);
    };
  
    const handleFormData = () => {
        let data = {
            "type" : typeName,
            "hasSubType": hasSubtype,
            ...(hasSubtype && {"subTypes": values})
        };   
        
        if(!typeName){
            setOpen(true);
            setUploadResponse({status:'error',errors: ['FORM_FIELD_DATA_MISSING']});
            return;
        }

        if(hasSubtype && values[0]===''){
            setOpen(true);
            setUploadResponse({status:'error',errors: ['FORM_FIELD_DATA_MISSING']});
            return;
        }
        console.log("Values: ",values);

        userService
        .uploadCategory(data)
        .then((response) => {
          console.log("Response: ",response.data);
          setValues([""]);
          setTypeName("");
          setHasSubtype(false);
          setUploadResponse(response.data);
          setOpen(true);
        })
        .catch((error) => {
          dispatch(setMessage(error.response));
          setUploadResponse(error.response);
          setOpen(true);
        });
    };

        return (
            <div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={uploadResponse && uploadResponse.status === 'SUCCESS' ? 'success' : 'error'} sx={{ width: '100%' }}>
                        {t(uploadResponse.status === 'SUCCESS' ? uploadResponse.successMessage : (uploadResponse.errors ? uploadResponse.errors[0] : (uploadResponse.data && uploadResponse.data.errors ? uploadResponse.data.errors[0] : 'Something went wrong, Please try again.')))}
                    </Alert>
                </Snackbar>

            <form
              className='add-category-form'
              noValidate
              autoComplete='off'
            >
              <Typography variant="h4" className="head-title" gutterBottom component="div">
                  Add Categories
              </Typography>
              <div className='align-items-center'>
                <InputLabel id='category-label' className='required'>
                  {t("Category")}
                </InputLabel>
                <TextField
                  required
                  className='upload-form-input'
                  placeholder={t("Category")}
                  inputProps={{ "aria-label": "description" }}
                  id='event-title'
                  value={typeName ? typeName : ""}
                  onChange={(event) => setTypeName(event.target.value)}
                />
              </div>

              <div
                className='align-items-center'
                style={{ marginRight: "347px" }}
              >
                <InputLabel id='isSubcategory-label' className='required'>
                  {t("Is Sub Category")}
                </InputLabel>
                <Checkbox
                    checked={hasSubtype}
                    onChange={handleChange}    
                />
              </div> 
                
              {values.map((jump, index) => (
                  hasSubtype && <div className={index===0 && index===values.length-1 ? 'subcat-empty-margin align-items-center' : index===0 ? 'align-items-center': index===values.length-1 ? 'subcat-margin-add align-items-center' : 'subcat-margin align-items-center'}>
                  {
                      !index && 
                      (<InputLabel id='subcategory-label' className='required'>
                      {t("Sub Category")}
                    </InputLabel>)
                  }
                  <TextField
                    required
                    className='upload-form-input'
                    placeholder={t("Sub Category Name")}
                    onChange={(e) => handleValueChange(index, e)}
                    inputProps={{ "aria-label": "description" }}
                    id='video-url'
                    disabled={index===values.length-1 ? false : true}
                  />
                  {
                      index===values.length-1 && <AddCircleOutlineIcon sx={{cursor:'pointer', color:'#51c0db', position: 'relative', left:'3px'}} onClick={addValue}/>
                  }
                 </div>
              ))}

              <div className='align-items-center upload-button-block'>
                <button type='button' className='upload-button' onClick={handleFormData}>
                  {t('Add')}
                </button>
              </div>
            </form>
            </div>
        );
    
};

export default AddCategory;
