import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import userService from "../services/user.service";

export const uploadVideoData = createAsyncThunk(
  "uploadVideo/uploadVideoData",
  async (data, thunkAPI) => {
    try {
        const response = await userService.uploadVideos(data);
        thunkAPI.dispatch(setMessage(response.data));
        return response.data;
    } catch (error) {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateVideo = createAsyncThunk(
  "uploadVideo/updateVideo",
  async (data, thunkAPI) => {
    try {
        const response = await userService.updateVideo(data);
        thunkAPI.dispatch(setMessage(response.data));
        return response.data;
    } catch (error) {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateSessionVideo = createAsyncThunk(
  "uploadVideo/updateSessionVideo",
  async (data, thunkAPI) => {
    try {
        const response = await userService.updateSessionVideo(data);
        thunkAPI.dispatch(setMessage(response.data));
        return response.data;
    } catch (error) {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getVideoById = createAsyncThunk(
  "uploadVideo/getVideoById",
  async (data, thunkAPI) => {
    try {
      const response = await userService.getVideoByID(data);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
        thunkAPI.dispatch(setMessage(error.response));
        return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const clearFilledData = createAsyncThunk(
  "uploadVideo/clearFilledData",
  async () => {
    return;
  }
);

const initialState = { videos: [], filledData: {}, isFetching: true, page: 1 };

const uploadVideoSlice = createSlice({
  name: "uploadVideo",
  initialState,
  extraReducers: {
    [uploadVideoData.fulfilled]: (state, action) => {
    },
    [uploadVideoData.rejected]: (state, action) => {
    },
    [getVideoById.fulfilled]: (state, action) => {
      state.filledData={...action.payload[0]};
    },
    [getVideoById.rejected]: (state, action) => {
    },
    [clearFilledData.fulfilled]: (state, action) => {
      state.filledData={};
    },
    [clearFilledData.rejected]: (state, action) => {
    }
  },
});

const { reducer } = uploadVideoSlice;
export default reducer;
