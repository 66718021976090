import React, { useState } from 'react';
import { InputLabel, MenuItem, Select, TextField, Snackbar, Alert } from '@mui/material';
import {useTranslation} from "react-i18next";
import './Add-user.css';
import { useDispatch } from 'react-redux';
import { register, updateUser } from "../../slices/auth";
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import userService from '../../services/user.service';

function AddUser() {
    const [user, setUser] = useState({});
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({status:'',successMessage:'',errors:[]});
    var { id } = useParams();

    useEffect(() => {
        
        const fetchUser = async (id) => {
            if(id){
                var data = await userService.getUser(id)
                .then((response) => {
                   return response.data;                  
                })
                .catch((error) => {
                    console.log(error);
                });

                setUser(() =>{
                    return {
                        ...data,
                        nickName: data.nickname ? data.nickname : '',
                    }
                }); 
            }
        }
        fetchUser(id);
    },[id]);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };
  
    const dispatch = useDispatch();
    
    const handleInputChange = (event, id) => {
        let data = user;
        switch (id) {
            case 'firstname':
                data.firstName = event.target.value;
                break;
            case 'lastname':
                data.lastName = event.target.value;
                break;
            case 'email':
                data.email = event.target.value;
                break;
            case 'password':
                data.password = event.target.value;
                break;
            case 'nickname':
                data.nickName = event.target.value;
                break;
            case 'language':
                data.language = event.target.value;
                break;
            case 'userType':
                data.userType = event.target.value;
                break;
            default:
                break;
        }
        setUser(() => {return { ...user, ...data}});
    };

    const handleRegister = (event) => {
        event.preventDefault();
        if(!user.firstName || !user.lastName || !user.email || !user.password || !user.nickName || !user.language){
            setOpen(true);
            setUploadResponse({status:'error',errors: ['FORM_FIELD_DATA_MISSING']});
            return;
        }
        dispatch(register(user))
            .unwrap()
            .then((response) => {
              setUploadResponse({
                    status:'SUCCESS',
                    successMessage: 'User created successfully', 
                    errors: []
                });
              setOpen(true);
            })
            .catch((error) => {
              setUploadResponse({
                    status:'error',
                    successMessage: '',
                    errors: ['Something went wrong, Please try again.']
              });
              setOpen(true);
      });
    }

      const handSubmit = (event) => {
        event.preventDefault();
        if(!user.firstName || !user.lastName || !user.email || (!user.password && !id) || !user.nickName || !user.language){
            setOpen(true);
            setUploadResponse({status:'error',errors: ['FORM_FIELD_DATA_MISSING']});
            return;
        }

        if(id){
            dispatch(updateUser(user))
            .unwrap()
            .then((response) => {
              setUploadResponse(response);
              setOpen(true);
            })
            .catch((error) => {
              setUploadResponse(error);
              setOpen(true);
            });
        }
        else{
            dispatch(register(user))
            .unwrap()
            .then((response) => {
              setUploadResponse({
                status:'SUCCESS',
                successMessage: 'User created successfully', 
                errors: []
            });
              setOpen(true);
            })
            .catch((error) => {
              setUploadResponse({
                status:'error',
                successMessage: '',
                errors: ['Something went wrong, Please try again.']
                });
              setOpen(true);
            });
        }
        
      }

        return (
            <div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={uploadResponse && uploadResponse.status === 'SUCCESS' ? 'success' : 'error'} sx={{ width: '100%' }}>
                        {t(uploadResponse.status === 'SUCCESS' ? uploadResponse.successMessage : uploadResponse.errors[0])}
                    </Alert>
                </Snackbar>
                
                <p className='section-title'>{!id ? t('Create New User') : t('Update user')} :</p>
                <form className="create-user-form" noValidate autoComplete="off" onSubmit={handSubmit}>
                    <div className='align-items-center'>
                        <InputLabel className='required' id="url-key-label">{t('Firstname')}</InputLabel>
                        <TextField className="create-user-form-input" placeholder="" value={user.firstName} onChange={(event) => handleInputChange(event,'firstname')} inputProps={{ 'aria-label': 'firstname' }} />
                    </div>
                    <div className='align-items-center'>
                        <InputLabel className='required' id="url-key-label">{t('Lastname')}</InputLabel>
                        <TextField className="create-user-form-input" placeholder="" value={user.lastName} onChange={(event) => handleInputChange(event,'lastname')} inputProps={{ 'aria-label': 'lastname' }} />
                    </div>
                    <div className='align-items-center'>
                        <InputLabel className='required' id="url-key-label">{t('Email')}</InputLabel>
                        <TextField autoComplete='new-email' className="create-user-form-input" placeholder="" value={user.email} onChange={(event) => handleInputChange(event,'email')} inputProps={{ 'aria-label': 'email' }} />
                    </div>
                    {!id && <div className='align-items-center'>
                        <InputLabel className='required' id="url-key-label">{t('Password')}</InputLabel>
                        <TextField autoComplete='new-password' className="create-user-form-input" placeholder="" value={user.password} type='password' onChange={(event) => handleInputChange(event,'password')} inputProps={{ 'aria-label': 'password' }} />
                    </div>}
                    
                    <div className='align-items-center'>
                        <InputLabel id="language-select-label" className="required">{t('Language')}</InputLabel>
                        <Select
                        labelid="language-select-label"
                        id="language-select"
                        className="select-input"
                        value={user.language !== undefined ? user.language : ''}
                        onChange={(event) => handleInputChange(event,'language')}
                        required
                        >
                            <MenuItem value="en">{t('English')}</MenuItem>
                            <MenuItem value="da">{t('Danish')}</MenuItem>
                        </Select>
                    </div>
                    { !id && <div className='align-items-center'>
                        <InputLabel id="role-select-label" className="required">{t('Role')}</InputLabel>
                        <Select
                        labelid="role-select-label"
                        id="role-select"
                        className="select-input"
                        value={user.userType}
                        onChange={(event) => handleInputChange(event,'userType')}
                        required
                        >
                            <MenuItem value='Admin'>{t('Admin')}</MenuItem>
                            <MenuItem value='Instructor'>{t('Instructor')}</MenuItem>
                            <MenuItem value='GetUpp'>{t('GetUpp')}</MenuItem>
                            <MenuItem value='OfficeFit'>{t('OfficeFit')}</MenuItem>
                        </Select>

                    </div>}
                    
                    <div className='align-items-center'>
                        <InputLabel className='required' id="url-key-label">{t('Nickname')}</InputLabel>
                        <TextField className="create-user-form-input" placeholder="" value={user.nickName} onChange={(event) => handleInputChange(event,'nickname')} inputProps={{ 'aria-label': 'nickname' }} />
                    </div>
                    <div className='align-items-center'>
                        <button type="submit" className='create-user-button'>{t('Opret Bruger')}</button>
                    </div>
                </form>
            </div>
        );
    
};

export default AddUser;
