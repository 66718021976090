import React from 'react';
import Footer from '../../components/Footer/Footer';
import Nav from '../../components/Navbar/Nav';
import InstructorRoutes from '../../Routes/InstructorRoutes';

function InstructorScreen() {
        return (
            <div>
                <Nav />
                <InstructorRoutes />
                <Footer />
            </div>
        );
    
};

export default InstructorScreen;
