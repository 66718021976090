import { configureStore } from '@reduxjs/toolkit'
import authReducer from "../slices/auth";
import messageReducer from "../slices/message";
import allVideosReducer from "../slices/all-videos";
import allSessionsReducer from '../slices/all-sessions';
import favoriteVideosReducer from "../slices/favorites";
import favoriteCarrouselVideosReducer from "../slices/favorites-carrousel";
import lastPlayedVideosReducer from "../slices/last-played";
import mostPopularVideosReducer from "../slices/most-popular";
import instructorSearchVideosReducer from "../slices/instructor-search";
import typeSearchVideosReducer from "../slices/type-search";
import userDataReducer from '../slices/userData';
import dashboardReducer from "../slices/dashboardData"
import videoLiveFilterReducer from "../slices/videoLiveFilter"
import fetchLiveEventsReducer from '../slices/fetchLiveEvents';
import latestVideosReducer from "../slices/latestVideos"
import selectedVideoReducer from "../slices/selectedVideoUrl"
import uploadDataReducer from "../slices/uploadData";
import dashboardCarrouselReducer from "../slices/dashboard-carrousel";
import allTagsReducer from "../slices/tags";
import uploadVideoReducer from "../slices/uploadVideo";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  allVideos: allVideosReducer,
  allSessions: allSessionsReducer,
  favoriteVideos: favoriteVideosReducer,
  lastPlayedVideos : lastPlayedVideosReducer,
  mostPopularVideos: mostPopularVideosReducer,
  instructorSearchVideos: instructorSearchVideosReducer,
  typeSearchVideos: typeSearchVideosReducer,
  favoriteCarrouselVideos: favoriteCarrouselVideosReducer,
  userData:userDataReducer,
  uploadData:uploadDataReducer,
  dashboardData: dashboardReducer,
  videoLiveFilter: videoLiveFilterReducer,
  fetchLiveEvent: fetchLiveEventsReducer,
  latestVideos: latestVideosReducer,
  selectedVideoUrl: selectedVideoReducer,
  dashboardCarrouselVideos: dashboardCarrouselReducer,
  allTags: allTagsReducer,
  uploadVideo: uploadVideoReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export default store;
