import React, { useState } from "react";

import { getAllTags } from "../../slices/tags";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { set } from "date-fns";
import userService from "../../services/user.service";
import { setMessage } from "../../slices/message";

function Tags() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state.allTags);
  const [open, setOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [response, setResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    dispatch(getAllTags());
  }, []);

  const handleConfirmationDelete = (data) => {
    setSelectedTag(data);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      var response = await userService.deleteTag(selectedTag);
      dispatch(getAllTags());
      setResponse(response.data);
      setOpenSnackbar(true);
    } catch (error) {
      dispatch(setMessage(error.response));
      console.log(error);
      setOpenSnackbar(true);
    }
    setOpen(false);
  };

  const handleSnakbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ maxWidth: `64rem`, margin: `0 auto` }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnakbarClose}
      >
        <Alert
          onClose={handleSnakbarClose}
          severity={
            response && response.status === "SUCCESS" ? "success" : "error"
          }
          sx={{ width: "100%" }}
        >
          {t(
            response.status === "SUCCESS"
              ? response.successMessage
              : response.errors
              ? response.errors[0]
              : response.data && response.data.errors
              ? response.data.errors[0]
              : "Something went wrong, Please try again."
          )}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
            }}
          >
            <h1>{t("Tags")}</h1>
            <Link to="/add-tag">
              <AddIcon />
            </Link>
          </Box>
          <Box sx={{ marginBottom: `1rem` }}>
            <TableContainer component={Paper} size="small">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Tags")}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tags &&
                    tags.map((tag) => (
                      <TableRow key={tag.id}>
                        <TableCell component="th" scope="row">
                          {tag.name}
                        </TableCell>
                        <TableCell align="right">
                          <Link to={`/tags/${tag.id}`}>
                            <Button>
                              <EditIcon />
                            </Button>
                          </Link>
                          <Button onClick={() => handleConfirmationDelete(tag)}>
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={open} maxWidth="xs">
        <DialogTitle>{t("Delete tag")}</DialogTitle>
        <DialogContent>
          <p>{t("Are you sure you want to delete this tag?")}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleDelete} color="primary">
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Tags;
