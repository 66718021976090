import React, { useEffect, useState } from 'react';
import { Carrousel } from '../Carrousel/Carrousel';
import InstructorIcon from '../../img/quick-search-icons/Instructor.png';
import HoldIcon from '../../img/quick-search-icons/Hold.png';
import ProgressIcon from '../../img/quick-search-icons/Progress.png';
import ErgonomicsIcon from '../../img/quick-search-icons/Ergonomics.png';
import { useTranslation } from 'react-i18next';
import './Quick-search.css';
import { Alert, Divider, Snackbar } from '@mui/material';
import userService from '../../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { getInstructorSearchVideos, setInstructorVideos } from '../../slices/instructor-search';
import { getTypeSearchVideos, setTypeVideos } from '../../slices/type-search';

function QuickSearch(props) {
    const paramType = props.match.params.type;
    const [openMessage, setOpenMessage] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({status:'',successMessage:'',errors:[]});
    const {t} = useTranslation();
    const { videos } = useSelector((state) => {
        let newState = state;
            switch (paramType) {
            case 'Instructor':
                newState = state.instructorSearchVideos;
                break;
            case 'Team':
                newState = state.typeSearchVideos;
                break;
            default:
                break;
        }
        return newState;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        userService.getQuickSearchVideos(paramType === 'Instructor' ? 'instructor=true' : (paramType === 'Team' ? 'type=true' : (paramType === 'Progress' ? 'level=true' : '')))
        .then((response) => {
            switch (paramType) {
                case 'Instructor':
                    dispatch(setInstructorVideos(response.data.details));
                    break;
                case 'Team':
                    dispatch(setTypeVideos(response.data.details));
                    break;
                case 'Progress':
                    break;
                default:
                    break;
            }
        }).catch((error) => {
            console.log(error);
        })
        // eslint-disable-next-line
    },[]);

    const addVideoToFavorites = (data,listItems) => {
        userService.addVideoToFavorites(data)
          .then((response) => {
            setUploadResponse(response.data);
            setOpenMessage(true);
            listItems.forEach(element => {
                if(element.videoId === data.videoId){element.favourite = true} 
            });
          })
          .catch((error) => {
            setUploadResponse(error.response.data)
            setOpenMessage(true);
          });
    };
    const removeVideoFromFavorites = (data,listItems,isFavorite) => {
        userService.removeVideoFromFavorites(data)
          .then((response) => {
            setUploadResponse(response.data);
            setOpenMessage(true);
            listItems.forEach((element, index) => {
                if(element.videoId === data.videoId){isFavorite ? listItems.splice(index,1) : element.favourite = false} 
            });
          })
          .catch((error) => {
            setUploadResponse(error.response.data)
            setOpenMessage(true);
          });
    };

    return (
        <div className="quick-search-page">
            <Snackbar open={openMessage} autoHideDuration={6000} onClose={() => {setOpenMessage(false)}}>
                <Alert onClose={() => {setOpenMessage(false)}} severity={uploadResponse && uploadResponse.status === 'SUCCESS' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {t(uploadResponse.status === 'SUCCESS' ? uploadResponse.successMessage : uploadResponse.errors[0])}
                </Alert>
            </Snackbar>

            <h3 className='page-title'>
                <img alt="" src={paramType === 'Instructor' ? InstructorIcon : (paramType === 'Team' ? HoldIcon : (paramType === 'Progress' ? ProgressIcon : (paramType === 'Ergonomics' ? ErgonomicsIcon : '')))}/>
                {t(paramType)}
            </h3>
            <Divider />
            {   videos && 
                Object.keys(videos).map((key,index) => {
                    return ( 
                        <div key={index}>
                            <h3>{key}</h3>
                            <Carrousel 
                                addVideoToFavorites={addVideoToFavorites} 
                                removeVideoFromFavorites={removeVideoFromFavorites} 
                                data={videos[key]} 
                                pageValue={1} 
                                type={paramType} 
                                getVideos={paramType === 'Instructor' ? getInstructorSearchVideos : (paramType === 'Team' ? getTypeSearchVideos : null)} 
                                userId={paramType === 'Instructor' ? videos[key][0].userId : null} 
                                typeId={paramType === 'Team' ? videos[key][0].videoTypeId : null} 
                                levelId={paramType === 'Progress' ? videos[key][0].videoLevelId : null}
                            />
                        </div>
                    );
                })
            }
        </div>
    )
}

export default QuickSearch;
