import React from 'react';
import "./Banner.css";

// Pictures
import background from "../../img/homepage-banner-2.jpg";
import { Link } from 'react-router-dom';
import { t } from 'i18next';

function Banner() {

    return (
        <div className="banner">
            <div className='overlay'></div>
            <img src={background} alt="" className="banner-background" style={{height:'100%',width:'100%'}}/>
            <div className='banner-text'>
                <h1 className="bannerTitle">IT’S TIME TO GETUPP</h1> 
                <p className="login-text">Vær´med på vores online træningsmoduler, som giver dig nem og bekvem adgang til forskellige former for bevægelse i arbejdstiden.</p>
                <Link to='/login' className="login-link">
                    <button>{t('Login')}</button>
                </Link>
            </div>
        </div>
    );
}

export default Banner;
