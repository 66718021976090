import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../services/auth.service";

export const setMessage = createAsyncThunk(
  "message/setMessage",
  async (queryParams, thunkAPI) => {
    if(queryParams.status===401 && queryParams.statusText==="Unauthorized")
    {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await authService.refreshToken(user.refresh_token);
        
        if (response.data) {
          localStorage.setItem("auth", response.data.access_token);
          localStorage.setItem("user", JSON.stringify(response.data));
          return { response: response.data };
        }
        
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const clearMessage = createAsyncThunk(
  "message/clearMessage",
  () => {
    return { message:"" };
  }
);

const initialState = { token: "" };

const messageSlice = createSlice({
  name: "message",
  initialState,
  extraReducers: {
    [setMessage.fulfilled]: (state, action) => {

      if(action.payload)
        state.token=action.payload.response.refresh_token;
        
    },
    [setMessage.rejected]: (state, action) => {
    }
  }
});

const { reducer } = messageSlice;
export default reducer;