import React, { useEffect, useState } from 'react';
import { Badge, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import userService from '../../services/user.service';
import WorkOutImage from "../../img/Home-img/After Work Cardio/After Work Cardio 3000x1300 .png";
import ActiveBreaksImage from "../../img/Home-img/Morgenbevægelse/Morgenbevægelse 3000x1300.png";
import RespiratoryTrainingImage from "../../img/Home-img/Åndedrætstræning/Åndedrætstræning 4 3000x1300.png";
import YogaImage from "../../img/Home-img/Yoga/Yoga 4 3000x1300 .png";
import { Link } from 'react-router-dom';
import "./SessionPlayer.css";
import { setMessage } from "../../slices/message";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function SessionPlayer(props) {

	const [isFetching, setIsFetching] = useState(false);
    const [allVideos, setAllVideos] = useState([]);
    const id = props.match.params.sessionId;
    const [currentVideo, setCurrentVideo] = useState('');
    const [videoURL, setVideoUrl] = useState('');
    const token  = useSelector((state) => state.message.token);
    const { sessions } = useSelector((state) => state.allSessions);
    const [sessionDetails, setSessionDetails] = useState(() => {
        return sessions.find((element) => element.id === id);
    });

    const dispatch = useDispatch();

    useEffect(() => {
		window.addEventListener('scroll', handleScroll);
        userService.getSessionVideosById(id)
        .then((response) => {
            setAllVideos(response.data.details);
            setCurrentVideo(response.data.details[0].id);
            setVideoUrl(response.data.details[0].iframeUrl);
            setCurrentVideo(response.data.details[0].id);
        })
        .catch((error) => {
            console.log(error);
            dispatch(setMessage(error.response));
        });
        // eslint-disable-next-line
	}, [token]);

    function secondsToHms(duration) {
        const sec = parseInt(duration, 10); 
        let hours   = Math.floor(sec / 3600); 
        let minutes = Math.floor((sec - (hours * 3600)) / 60); 
        let seconds = sec - (hours * 3600) - (minutes * 60); 
        
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }

    const createLiveVideoPlayer = () => {
        return (
            <Grid container spacing={1} direction="row" justifyContent="center">
                <Grid item xs={10} sm={10} md={7} lg={7}>
                    <p className='player-category'>{sessionDetails.sessionTitle}</p>
                    <Item style={{boxShadow:'none', padding:'0px'}}>
                        <div className='player-grid'>
                           <div style={{height:'30em',position:'relative'}}>
                               <iframe src={videoURL} title="sessionPlayer" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',borderRadius:'4px'}}></iframe>
                           </div>
                        </div>
                    </Item>
                    <p className='session-description'>{sessionDetails.notes}</p>
                </Grid>
                <Grid item xs={10} sm={10} md={4} lg={4}>
                    <Item style={{boxShadow:'none', padding:'0px', marginTop:'2.2em'}}>
                        <div className='container'>
                            <div className='video-list'>
                                {allVideos.map((listItem) => {
                                    return (
                                        <Link to={`/session-player/${id}`} style={{ textDecoration: 'none' }}>
                                        <div className={`video ${listItem.id === currentVideo ? 'active' : ''}`} onClick={() => {setCurrentVideo(listItem.id); setVideoUrl(listItem.iframeUrl);}}>
                                            <img alt="" src={
                                                    listItem.thumbnailUrl ? listItem.thumbnailUrl :
                                                    listItem.videoType === "Work Out" ? WorkOutImage : 
                                                    listItem.videoType === "Yoga" ? YogaImage : 
                                                    listItem.videoType === "Active Breaks" ? ActiveBreaksImage : 
                                                    listItem.videoType === "Respiratory Training" ? RespiratoryTrainingImage : ''} controls muted></img>
                                            <div className='video-title'>
                                            <h5 className='title'>{listItem.videoTitle}</h5>
                                            <span className='tags-badge-line'>
                                                {
                                                    listItem.tags && listItem.tags.map((item) => {
                                                        return  item && <Badge className='tag-badge'>{ item.name }</Badge>
                                                    }) 
                                                    
                                                }
                                            </span>
                                            
                                            <span className='subtitle'>{listItem.firstName} {listItem.lastName}</span>
                                            <span className='duration'>{secondsToHms(listItem.duration)}</span>
                                            </div>
                                        </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        );
    }

    const handleScroll = () => {
		if (
			Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight ||
			isFetching
		)
			return;
		setIsFetching(true);
	};

    return(
        <div className='session-player-page'>
           <div className="Player">
            {props.videoType && props.videoType !== 'live' ? '' : createLiveVideoPlayer()}
           </div>
        </div>
    );
}

export default SessionPlayer