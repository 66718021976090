import React,{ useEffect} from 'react';
import './Skema.css';
import { Grid, Typography } from '@mui/material';
import WorkOutImage from "../../img/Home-img/After Work Cardio/After Work Cardio 3000x1300 .png";
import ActiveBreaksImage from "../../img/Home-img/Morgenbevægelse/Morgenbevægelse 3000x1300.png";
import RespiratoryTrainingImage from "../../img/Home-img/Åndedrætstræning/Åndedrætstræning 4 3000x1300.png";
import YogaImage from "../../img/Home-img/Yoga/Yoga 4 3000x1300 .png";
import {useTranslation} from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { getVideoLiveFilter } from '../../slices/videoLiveFilter';
import { fetchLiveEvents } from '../../slices/fetchLiveEvents';
import { getDashboard } from '../../slices/dashboardData';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReactAddToCalendar from '../ReactAddToCalendar/ReactAddToCalendar';
import "../ReactAddToCalendar/styles/ReactAddToCalendar.scss";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Skema(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const token  = useSelector((state) => state.message.token);
    const {liveEvents} = useSelector((state) => state.fetchLiveEvent);
    const currentDateTime = new Date().toISOString();
    const { dashboard } = useSelector((state) => {
      return state.dashboardData;
    });

    useEffect(() => {
      dispatch(getVideoLiveFilter())
      dispatch(getDashboard((new Date()).toISOString()));
      dispatch(fetchLiveEvents(currentDateTime));
      // eslint-disable-next-line
    },[token])

    const getEventDays = (videoLiveEventDays) => {
      let day = ""
      if(videoLiveEventDays)
      {
        videoLiveEventDays.replace(/[&\/\\#:\[\]+()$~%.'"*?<>{}Days]/g, '').split(',')
        .forEach(element => {
          if(element === "1") day += "Monday "
          else if(element === "2") day += "Tuesday "
          else if(element === "3") day += "Wednesday "
          else if(element === "4") day += "Thursday "
          else if(element === "5") day += "Friday "
        })
      }
      return day
    }

    const getDaysArray = videoLiveEventDays => {
      let Days = ['MO', 'TU', 'WE', 'TH', 'FR'];
      let days = [];
      videoLiveEventDays.replace(/[&\/\\#:\[\]+()$~%.'"*?<>{}Days]/g, '').split(',')
      .map(day => days.push(Days[day-1]));
      console.log(days);
      return days;
    }
        
    return (
        <div className='skema-page'>
          {
            dashboard.Videos && dashboard.Videos.map(item => {
              return ( item.isLiveEvent && 
                <div className='player-grid'>
                  <div style={{padding:'56.25% 0 0 0',position:'relative'}}>
                      <iframe src={`${item.iframeUrl}/embed`} title="livePlayer" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',borderRadius:'4px'}}></iframe>
                  </div>
              </div>
              )
            })
          }
            <Typography variant="h5" component="div" sx={{fontWeight:'bold', margin:'10px 0'}}>
              AKTIVITETER
            </Typography>
            {liveEvents &&
              liveEvents.map((item) => {
              return (
              <Box sx={{ flexGrow: 1 }} mb={5}>
                
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Item style={{padding:'0px'}}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Item className="calendar-date" sx={{boxShadow:'none', display:'flex', justifyContent:'flex-start'}} mt={2}>
                              <Typography variant="h6" component="div" sx={{display:'flex', fontWeight:'bold', alignItems:'flex-start'}} mt={0.5}>
                                <CalendarMonthOutlinedIcon sx={{fontSize:'30px'}}/> <span>{item.videoLiveEventDateTimeISO.substring(0,10)}</span>
                              </Typography>
                              <Typography variant="h6" component="div" sx={{display:'flex', fontWeight:'bold', alignItems:'flex-start', fontSize:'16px'}} mt={1}>
                              { getEventDays(item.videoLiveEventDays)}
                              </Typography>
                            </Item>
                          </Grid>

                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Item className="calendar-title" style={{boxShadow:'none'}}>
                              <Typography variant="subtitle2" component="div" sx={{display:'flex', fontWeight:'bold', alignItems:'flex-start'}} mt={1.5}>
                                <FiberManualRecordIcon sx={{fontSize:'18px', marginRight:'5px', color:'red'}}/> <span>{t(item.videoType)}</span>
                              </Typography>
                              <Typography variant="h5" component="div" sx={{color:'black', textAlign:'left', marginRight:'25px'}}>
                                {item.videoLiveEventTitle}
                              </Typography>
                            </Item>
                          </Grid>

                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Item className="calendar-description" style={{boxShadow:'none'}}>
                              <Typography variant="subtitle2" component="div" mb={4}>
                                {t(item.videoSubType)}
                              </Typography>
                              {/* <ReactAddToCalendar event={{
                                title: 'Sample Event',
                                description: 'This is the sample event provided as an example only',
                                location: 'Portland, OR',
                                startTime: '2023-09-16T20:15:00-04:00',
                                endTime: '2023-09-16T21:45:00-04:00'
                              }}/> */}
                              <ReactAddToCalendar event={{
                                styleClass: "react-add-to-calendar",
                                btnTitle: "Add to My Calendar",
                                title: item.videoLiveEventTitle,
                                description: item.videoLiveEventNotes,
                                location: 'Denmark',
                                startTime: item.videoLiveEventDateTimeISO,
                                endTime: item.videoLiveEventDateTimeISO,
                                ...(item.isRecurringEvent && {
                                    recurring: {
                                      repeat: 'weekly',
                                      byDay: getDaysArray(item.videoLiveEventDays)
                                    }
                                  })
                              }}/>
                              {/* <Button variant="contained" sx={{backgroundColor:'grey'}} size="small">Outlined</Button> */}
                            </Item>
                          </Grid>

                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:'0px', margin:'0px'}}>
                            <Item style={{boxShadow:'none', padding:'0px', margin:'0px'}}>
                              <img className='calendar-image' alt='thumbnail' src={item.videoType === "Yoga" ? YogaImage :
                              item.videoType === "Active Breaks" ? ActiveBreaksImage :
                              item.videoType === "Respiratory Training" ? RespiratoryTrainingImage :
                              item.videoType === "Work Out" ? WorkOutImage : ""} style={{width:'100%', height:'auto'}}/>
                            </Item>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                </Grid>
              </Box>
              )}
              )}
        
        </div>
    )
}

export default Skema;