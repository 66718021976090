import React, { useEffect } from 'react';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/Footer/Footer';
import NavForm from '../../components/Navbar/NavForm';

function HomeScreen() {
    useEffect(() => {
        document.title = `GetUpp Live`;
      });

    return (
        <div>
            <NavForm />
            <Banner />
            <Footer />
        </div>
    );
}

export default HomeScreen;
