import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
} from "@mui/material";
import {
  PlayArrow,
  ArrowBackIosNew,
  ArrowForwardIos,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { Carrousel } from "../Carrousel/Carrousel";
import userService from "../../services/user.service";
import { useDispatch, useSelector } from "react-redux";
// import { getLastPlayedVideos, setLastPlayedVideosPage } from '../../slices/last-played';
import Carousel from "react-material-ui-carousel";
import WorkOutImage from "../../img/Home-img/After Work Cardio/After Work Cardio 3000x1300 .png";
import ActiveBreaksImage from "../../img/Home-img/Morgenbevægelse/Morgenbevægelse 3000x1300.png";
import RespiratoryTrainingImage from "../../img/Home-img/Åndedrætstræning/Åndedrætstræning 4 3000x1300.png";
import YogaImage from "../../img/Home-img/Yoga/Yoga 4 3000x1300 .png";
import DefaultBanner from "../../img/Banner.jpg";
import ReplayVideo from "../Player/ReplayVideo";
import { getDashboard } from "../../slices/dashboardData";
import { videoUrl } from "../../slices/selectedVideoUrl";
import Spinner from "../ProgressBar/Spinner";
import {
  getFavoriteVideos,
  getLatestVideos,
  getActiveBreaksVideos,
  getLastPlayedVideos,
  getRespiratoryTrainingVideos,
  getTrainingVideos,
  getWorkOutVideos,
  getYogaVideos,
  setPage,
  getPowerstandVideos,
  refreshLikesDislikes,
  setFavoritesPage,
  getStretchingBreaksVideos,
  getPitStopVideos,
  getMindboostVideos,
  getErgonomicsVideos,
  getPulseAndPowerVideos,
  getAfterWorkPulsAndPowerVideos,
} from "../../slices/dashboard-carrousel";
import { getTeams } from "../../slices/uploadData";
import { setPage as setPageValue } from "../../slices/all-videos";
import {
  removeFromFavorites as removeFromFavorite,
  setPage as setFavoritepage,
  getFavoriteVideos as getFavoriteVideo,
} from "../../slices/favorites";

import RelaxationBreakEN from "../../img/Home-img/Afspændings pause EN.png";
import RelaxationBreakDA from "../../img/Home-img/Afspændings pause DA.png";
import ActiveBreakEN from "../../img/Home-img/Aktiv pause EN.png";
import ActiveBreakDA from "../../img/Home-img/Aktiv pause DA.png";
import ErgonomicsEN from "../../img/Home-img/Ergonomi EN.png";
import ErgonomicsDA from "../../img/Home-img/Ergonomi DA.png";
import MindboostEN from "../../img/Home-img/Mindboost EN.png";
import MindboostDA from "../../img/Home-img/Mindboost DA.png";
import PitStopEN from "../../img/Home-img/Pit stop EN.png";
import PitStopDA from "../../img/Home-img/Pit stop DA.png";
import PulsePowerEN from "../../img/Home-img/Puls & Power EN.png";
import PulsePowerDA from "../../img/Home-img/Puls & Power DA.png";
import YogaEN from "../../img/Home-img/Yoga EN.png";
import YogaDA from "../../img/Home-img/Yoga DA.png";

function Item(props) {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = i18n.language;
  console.log("currentLanguage", currentLanguage);
  const RelaxationBreak =
    currentLanguage === "en" ? RelaxationBreakEN : RelaxationBreakDA;
  const ActiveBreak = currentLanguage === "en" ? ActiveBreakEN : ActiveBreakDA;
  const Ergonomics = currentLanguage === "en" ? ErgonomicsEN : ErgonomicsDA;
  const Mindboost = currentLanguage === "en" ? MindboostEN : MindboostDA;
  const PitStop = currentLanguage === "en" ? PitStopEN : PitStopDA;
  const PulsePower = currentLanguage === "en" ? PulsePowerEN : PulsePowerDA;
  const Yoga = currentLanguage === "en" ? YogaEN : YogaDA;

  return (
    <>
      <Card style={{ position: "relative" }}>
        <div>
          <CardMedia
            component="img"
            image={
              props.item.videoType === "Work Out" &&
              props.item.videoSubType &&
              props.item.videoSubType === "Puls & Power"
                ? ActiveBreak
                : props.item.videoType === "Work Out"
                ? WorkOutImage
                : props.item.videoType === "Yoga"
                ? Yoga
                : props.item.videoType === "Active Breaks"
                ? ActiveBreak
                : props.item.videoType === "Respiratory Training"
                ? RespiratoryTrainingImage
                : props.item.videoType === "Stretching break"
                ? RelaxationBreak
                : props.item.videoType === "Ergonomics"
                ? Ergonomics
                : props.item.videoType === "Mindboost"
                ? Mindboost
                : props.item.videoType === "Pit stop"
                ? PitStop
                : props.item.videoType === "Pulse and power"
                ? PulsePower
                : DefaultBanner
            }
            alt={props.item.videoType}
            className="carousel-img"
          />
        </div>

        <Typography
          style={{ position: "absolute", bottom: "10%", marginLeft: "20px" }}
        >
          <Link
            to={`${
              props.isLiveSession
                ? "/player/live"
                : `/all-videos/${props.item.videoTypeId}`
            }`}
            style={{ textDecoration: "none" }}
          >
            <IconButton
              size="medium"
              variant="contained"
              className="carousel-btn"
              onClick={() => {
                dispatch(videoUrl(props.item.iframeUrl));
              }}
            >
              <PlayArrow fontSize="medium" />
              {t(
                `${
                  props.isLiveSession ? "Go to Live Sessions" : "Go to videos"
                }`
              )}
            </IconButton>
          </Link>
        </Typography>
      </Card>
      <Dialog
        fullScreen
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <ReplayVideo
          onClose={() => {
            setOpen(false);
          }}
        />
      </Dialog>
    </>
  );
}

function Dashboard() {
  const [openMessage, setOpenMessage] = useState(false);
  const { dashboard, isFetching } = useSelector((state) => state.dashboardData);
  const [uploadResponse, setUploadResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const favPage = useSelector(
    (state) => state.dashboardCarrouselVideos.favorite.page
  );
  const lastPage = useSelector((state) => state.favoriteVideos.page);
  const token = useSelector((state) => state.message.token);
  const teams = useSelector((state) => state.uploadData.teams);
  const sortOrder = [
    "Active Breaks",
    "Yoga",
    "Respiratory Training",
    "Work Out",
  ];
  const [isLikedDisliked, setIsLikedDisliked] = useState(false);

  useEffect(async () => {
    await dispatch(getDashboard(new Date().toISOString()));
    await dispatch(setPageValue(1));
    // setDashboardData(dashboard.Videos.push(dashboard.LiveEvents));
  }, [token]);

  const categorySort = [
    { category: "Stretching break", order: 1 },
    { category: "Active Breaks", order: 2 },
    { category: "Yoga", order: 3 },
    { category: "Pulse and power", order: 4 },
    { category: "Mindboost", order: 5 },
    { category: "Ergonomics", order: 6 },
    { category: "Pit stop", order: 7 },
  ];

  const createVideoBlock = () => {
    return (
      <div className="video-tile-block">
        <Carousel
          indicators={false}
          swipe
          cycleNavigation={true}
          navButtonsAlwaysVisible={true}
          PrevIcon={<ArrowBackIosNew fontSize="large" />}
          NextIcon={<ArrowForwardIos fontSize="large" />}
          navButtonsProps={{ className: "carousel-nxt-pre-btn" }}
        >
          {dashboard.Videos &&
            dashboard.Videos.filter((element) => {
              return element.videoCount > 0;
            })
              .filter((element) => {
                //filter element should exist in categorySort array
                return categorySort.find(
                  (item) => item.category === element.videoType
                );
              })
              .sort((a, b) => {
                //sort by order in categorySort array
                return (
                  categorySort.find((item) => item.category === a.videoType)
                    .order -
                  categorySort.find((item) => item.category === b.videoType)
                    .order
                );
              })
              .map((item, index) => (
                <Item
                  key={index}
                  item={item}
                  isLiveSession={item.videoLiveEventTitle}
                />
              ))}
        </Carousel>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getTeams());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLikedDisliked) {
      dispatch(getFavoriteVideos({ page: favPage, favourites: true }));
      setIsLikedDisliked(false);
    }
  }, [favPage, isLikedDisliked]);

  const setPageData = async () => {
    await dispatch(setFavoritesPage(1));
  };

  const fetchFavoriteData = async () => {
    let data = {
      favourites: true,
      page: 1,
    };

    setTimeout(async () => {
      dispatch(setFavoritepage(1));
      dispatch(getFavoriteVideo(data));
      dispatch(setFavoritepage(1));
    }, 100);
  };

  const addVideoToFavorites = async (data, listItems) => {
    console.log("Sending Data: ", data);
    userService
      .addVideoToFavorites(data)
      .then(async (response) => {
        setUploadResponse(response.data);
        setOpenMessage(true);

        // listItems.forEach(element => {
        //     if (element.videoId === data.videoId) { element.favourite = true }
        // });

        if (response.data.status === "SUCCESS") {
          dispatch(setPage(1));
          setPageData().then((res) => {
            setIsLikedDisliked(true);
          });
          dispatch(getLatestVideos());
          // dispatch(getLastPlayedVideos(`page=${lastPage}`))
          dispatch(refreshLikesDislikes(data));
          fetchFavoriteData();
        }
      })
      .catch((error) => {
        console.log(error);
        setUploadResponse(error.response.data);
        setOpenMessage(true);
      });
  };
  const removeVideoFromFavorites = (data, listItems, isFavorite) => {
    userService
      .removeVideoFromFavorites(data)
      .then((response) => {
        setUploadResponse(response.data);
        setOpenMessage(true);

        // listItems.forEach((element, index) => {
        //     if (element.videoId === data.videoId) { isFavorite ? listItems.splice(index, 1) : element.favourite = false }
        // });

        if (response.data.status === "SUCCESS") {
          dispatch(setPage(1));
          setPageData().then((res) => {
            setIsLikedDisliked(true);
          });
          dispatch(getLatestVideos());
          // dispatch(getLastPlayedVideos(`page=${lastPage}`))
          dispatch(refreshLikesDislikes(data));
          dispatch(
            removeFromFavorite(
              data.sessionVideoId
                ? { vid: data.sessionVideoId }
                : { vid: data.videoId }
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setUploadResponse(error.response.data);
        setOpenMessage(true);
      });
  };

  const getCategoryCarrouselData = () => {
    var ordering = {},
      newTeams = teams; // map for efficient lookup of sortIndex
    for (var i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }
    newTeams = newTeams.slice().sort(function (a, b) {
      return ordering[a.type] - ordering[b.type];
    });
    return newTeams;
  };

  return (
    <div className="dashboard-page">
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={() => {
          setOpenMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(false);
          }}
          severity={
            uploadResponse && uploadResponse.status === "SUCCESS"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {t(
            uploadResponse.status === "SUCCESS"
              ? uploadResponse.successMessage
              : uploadResponse.errors
              ? uploadResponse.errors[0]
              : uploadResponse.data && uploadResponse.data.errors
              ? uploadResponse.data.errors[0]
              : "Something went wrong, Please try again."
          )}
        </Alert>
      </Snackbar>

      {isFetching ? <Spinner /> : null}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {createVideoBlock()}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: "1em" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p className="font-weight-bold scroll-menu-title carrousel-headings">
            {t("Top Videos")}
          </p>
          <Carrousel
            type="latest-videos"
            addVideoToFavorites={addVideoToFavorites}
            removeVideoFromFavorites={removeVideoFromFavorites}
            getVideos={getLatestVideos}
            setPage={setPage}
          />
          <Divider style={{ borderBottomWidth: "medium" }} />
          {teams &&
            teams.length > 0 &&
            getCategoryCarrouselData()
              .filter((element) => {
                return (
                  element.videoCount > 0 && element.type === "Active Breaks"
                );
              })
              .map((element) => {
                return (
                  <>
                    <p className="font-weight-bold scroll-menu-title carrousel-headings">
                      {t(element.type)}
                    </p>
                    <Carrousel
                      type={element.type}
                      typeId={element.id}
                      addVideoToFavorites={addVideoToFavorites}
                      removeVideoFromFavorites={removeVideoFromFavorites}
                      getVideos={
                        element.type === "Active Breaks"
                          ? getActiveBreaksVideos
                          : element.type === "Yoga"
                          ? getYogaVideos
                          : element.type === "Respiratory Training"
                          ? getRespiratoryTrainingVideos
                          : element.type === "Work Out"
                          ? getWorkOutVideos
                          : element.type === "Powerstand"
                          ? getPowerstandVideos
                          : element.type === "Stretching break"
                          ? getStretchingBreaksVideos
                          : element.type === "Training"
                          ? getTrainingVideos
                          : element.type === "Pit stop"
                          ? getPitStopVideos
                          : element.type === "Mindboost"
                          ? getMindboostVideos
                          : element.type === "Ergonomics"
                          ? getErgonomicsVideos
                          : element.type === "Pulse and power"
                          ? getPulseAndPowerVideos
                          : element.type === "After Work - Puls og styrke"
                          ? getAfterWorkPulsAndPowerVideos
                          : ""
                      }
                      setPage={setPage}
                    />
                    <Divider style={{ borderBottomWidth: "medium" }} />
                  </>
                );
              })}
          <p className="font-weight-bold scroll-menu-title carrousel-headings">
            {t("Last Played")}
          </p>
          <Carrousel
            type="last-played"
            addVideoToFavorites={addVideoToFavorites}
            removeVideoFromFavorites={removeVideoFromFavorites}
            getVideos={getLastPlayedVideos}
            setPage={setPage}
          />
          <Divider style={{ borderBottomWidth: "medium" }} />
          <p className="font-weight-bold scroll-menu-title carrousel-headings">
            {t("Favorites")}
          </p>
          <Carrousel
            type="favorites"
            addVideoToFavorites={addVideoToFavorites}
            removeVideoFromFavorites={removeVideoFromFavorites}
            getVideos={getFavoriteVideos}
            setPage={setPage}
          />
          {teams &&
            teams.length > 0 &&
            getCategoryCarrouselData()
              .filter((element) => {
                return (
                  element.videoCount > 0 && element.type !== "Active Breaks"
                );
              })
              .map((element) => {
                return (
                  <>
                    <p className="font-weight-bold scroll-menu-title carrousel-headings">
                      {t(element.type)}
                    </p>
                    <Carrousel
                      type={element.type}
                      typeId={element.id}
                      addVideoToFavorites={addVideoToFavorites}
                      removeVideoFromFavorites={removeVideoFromFavorites}
                      getVideos={
                        element.type === "Active Breaks"
                          ? getActiveBreaksVideos
                          : element.type === "Yoga"
                          ? getYogaVideos
                          : element.type === "Respiratory Training"
                          ? getRespiratoryTrainingVideos
                          : element.type === "Work Out"
                          ? getWorkOutVideos
                          : element.type === "Powerstand"
                          ? getPowerstandVideos
                          : element.type === "Stretching break"
                          ? getStretchingBreaksVideos
                          : element.type === "Training"
                          ? getTrainingVideos
                          : element.type === "Pit stop"
                          ? getPitStopVideos
                          : element.type === "Mindboost"
                          ? getMindboostVideos
                          : element.type === "Ergonomics"
                          ? getErgonomicsVideos
                          : element.type === "Pulse and power"
                          ? getPulseAndPowerVideos
                          : element.type === "After Work - Puls og styrke"
                          ? getAfterWorkPulsAndPowerVideos
                          : ""
                      }
                      setPage={setPage}
                    />
                    <Divider style={{ borderBottomWidth: "medium" }} />
                  </>
                );
              })}
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
