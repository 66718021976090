import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import React, { useContext, useState, useEffect } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function Arrow({
    children,
    disabled,
    onClick,
    alignment
  }) {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        style={{
          opacity: disabled ? "0.5" : "1",
        }}
        className={`${alignment === 'left' ? 'arrow-left' : 'arrow-right'} arrow-buttons`}
      >
        {children}
      </button>
    );
  }

 export function LeftArrow() {
      const {
        isFirstItemVisible,
        scrollPrev
      } = useContext(VisibilityContext);

    return (
      <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()} alignment="left">
        <ChevronLeft/>
      </Arrow>
    );
}

export function RightArrow({changeStateValue}) {
  const {isLastItemVisible,scrollNext} = useContext(VisibilityContext);

    useEffect(() => {
        if (isLastItemVisible) {
            changeStateValue();
        }
        }, [isLastItemVisible]);

    return (
        <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()} alignment="right">
            <ChevronRight/>
        </Arrow>
    );
}
