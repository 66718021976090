import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import userService from "../services/user.service";

export const getFavoriteVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getFavoriteVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getFavoriteVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getWorkOutVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getWorkOutVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getTrainingVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getTrainingVideos",
  async (queryParams, thunkAPI) => {
    try {
      console.log("Training Params: ", queryParams);
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getActiveBreaksVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getActiveBreaksVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getStretchingBreaksVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getStretchingBreaksVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPitStopVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getPitStopVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getMindboostVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getMindboostVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getErgonomicsVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getErgonomicsVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPulseAndPowerVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getPulseAndPowerVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getRespiratoryTrainingVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getRespiratoryTrainingVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getYogaVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getYogaVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPowerstandVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getPowerstandVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAfterWorkPulsAndPowerVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getAfterWorkPulsAndPowerVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getAllVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getLastPlayedVideos = createAsyncThunk(
  "dashboardCarrouselVideos/getLastPlayedVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getLastPlayedVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getLatestVideos = createAsyncThunk(
  "dashboardCarrouselVideos/latestVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getLatestVideos();
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (err) {
      thunkAPI.dispatch(setMessage(err.response));
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setPage = createAsyncThunk(
  "dashboardCarrouselVideos/setPage",
  async (data, thunkAPI) => {
    return { page: data.page, type: data.type };
  }
);

export const refreshLikesDislikes = createAsyncThunk(
  "dashboardCarrouselVideos/refreshLikesDislikes",
  async (data, thunkAPI) => {
    return data;
  }
);

export const setFavoritesPage = createAsyncThunk(
  "dashboardCarrouselVideos/setFavoritesPage",
  async (data, thunkAPI) => {
    return data;
  }
);

const initialState = {
  favorite: { videos: [], page: 1 },
  training: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  activeBreaks: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  workOut: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  respiratoryTraining: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  yoga: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  powerstand: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  latestVideos: { videos: [], page: 1 },
  lastPlayedVideos: { videos: [], page: 1 },
  isFetching: false,
  isFetched: false,
  stretchingBreaks: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  pitStop: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  mindboost: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  ergonomics: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  pulseAndPower: { videos: [], page: 1, videoType: "", videoTypeId: "" },
  afterWorkPulsAndPower: {
    videos: [],
    page: 1,
    videoType: "",
    videoTypeId: "",
  },
};

const dashboardCarrouselVideosSlice = createSlice({
  name: "dashboardCarrouselVideos",
  initialState,
  extraReducers: {
    [getLastPlayedVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      state.lastPlayedVideos.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.lastPlayedVideos.videos, ...action.payload];
      state.lastPlayedVideos.page = state.lastPlayedVideos.page + 1;
    },
    [getLastPlayedVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [setFavoritesPage.fulfilled]: (state, action) => {
      state.favorite.page = action.payload;
    },
    [setFavoritesPage.rejected]: (state, action) => {},
    [getFavoriteVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      state.favorite.videos =
        state.favorite.page === 1
          ? [...action.payload]
          : [...state.favorite.videos, ...action.payload];

      if (action.payload.length > 0) {
        state.favorite.page = state.favorite.page + 1;
      }
    },
    [getFavoriteVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getActiveBreaksVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.activeBreaks.videoType = action.payload[0].videoType;
        state.activeBreaks.videoTypeId = action.payload[0].videoTypeId;
      }
      state.activeBreaks.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.activeBreaks.videos, ...action.payload];
      state.activeBreaks.page = state.activeBreaks.page + 1;
    },
    [getActiveBreaksVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getStretchingBreaksVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.stretchingBreaks.videoType = action.payload[0].videoType;
        state.stretchingBreaks.videoTypeId = action.payload[0].videoTypeId;
      }
      state.stretchingBreaks.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.stretchingBreaks.videos, ...action.payload];
      state.stretchingBreaks.page = state.stretchingBreaks.page + 1;
    },
    [getStretchingBreaksVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getRespiratoryTrainingVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.respiratoryTraining.videoType = action.payload[0].videoType;
        state.respiratoryTraining.videoTypeId = action.payload[0].videoTypeId;
      }
      state.respiratoryTraining.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.respiratoryTraining.videos, ...action.payload];
      state.respiratoryTraining.page = state.respiratoryTraining.page + 1;
    },
    [getRespiratoryTrainingVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getTrainingVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      state.training.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.training.videos, ...action.payload];
      state.training.page = state.training.page + 1;
    },
    [getTrainingVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getPitStopVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.pitStop.videoType = action.payload[0].videoType;
        state.pitStop.videoTypeId = action.payload[0].videoTypeId;
      }
      state.pitStop.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.pitStop.videos, ...action.payload];
      state.pitStop.page = state.pitStop.page + 1;
    },
    [getPitStopVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getMindboostVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.mindboost.videoType = action.payload[0].videoType;
        state.mindboost.videoTypeId = action.payload[0].videoTypeId;
      }
      state.mindboost.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.mindboost.videos, ...action.payload];
      state.mindboost.page = state.mindboost.page + 1;
    },
    [getMindboostVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getErgonomicsVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.ergonomics.videoType = action.payload[0].videoType;
        state.ergonomics.videoTypeId = action.payload[0].videoTypeId;
      }
      state.ergonomics.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.ergonomics.videos, ...action.payload];
      state.ergonomics.page = state.ergonomics.page + 1;
    },
    [getErgonomicsVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getPulseAndPowerVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.pulseAndPower.videoType = action.payload[0].videoType;
        state.pulseAndPower.videoTypeId = action.payload[0].videoTypeId;
      }
      state.pulseAndPower.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.pulseAndPower.videos, ...action.payload];
      state.pulseAndPower.page = state.pulseAndPower.page + 1;
    },
    [getPulseAndPowerVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getAfterWorkPulsAndPowerVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.afterWorkPulsAndPower.videoType = action.payload[0].videoType;
        state.afterWorkPulsAndPower.videoTypeId = action.payload[0].videoTypeId;
      }
      state.afterWorkPulsAndPower.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.afterWorkPulsAndPower.videos, ...action.payload];
      state.afterWorkPulsAndPower.page = state.afterWorkPulsAndPower.page + 1;
    },
    [getAfterWorkPulsAndPowerVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getLatestVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      state.latestVideos.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.latestVideos.videos, ...action.payload];
      state.latestVideos.page = state.latestVideos.page + 1;
    },
    [getLatestVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getWorkOutVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      state.workOut.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.workOut.videos, ...action.payload];
      state.workOut.page = state.workOut.page + 1;
    },
    [getWorkOutVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getYogaVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload.length) {
        state.yoga.videoType = action.payload[0].videoType;
        state.yoga.videoTypeId = action.payload[0].videoTypeId;
      }
      state.yoga.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.yoga.videos, ...action.payload];
      state.yoga.page = state.yoga.page + 1;
    },
    [getYogaVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [getPowerstandVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      if (action.payload) {
        state.powerstand.videoType = action.payload[0].videoType;
        state.powerstand.videoTypeId = action.payload[0].videoTypeId;
      }
      state.powerstand.videos =
        state.page === 1
          ? [...action.payload]
          : [...state.powerstand.videos, ...action.payload];
      state.powerstand.page = state.powerstand.page + 1;
    },
    [getPowerstandVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    },
    [refreshLikesDislikes.fulfilled]: (state, action) => {
      switch (action.payload.videoType) {
        case "Powerstand":
          state.powerstand.videos = state.powerstand.videos.map((video) => {
            if (
              video.id == action.payload.videoId ||
              video.id == action.payload.sessionVideoId
            )
              video.isFavourite = action.payload.isFavourite;
            return video;
          });
          break;
        case "Active Breaks":
          state.activeBreaks.videos = state.activeBreaks.videos.map((video) => {
            if (
              video.id == action.payload.videoId ||
              video.id == action.payload.sessionVideoId
            )
              video.isFavourite = action.payload.isFavourite;
            return video;
          });
          break;
        case "Yoga":
          state.yoga.videos = state.yoga.videos.map((video) => {
            if (
              video.id == action.payload.videoId ||
              video.id == action.payload.sessionVideoId
            )
              video.isFavourite = action.payload.isFavourite;
            return video;
          });
          break;
        case "Respiratory Training":
          state.respiratoryTraining.videos =
            state.respiratoryTraining.videos.map((video) => {
              if (
                video.id == action.payload.videoId ||
                video.id == action.payload.sessionVideoId
              )
                video.isFavourite = action.payload.isFavourite;
              return video;
            });
          break;
        case "Work Out":
          state.workOut.videos = state.workOut.videos.map((video) => {
            if (
              video.id == action.payload.videoId ||
              video.id == action.payload.sessionVideoId
            )
              video.isFavourite = action.payload.isFavourite;
            return video;
          });
          break;
        case "Ergonomics":
          state.ergonomics.videos = state.ergonomics.videos.map((video) => {
            if (
              video.id == action.payload.videoId ||
              video.id == action.payload.sessionVideoId
            )
              video.isFavourite = action.payload.isFavourite;
            return video;
          });
          break;
        case "Mindboost":
          state.mindboost.videos = state.mindboost.videos.map((video) => {
            if (
              video.id == action.payload.videoId ||
              video.id == action.payload.sessionVideoId
            )
              video.isFavourite = action.payload.isFavourite;
            return video;
          });
          break;
        case "Pit Stop":
          state.pitStop.videos = state.pitStop.videos.map((video) => {
            if (
              video.id == action.payload.videoId ||
              video.id == action.payload.sessionVideoId
            )
              video.isFavourite = action.payload.isFavourite;
            return video;
          });
          break;
        case "Pulse and Power":
          state.pulseAndPower.videos = state.pulseAndPower.videos.map(
            (video) => {
              if (
                video.id == action.payload.videoId ||
                video.id == action.payload.sessionVideoId
              )
                video.isFavourite = action.payload.isFavourite;
              return video;
            }
          );
          break;
        case "Stretching Break":
          state.stretchingBreaks.videos = state.stretchingBreaks.videos.map(
            (video) => {
              if (
                video.id == action.payload.videoId ||
                video.id == action.payload.sessionVideoId
              )
                video.isFavourite = action.payload.isFavourite;
              return video;
            }
          );
          break;
        case "Training":
          state.training.videos = state.training.videos.map((video) => {
            if (
              video.id == action.payload.videoId ||
              video.id == action.payload.sessionVideoId
            )
              video.isFavourite = action.payload.isFavourite;
            return video;
          });
          break;
        case "After Work - Puls og styrke":
          state.afterWorkPulsAndPower.videos =
            state.afterWorkPulsAndPower.videos.map((video) => {
              if (
                video.id == action.payload.videoId ||
                video.id == action.payload.sessionVideoId
              )
                video.isFavourite = action.payload.isFavourite;
              return video;
            });
          break;
      }
      state.latestVideos.videos = state.latestVideos.videos.map((video) => {
        if (
          video.id == action.payload.videoId ||
          video.id == action.payload.sessionVideoId
        )
          video.isFavourite = action.payload.isFavourite;
        return video;
      });

      state.lastPlayedVideos.videos = state.lastPlayedVideos.videos.map(
        (video) => {
          if (
            video.id == action.payload.videoId ||
            video.id == action.payload.sessionVideoId
          )
            video.isFavourite = action.payload.isFavourite;
          return video;
        }
      );

      state.favorite.videos = state.favorite.videos.map((video) => {
        if (
          video.id == action.payload.videoId ||
          video.id == action.payload.sessionVideoId
        )
          video.isFavourite = action.payload.isFavourite;
        return video;
      });
    },
    [setPage.fulfilled]: (state, action) => {
      switch (action.payload.type) {
        case "favorites":
          state.favorite.page = action.payload;
          break;
        case "last-played":
          state.lastPlayedVideos.page = action.payload;
          break;
        case "latest-videos":
          state.latestVideos.page = action.payload;
          break;
        case "active-breaks":
          state.activeBreaks.page = action.payload;
          break;
        case "work-out":
          state.workOut.page = action.payload;
          break;
        case "yoga":
          state.yoga.page = action.payload;
          break;
        case "respriratory-training":
          state.respiratoryTraining.page = action.payload;
          break;
        case "powerstand":
          state.powerstand.page = action.payload;
          break;
        case "stretching-breaks":
          state.stretchingBreaks.page = action.payload;
          break;
        case "pit-stop":
          state.pitStop.page = action.payload;
          break;
        case "mindboost":
          state.mindboost.page = action.payload;
          break;
        case "ergonomics":
          state.ergonomics.page = action.payload;
          break;
        case "pulse-and-power":
          state.pulseAndPower.page = action.payload;
          break;
        case "training":
          state.training.page = action.payload;
          break;
        default:
          break;
      }
    },
    [setPage.rejected]: (state, action) => {},
  },
});

const { reducer } = dashboardCarrouselVideosSlice;
export default reducer;
