import React, { useState } from "react";
import { InputLabel, TextField, Snackbar, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Add-tag.css";
import userService from "../../services/user.service";
import { setMessage } from "../../slices/message";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

function AddTags() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const [tagName, setTagName] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFormData = () => {
    let data = {
      name: tagName,
    };

    if (id) {
      data.id = id;
    }

    if (!tagName) {
      setOpen(true);
      setUploadResponse({
        status: "error",
        errors: ["FORM_FIELD_DATA_MISSING"],
      });
      return;
    }

    if (id) {
      userService
        .updateTag(data)
        .then((response) => {
          console.log("Response: ", response.data);
          setUploadResponse(response.data);
          setOpen(true);
        })
        .catch((error) => {
          dispatch(setMessage(error.response));
          setUploadResponse(error.response);
          setOpen(true);
        });
      return;
    } else {
      userService
        .uploadTag(data)
        .then((response) => {
          console.log("Response: ", response.data);
          setTagName("");
          setUploadResponse(response.data);
          setOpen(true);
        })
        .catch((error) => {
          dispatch(setMessage(error.response));
          setUploadResponse(error.response);
          setOpen(true);
        });
    }
  };

  useEffect(() => {
    //get tag using userservice.getTag. use try catch
    if (id) {
      userService
        .getTag(id)
        .then((response) => {
          console.log("Response: ", response.data);
          setTagName(response.data.name);
        })
        .catch((error) => {
          dispatch(setMessage(error.response));
          setUploadResponse(error.response);
          setOpen(true);
        });
    }
  }, [id]);

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={
            uploadResponse && uploadResponse.status === "SUCCESS"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {t(
            uploadResponse.status === "SUCCESS"
              ? uploadResponse.successMessage
              : uploadResponse.errors
              ? uploadResponse.errors[0]
              : uploadResponse.data && uploadResponse.data.errors
              ? uploadResponse.data.errors[0]
              : "Something went wrong, Please try again."
          )}
        </Alert>
      </Snackbar>
      <form className="tags-form" noValidate autoComplete="off">
        <Typography
          variant="h4"
          className="head-title"
          gutterBottom
          component="div"
        >
          {id ? t('Edit tag') : t('Add Tag')}
        </Typography>
        <div className="align-items-center">
          <InputLabel id="category-label" className="required">
            {t("Tags")}
          </InputLabel>
          <TextField
            required
            className="tags-form-input"
            placeholder={t("Tags")}
            inputProps={{ "aria-label": "description" }}
            id="event-title"
            value={tagName ? tagName : ""}
            onChange={(event) => setTagName(event.target.value)}
          />
        </div>

        <div className="align-items-center upload-button-block">
          <button
            type="button"
            className="upload-button"
            onClick={handleFormData}
          >
            {id ? t("Update") : t("Add")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddTags;
