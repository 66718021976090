import React, { Suspense, useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  Grid,
  Radio,
  FormControl,
  FormLabel,
  Card,
  CardContent,
  Button,
  Hidden,
  Snackbar,
  Alert,
  Badge,
  Tooltip,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Allvideos.css";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { PlayArrow, ArrowUpward } from "@mui/icons-material";
import ReplayVideo from "../Player/ReplayVideo";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import userService from "../../services/user.service";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVideos,
  setPage,
  addToFavorites,
  removeFromFavorites,
  removeVideo,
} from "../../slices/all-videos";
import {
  removeFromFavorites as removeFromFavorite,
  setPage as setFavoritepage,
} from "../../slices/favorites";
import { getAllTags } from "../../slices/tags";
import moment from "moment";
import Spinner from "../ProgressBar/Spinner";
import { getTeams, getInstructors } from "../../slices/uploadData";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useHistory } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getVideoById } from "../../slices/uploadVideo";
import ReactAddToCalendar from "../ReactAddToCalendar/ReactAddToCalendar";
import "../ReactAddToCalendar/styles/ReactAddToCalendar.scss";
import { getFavoriteVideos } from "../../slices/favorites";
import { refreshLikesDislikes } from "../../slices/dashboard-carrousel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AllVideos(props) {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedInstructor, setInstructor] = useState(null);
  const [selectedTeam, setTeam] = useState(props.match.params.typeId || null);
  const [selectedLanguage, setLanguage] = useState(null);
  const [selectedSubtitleLanguage, setSubtitleLanguage] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [date, setDate] = useState(null);
  const [showInstructor, setInstructorVisibility] = useState(false);
  const [showTeam, setTeamVisibility] = useState(false);
  const [showLanguage, setLanguageVisibility] = useState(false);
  const [showDate, setDateVisibility] = useState(false);
  const [showTags, setTagsVisibility] = useState(false);
  const [showDuration, setDurationVisibility] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const { videos } = useSelector((state) => state.allVideos);
  const { tags } = useSelector((state) => state.allTags);
  const { page } = useSelector((state) => state.allVideos);
  const isLoading = useSelector((state) => state.allVideos.isFetching);
  const dispatch = useDispatch();
  const [duration, setDuration] = useState("");
  const Teams = useSelector((state) => state.uploadData.teams);
  const instructors = useSelector((state) => state.uploadData.instructors);
  const token = useSelector((state) => state.message.token);
  const history = useHistory();
  const [tagsData, setTags] = useState([]);
  let icon = { "calendar-plus-o": "left" };

  const [showDialog, setShowDialog] = useState(false);
  const [videoForDeletion, setVideoForDeletion] = useState(null);

  const { userDetails } = useSelector((state) => state.userData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const role = userDetails ? userDetails.userType : "";

  const handleTagsChange = async (event) => {
    await dispatch(setPage(1));
    const {
      target: { value },
    } = event;
    setTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const setTagsNameData = (selected) => {
    if (selected[0] === t('Select') && selected.length === 1) {
      return <p>{t('Select')}</p>;
    }
    let namesArray = [];
    selected.forEach((item) => {
      tags.forEach((obj) => {
        if (item === obj.id) namesArray.push(t(obj.name));
      });
    });
    return namesArray.join(", ");
  };

  const handleEditVideo = async (listItem) => {
    if (listItem.id && listItem.sessionId)
      dispatch(getVideoById({ sessionVideoId: listItem.id }));
    else if (listItem.id && !listItem.sessionId)
      dispatch(getVideoById({ videoId: listItem.id }));

    history.push("/upload/" + listItem.id);
  };

  const handleRadioOptionChange = (event, id) => {
    switch (id) {
      case "instructor":
        setInstructor(event.target.value);
        fetchData(
          event.target.value,
          selectedTeam,
          selectedLanguage,
          1,
          date,
          duration
        );
        break;
      case "team":
        history.replace("/all-videos");
        setTeam(event.target.value);
        fetchData(
          selectedInstructor,
          event.target.value,
          selectedLanguage,
          1,
          date,
          duration
        );
        break;
      case "language":
        setLanguage(event.target.value);
        fetchData(
          selectedInstructor,
          selectedTeam,
          event.target.value,
          1,
          date,
          duration
        );
        break;
      case "date-picker":
        setDate(() => {
          return event;
        });
        fetchData(
          selectedInstructor,
          selectedTeam,
          selectedLanguage,
          1,
          moment(event).format("YYYY-MM-DD"),
          duration
        );
        break;
      case "duration":
        setDuration(event.target.value);
        fetchData(
          selectedInstructor,
          selectedTeam,
          selectedLanguage,
          1,
          date,
          event.target.value
        );
        break;
      default:
        break;
    }
    dispatch(setPage(1));
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("scroll", handleScroll);
    dispatch(getTeams());
    dispatch(getInstructors());
    dispatch(getAllTags());
    // eslint-disable-next-line
  }, [
    selectedTeam,
    selectedInstructor,
    selectedLanguage,
    duration,
    tagsData,
    token,
    selectedSubtitleLanguage,
  ]);

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
        document.documentElement.offsetHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  };

  const fetchData = async (
    instructor,
    team,
    language,
    currentPage,
    selectedDate,
    durationVal,
    subtitleLanguage
  ) => {
    let data = {
      ...((instructor || selectedInstructor) && {
        userId: instructor
          ? instructor
          : selectedInstructor
          ? selectedInstructor
          : "",
      }),
      ...((team || selectedTeam) && {
        typeId: team ? team : selectedTeam ? selectedTeam : "",
      }),
      ...((language || selectedLanguage) && {
        language: language
          ? language
          : selectedLanguage
          ? selectedLanguage
          : "",
      }),
      ...(selectedDate && { date: selectedDate ? selectedDate : "" }),
      ...((durationVal || duration) && {
        durationRange: durationVal ? durationVal : duration ? duration : "",
      }),
      ...(tagsData && tagsData.length > 0 && { tagIdList: tagsData }),
      ...((subtitleLanguage || selectedSubtitleLanguage) && {
        subtitleLanguage: subtitleLanguage
          ? subtitleLanguage.join(",")
          : selectedSubtitleLanguage
          ? selectedSubtitleLanguage.join(",")
          : "",
      }),
      favourites: false,
      page: currentPage ? currentPage : page,
    };
    console.log(page);
    setTimeout(async () => {
      await dispatch(getAllVideos(data));
      setIsFetching(false);
    }, 1000);
  };

  const fetchFavoriteData = async (
    instructor,
    team,
    language,
    currentPage,
    selectedDate,
    duration
  ) => {
    let data = {
      ...((instructor || selectedInstructor) && {
        userId: instructor
          ? instructor
          : selectedInstructor
          ? selectedInstructor
          : "",
      }),
      ...((team || selectedTeam) && {
        typeId: team ? team : selectedTeam ? selectedTeam : "",
      }),
      ...((language || selectedLanguage) && {
        language: language
          ? language
          : selectedLanguage
          ? selectedLanguage
          : "",
      }),
      ...(selectedDate && { date: selectedDate ? selectedDate : "" }),
      ...(duration && { durationRange: duration ? duration : "" }),
      ...(tagsData && tagsData.length > 0 && { tagIdList: tagsData }),
      favourites: true,
      page: currentPage ? currentPage : 1,
    };

    setTimeout(async () => {
      dispatch(setFavoritepage(1));
      dispatch(getFavoriteVideos(data));
      dispatch(setFavoritepage(1));
      setIsFetching(false);
    }, 100);
  };

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
    // eslint-disable-next-line
  }, [isFetching]);

  const fetchMoreListItems = () => {
    fetchData();
    setIsFetching(false);
  };

  const addVideoToFavorites = (data) => {
    userService
      .addVideoToFavorites(data)
      .then((response) => {
        setUploadResponse(response.data);
        setOpenMessage(true);
        if (response.data.status === "SUCCESS") {
          dispatch(
            addToFavorites(
              data.sessionVideoId
                ? { vid: data.sessionVideoId }
                : { vid: data.videoId }
            )
          );
          dispatch(refreshLikesDislikes(data));
          fetchFavoriteData();
        }
      })
      .catch((error) => {
        console.log(error);
        setUploadResponse(error.response.data);
        setOpenMessage(true);
      });
  };
  const removeVideoFromFavorites = (data) => {
    userService
      .removeVideoFromFavorites(data)
      .then((response) => {
        setUploadResponse(response.data);
        setOpenMessage(true);
        if (response.data.status === "SUCCESS") {
          dispatch(
            removeFromFavorites(
              data.sessionVideoId
                ? { vid: data.sessionVideoId }
                : { vid: data.videoId }
            )
          );
          dispatch(
            removeFromFavorite(
              data.sessionVideoId
                ? { vid: data.sessionVideoId }
                : { vid: data.videoId }
            )
          );
          dispatch(refreshLikesDislikes(data));
        }
      })
      .catch((error) => {
        console.log(error);
        setUploadResponse(error.response.data);
        setOpenMessage(true);
      });
  };

  const deleteVideo = (data) => {
    userService
      .deleteVideo(data)
      .then((response) => {
        setUploadResponse(response.data);
        setOpenMessage(true);
        if (response.data.status === "SUCCESS") {
          dispatch(
            removeVideo(
              data.sessionVideoId
                ? { vid: data.sessionVideoId }
                : { vid: data.videoId }
            )
          );
          dispatch(
            removeFromFavorite(
              data.sessionVideoId
                ? { vid: data.sessionVideoId }
                : { vid: data.videoId }
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setUploadResponse(error.response.data);
        setOpenMessage(true);
      });
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  window.addEventListener("scroll", toggleVisible);

  const expandFilterPanel = (id, show) => {
    switch (id) {
      case "instructor":
        setInstructorVisibility(() => {
          return show;
        });
        break;
      case "team":
        setTeamVisibility(() => {
          return show;
        });
        break;
      case "language":
        setLanguageVisibility(() => {
          return show;
        });
        break;
      case "date":
        setDateVisibility(() => {
          return show;
        });
        break;
      case "duration":
        setDurationVisibility(() => {
          return show;
        });
        break;
      case "tags":
        setTagsVisibility(() => {
          return show;
        });
        break;
      default:
        break;
    }
  };

  const resetData = async () => {
    await dispatch(setPage(1));
    setInstructor(null);
    setTeam(null);
    setLanguage(null);
    setDate(null);
    setDuration("");
    setTags([]);
    setSubtitleLanguage([]);
  };

  const clearFilters = async () => {
    await resetData();
    history.replace("/all-videos");
  };

  const getDaysArray = (videoLiveEventDays) => {
    let Days = ["MO", "TU", "WE", "TH", "FR"];
    let days = [];
    videoLiveEventDays
      .replace(/[&\/\\#:\[\]+()$~%.'"*?<>{}Days]/g, "")
      .split(",")
      .map((day) => days.push(Days[day - 1]));
    console.log(days);
    return days;
  };

  const createFilters = () => {
    return (
      <div>
        <Button
          color="primary"
          className="scroll-to-top-button"
          style={{ display: visible ? "inline" : "none" }}
        >
          <ArrowUpward
            onClick={scrollToTop}
            style={{ display: visible ? "inline" : "none" }}
          />
        </Button>

        <p className="clear-filter">
          {(selectedInstructor ||
            selectedTeam ||
            selectedLanguage ||
            date ||
            duration ||
            tagsData.length > 0 ||
            selectedSubtitleLanguage.length > 0) && (
            <Button
              size="small"
              onClick={() => clearFilters()}
              variant="outlined"
              style={{
                borderRadius: "20px",
                fontSize: "10px",
                fontWeight: "bold",
                backgroundColor: "#404040",
                color: "#ffffff",
                borderColor: "#404040",
              }}
              startIcon={
                <NotInterestedIcon
                  style={{ fontSize: "16px", verticalAlign: "top" }}
                />
              }
            >
              <span>{t("Clear filters")}</span>
            </Button>
          )}
        </p>

        <FormControl component="fieldset" className="form-control">
          <FormLabel component="legend" className="filter-label">
            {t("Category")}
            <Hidden mdUp>
              {!showTeam ? (
                <ExpandMoreIcon
                  onClick={() => expandFilterPanel("team", true)}
                  className="expand-icon"
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => expandFilterPanel("team", false)}
                  className="expand-icon"
                />
              )}
            </Hidden>
            <p className="clear-filter-btn">
              {selectedTeam && (
                <Button
                  size="small"
                  onClick={() => {
                    setTeam(null);
                  }}
                  variant="outlined"
                  style={{ color: "#ffffff", borderColor: "#404040" }}
                  startIcon={
                    <NotInterestedIcon style={{ right: 0, marginRight: 0 }} />
                  }
                ></Button>
              )}
            </p>
          </FormLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="team"
            value={selectedTeam ? selectedTeam : t('Select')}
            onChange={(event) => handleRadioOptionChange(event, "team")}
            input={<OutlinedInput />}
            className={`${!showTeam && "d-none"} filter-radio-group tags-input`}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              let namesArray = "";
              Teams.forEach((obj) => {
                if (selected === obj.id) namesArray = obj.type;
              });
              return namesArray ? namesArray : t('Select');
            }}
          >
            {Teams &&
              Teams.length > 0 &&
              Teams.slice()
                .sort((a, b) => t(a.type).localeCompare(t(b.type)))
                .map((team, index) => {
                  return (
                    <MenuItem key={index} value={team.id}>
                      <ListItemText primary={t(team.type)} />
                    </MenuItem>
                  );
                })}
          </Select>
        </FormControl>

        <FormControl component="fieldset" className="form-control">
          <FormLabel component="legend" className="filter-label">
            {t("Tags")}
            <Hidden mdUp>
              {!showTags ? (
                <ExpandMoreIcon
                  onClick={() => expandFilterPanel("tags", true)}
                  className="expand-icon"
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => expandFilterPanel("tags", false)}
                  className="expand-icon"
                />
              )}
            </Hidden>
            <p className="clear-filter-btn">
              {tagsData.length > 0 && (
                <Button
                  size="small"
                  onClick={() => {
                    setTags([]);
                  }}
                  variant="outlined"
                  style={{ color: "#ffffff", borderColor: "#404040" }}
                  startIcon={
                    <NotInterestedIcon style={{ right: 0, marginRight: 0 }} />
                  }
                ></Button>
              )}
            </p>
          </FormLabel>

          <Select
            labelId="demo-multiple-checkbox-label"
            id="tags"
            multiple
            value={tagsData && tagsData.length > 0 ? tagsData : [t('Select')]}
            onChange={handleTagsChange}
            input={<OutlinedInput />}
            className={`${!showTags && "d-none"} filter-radio-group tags-input`}
            // renderValue={(selected) => selected ? setTagsNameData(selected) : "Select"}
            renderValue={(selected) => {
              return setTagsNameData(selected);
            }}
            MenuProps={MenuProps}
          >
            {tags
              .slice()
              .sort((a, b) => t(a.name).localeCompare(t(b.name)))
              .map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox checked={tagsData.indexOf(item.id) > -1} />
                  <ListItemText primary={t(item.name)} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl component="fieldset" className="form-control">
          <FormLabel component="legend" className="filter-label">
            {t("Duration")}
            <Hidden mdUp>
              {!showDuration ? (
                <ExpandMoreIcon
                  onClick={() => expandFilterPanel("duration", true)}
                  className="expand-icon"
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => expandFilterPanel("duration", false)}
                  className="expand-icon"
                />
              )}
            </Hidden>
            <p className="clear-filter-btn">
              {duration && (
                <Button
                  size="small"
                  onClick={() => {
                    setDuration("");
                  }}
                  variant="outlined"
                  style={{ color: "#ffffff", borderColor: "#404040" }}
                  startIcon={
                    <NotInterestedIcon style={{ right: 0, marginRight: 0 }} />
                  }
                ></Button>
              )}
            </p>
          </FormLabel>
          <Select
            id="duration"
            className={`${
              !showDuration && "d-none"
            } filter-radio-group tags-input`}
            value={duration ? duration : t('Select')}
            onChange={(event) => handleRadioOptionChange(event, "duration")}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              return selected ? selected : t('Select');
            }}
          >
            <MenuItem value="0-5" className="filter-options">
              <ListItemText
                onClick={(event) =>
                  event.target.value === duration ? setDuration("") : null
                }
                primary="0-5 min"
              />
            </MenuItem>
            <MenuItem value="5-15" className="filter-options">
              <ListItemText
                onClick={(event) =>
                  event.target.value === duration ? setDuration("") : null
                }
                primary="5-15 min"
              />
            </MenuItem>
            <MenuItem value="15-30" className="filter-options">
              <ListItemText
                onClick={(event) =>
                  event.target.value === duration ? setDuration("") : null
                }
                primary="15-30 min"
              />
            </MenuItem>
            <MenuItem value="30-60" className="filter-options">
              <ListItemText
                onClick={(event) =>
                  event.target.value === duration ? setDuration("") : null
                }
                primary="30-60 min"
              />
            </MenuItem>
            <MenuItem value="over 60" className="filter-options">
              <ListItemText
                onClick={(event) =>
                  event.target.value === duration ? setDuration("") : null
                }
                primary="Over 60 min"
              />
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl component="fieldset" className="form-control">
          <FormLabel component="legend" className="filter-label">
            {t("Instructor")}
            <Hidden mdUp>
              {!showInstructor ? (
                <ExpandMoreIcon
                  onClick={() => expandFilterPanel("instructor", true)}
                  className="expand-icon"
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => expandFilterPanel("instructor", false)}
                  className="expand-icon"
                />
              )}
            </Hidden>
            <p className="clear-filter-btn">
              {selectedInstructor && (
                <Button
                  size="small"
                  onClick={() => {
                    setInstructor(null);
                  }}
                  variant="outlined"
                  style={{ color: "#ffffff", borderColor: "#404040" }}
                  startIcon={
                    <NotInterestedIcon style={{ right: 0, marginRight: 0 }} />
                  }
                ></Button>
              )}
            </p>
          </FormLabel>
          <Select
            id="instructor"
            className={`${
              !showInstructor && "d-none"
            } filter-radio-group tags-input`}
            value={selectedInstructor ? selectedInstructor : t('Select')}
            onChange={(event) => handleRadioOptionChange(event, "instructor")}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              let namesArray = "";
              instructors.forEach((obj) => {
                if (selected === obj.id)
                  namesArray = obj.firstName + " " + obj.lastName;
              });
              return namesArray ? namesArray : t('Select');
            }}
          >
            {instructors &&
              instructors.length > 0 &&
              instructors.map((instructor, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={instructor.id}
                    className="filter-options"
                    control={
                      <Radio
                        size="small"
                        onClick={(event) =>
                          event.target.value === selectedInstructor
                            ? setInstructor(null)
                            : null
                        }
                        sx={{
                          color: "#404040",
                          "&.Mui-checked": { color: "#404040" },
                        }}
                      />
                    }
                    label={instructor.firstName + " " + instructor.lastName}
                  >
                    <ListItemText
                      primary={instructor.firstName + " " + instructor.lastName}
                    />
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControl component="fieldset" className="form-control">
          <FormLabel component="legend" className="filter-label">
            {t("Language")}
            <Hidden mdUp>
              {!showLanguage ? (
                <ExpandMoreIcon
                  onClick={() => expandFilterPanel("language", true)}
                  className="expand-icon"
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => expandFilterPanel("language", false)}
                  className="expand-icon"
                />
              )}
            </Hidden>
            <p className="clear-filter-btn">
              {selectedLanguage && (
                <Button
                  size="small"
                  onClick={() => {
                    setLanguage(null);
                  }}
                  variant="outlined"
                  style={{ color: "#ffffff", borderColor: "#404040" }}
                  startIcon={
                    <NotInterestedIcon style={{ right: 0, marginRight: 0 }} />
                  }
                ></Button>
              )}
            </p>
          </FormLabel>
          <Select
            id="language"
            className={`${
              !showLanguage && "d-none"
            } filter-radio-group tags-input`}
            aria-label="Language"
            value={selectedLanguage ? selectedLanguage : t('Select')}
            onChange={(event) => handleRadioOptionChange(event, "language")}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              return selected ? selected : t('Select');
            }}
          >
            <MenuItem
              value="da"
              className="filter-options"
              onClick={(event) =>
                event.target.value === selectedLanguage
                  ? setLanguage(null)
                  : null
              }
            >
              <ListItemText primary={`${t("Danish")}`}></ListItemText>
            </MenuItem>
            <MenuItem
              value="en"
              className="filter-options"
              onClick={(event) =>
                event.target.value === selectedLanguage
                  ? setLanguage(null)
                  : null
              }
            >
              <ListItemText primary={`${t("English")}`}></ListItemText>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl component="fieldset" className="form-control">
          <FormLabel component="legend" className="filter-label">
            {t("Subtitle language")}
            <Hidden mdUp>
              {!showLanguage ? (
                <ExpandMoreIcon
                  onClick={() => expandFilterPanel("subtitle-language", true)}
                  className="expand-icon"
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => expandFilterPanel("subtitle-language", false)}
                  className="expand-icon"
                />
              )}
            </Hidden>
            <p className="clear-filter-btn">
              {selectedSubtitleLanguage.length > 0 && (
                <Button
                  size="small"
                  onClick={() => {
                    setSubtitleLanguage([]);
                  }}
                  variant="outlined"
                  style={{ color: "#ffffff", borderColor: "#404040" }}
                  startIcon={
                    <NotInterestedIcon style={{ right: 0, marginRight: 0 }} />
                  }
                ></Button>
              )}
            </p>
          </FormLabel>
          <Select
            multiple
            id="subtitle-language"
            className="filter-radio-group tags-input"
            aria-label="Subtitle language"
            value={
              selectedSubtitleLanguage && selectedSubtitleLanguage.length > 0
                ? selectedSubtitleLanguage
                : [t('Select')]
            }
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              //return comma delimeted string of selected languages
              if (selected[0] === t('Select') && selected.length === 1) {
                return <p>{t('Select')}</p>;
              }
              var namesArray = [];
              selected.forEach((item) => {
                if (item === "da") namesArray.push(t("Danish"));
                else if (item === "en") namesArray.push(t("English"));
              });
              return namesArray.join(", ");
            }}
            onChange={async (event) => {
              await dispatch(setPage(1));
              const {
                target: { value },
              } = event;

              setSubtitleLanguage(
                typeof value === "string" ? value.split("") : value
              );
            }}
          >
            <MenuItem value="da" className="filter-options">
              <Checkbox checked={selectedSubtitleLanguage.indexOf("da") > -1} />
              <ListItemText primary={`${t("Danish")}`}></ListItemText>
            </MenuItem>
            <MenuItem value="en" className="filter-options">
              <Checkbox checked={selectedSubtitleLanguage.indexOf("en") > -1} />
              <ListItemText primary={`${t("English")}`}></ListItemText>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl component="fieldset" className="form-control">
          <FormLabel component="legend" className="filter-label">
            {t("Date")}
            <Hidden mdUp>
              {!showDate ? (
                <ExpandMoreIcon
                  onClick={() => expandFilterPanel("date", true)}
                  className="expand-icon"
                />
              ) : (
                <ExpandLessIcon
                  onClick={() => expandFilterPanel("date", false)}
                  className="expand-icon"
                />
              )}
            </Hidden>
            <p className="clear-filter-btn">
              {date && (
                <Button
                  size="small"
                  onClick={() => {
                    setDate(null);
                  }}
                  variant="outlined"
                  style={{ color: "#ffffff", borderColor: "#404040" }}
                  startIcon={
                    <NotInterestedIcon style={{ right: 0, marginRight: 0 }} />
                  }
                ></Button>
              )}
            </p>
          </FormLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={`${!showDate && "d-none"} filter-radio-group`}
              inputFormat="dd/MM/yyyy"
              id="date"
              onChange={(event) =>
                handleRadioOptionChange(event, "date-picker")
              }
              value={date}
              required
              renderInput={(params) => (
                <TextField
                  className={`${
                    !showDate && "d-none"
                  } filter-radio-group all-videos-page-date-picker`}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
    );
  };

  const handleDialogClose = () => {
    setShowDialog(false);
    setVideoForDeletion(null);
  };

  const handleConfirmDelete = () => {
    deleteVideo(videoForDeletion);
    setShowDialog(false);
  };

  const handleDeleteVideo = (video) => {
    setVideoForDeletion(video);
    setShowDialog(true);
  };

  return (
    <div>
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={() => {
          setOpenMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(false);
          }}
          severity={
            uploadResponse && uploadResponse.status === "SUCCESS"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {t(
            uploadResponse.status === "SUCCESS"
              ? uploadResponse.successMessage
              : uploadResponse.errors[0]
          )}
        </Alert>
      </Snackbar>

      {isLoading ? <Spinner /> : null}

      <Dialog
        fullScreen
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <ReplayVideo
          onClose={() => {
            setOpen(false);
          }}
          selectedVideo={selectedVideo}
        />
      </Dialog>

      <Dialog open={showDialog} onClose={handleDialogClose}>
        <DialogTitle>{t("Delete video")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Delete Video Confirmation Dialog")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogClose}>
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="outlined"
            color="error"
          >
            {t("Delete video")}
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container className="all-videos-page">
        <Grid item xs={12} sm={12} md={3} lg={3}>
          {createFilters()}
        </Grid>
        <Grid item xs={12} sm={12} md={8.7} lg={8.7} className="all-video-list">
          <p className="font-weight-bold video-block-title">
            {t("All Videos")}
          </p>
          <Grid container spacing={3}>
            {(!videos || videos.length === 0) && (
              <Grid item>
                <p>{t("No Videos Found")}</p>
              </Grid>
            )}
            {videos.map((listItem, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card className="video-card" style={{ position: "relative" }}>
                    <div className="overlay"></div>
                    <button
                      disabled={false}
                      style={{
                        display: "none",
                      }}
                      className="arrow-buttons play-button"
                      onClick={() => {
                        setOpen(true);
                        setSelectedVideo(listItem);
                      }}
                    >
                      <PlayArrow />
                    </button>

                    <CardContent className="video-card-content">
                      <Suspense
                        fallback={
                          <img
                            src={listItem.thumbnailUrl}
                            alt="Avatar"
                            className="video-slider-media"
                          />
                        }
                      >
                        <img
                          src={listItem.thumbnailUrl}
                          alt="Avatar"
                          className="video-slider-media"
                        />
                      </Suspense>

                      <p className="video-scroll-menu-text font-weight-bold">
                        {t(listItem.videoType)}
                      </p>
                      <p className="video-scroll-menu-text">{`${listItem.firstName} ${listItem.lastName}`}</p>

                      <ReactAddToCalendar
                        className="react-all-videos-add-to-calendar"
                        buttonTemplate={icon}
                        event={{
                          btnTitle: "",
                          title: listItem.videoTitle,
                          description: `${
                            listItem.notes ? listItem.notes + " " : ""
                          }Video Url:${listItem.videoUrl}`,
                          location: "Denmark",
                          startTime: listItem.startTime,
                          endTime: listItem.startTime,
                        }}
                      />
                      {userDetails && isLoggedIn && role === "Admin" && (
                        <button
                          className="edit-button"
                          onClick={() => {
                            handleEditVideo(listItem);
                          }}
                        >
                          <EditIcon />
                        </button>
                      )}
                      {userDetails && isLoggedIn && role === "Admin" && (
                        <button
                          className="delete-button"
                          onClick={() => {
                            handleDeleteVideo(
                              listItem.sessionId
                                ? { sessionVideoId: listItem.id }
                                : { videoId: listItem.id }
                            );
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      {!listItem.isFavourite && (
                        <button
                          className="favorite-button"
                          onClick={() => {
                            addVideoToFavorites(
                              listItem.sessionId
                                ? { sessionVideoId: listItem.id, videoType: listItem.videoType, videoTypeId: listItem.videoTypeId, isFavourite: true }
                                : { videoId: listItem.id, videoType: listItem.videoType, videoTypeId: listItem.videoTypeId, isFavourite: true }
                            );
                            
                          }}
                        >
                          <FavoriteBorderIcon className="favorite-icon" />
                        </button>
                      )}
                      {listItem.isFavourite && (
                        <button
                          className="favorite-button"
                          onClick={() => {
                            removeVideoFromFavorites(
                              listItem.sessionId
                                ? { sessionVideoId: listItem.id, videoType: listItem.videoType, videoTypeId: listItem.videoTypeId, isFavourite: false}
                                : { videoId: listItem.id, videoType: listItem.videoType, videoTypeId: listItem.videoTypeId, isFavourite: false }
                            );
                          }}
                        >
                          <FavoriteIcon className="favorite-icon" />
                        </button>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {isFetching && <h3>Fetching more videos...</h3>}
        </Grid>
      </Grid>
    </div>
  );
}

export default AllVideos;
