import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Divider,
  Hidden,
  Drawer,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../img/GetUpp_logo_GREY_BLUE.png";
import { Link } from "react-router-dom";
import "./Admin-nav.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import common from "../../common/common";
import { logout } from "../../slices/auth";

function AdminNav() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setDrawerOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleProfileMenuChange = (value) => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    let open = openDrawer;
    setDrawerOpen(!open);
  };

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    common.on("logout", () => {
      logOut();
    });

    return () => {
      common.remove("logout");
    };
  }, [logOut]);

  return (
    <div>
      <AppBar position="sticky" className="navbar">
        <CssBaseline />
        <Toolbar>
          <Link to="/" className="align-items-center">
            <img className="navLogo" src={logo} alt="" />
          </Link>
          <Hidden smUp>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
              className="navIconHide"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={openDrawer}
              onClose={handleDrawerToggle}
              className="mobile-drawer"
            >
              <Link
                onClick={handleDrawerToggle}
                to="/all-videos"
                className={`navItems ${
                  location.pathname === "/all-videos" ? "active" : ""
                }`}
              >
                {t("All Videos")}
              </Link>
              <Link
                to="/sessions"
                className={`navItems ${
                  location.pathname === "/sessions" ? "active" : ""
                }`}
              >
                {t("Training Sessions")}
              </Link>
              <Link
                onClick={handleDrawerToggle}
                to="/upload"
                className={`navItems ${
                  location.pathname === "/upload" ? "active" : ""
                }`}
              >
                {t("Upload Video")}
              </Link>
              <Link
                onClick={handleDrawerToggle}
                to="/upload-session"
                className={`navItems ${
                  location.pathname === "/upload-session" ? "active" : ""
                }`}
              >
                {t("Upload Session")}
              </Link>
              <Link
                onClick={handleDrawerToggle}
                to="/upload-live-event"
                className={`navItems ${
                  location.pathname === "/upload-live-event" ? "active" : ""
                }`}
              >
                {t("Upload Live Event")}
              </Link>
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <div className="navList">
              <Link
                to="/all-videos"
                className={`navItems ${
                  location.pathname === "/all-videos" ? "active" : ""
                }`}
              >
                {t("All Videos")}
              </Link>
              <Link
                to="/sessions"
                className={`navItems ${
                  location.pathname === "/sessions" ? "active" : ""
                }`}
              >
                {t("Training Sessions")}
              </Link>
            </div>
          </Hidden>
          <div className="admin-nav-options">
            <button className="logout-button">Admin</button>
          </div>
          <Link to="/profile">
            <AccountCircle className="admin-nav-avatar" />
          </Link>
          <div className="navAvatar">
            <Button
              aria-owns={anchorEl ? "profile-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              onMouseOver={handleClick}
              edge="end"
              aria-label="profile selected by current user"
              aria-controls="profile-menu"
              className="navAvatar"
            >
              {/* <img className="navAvatar" src={avatar} alt="" /> */}
              <AccountCircle
                style={{ fontSize: "25px" }}
                className="admin-nav-avatar"
              />
            </Button>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{ onMouseLeave: handleClose }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              style={{ marginTop: "2.2em" }}
            >
              <MenuItem onClick={() => handleProfileMenuChange()}>
                <Link className="profile-links" to="/add-category">
                  {t("Add Category")}
                </Link>
              </MenuItem>
              <MenuItem onClick={() => handleProfileMenuChange()}>
                <Link className="profile-links" to="/tags">
                  {t("Tags")}
                </Link>
              </MenuItem>
              <MenuItem onClick={() => handleProfileMenuChange()}>
                <Link
                  className={`profile-links ${
                    location.pathname === "/upload" ? "active" : ""
                  }`}
                  to="/upload"
                >
                  {t("Upload Video")}
                </Link>
              </MenuItem>
              <MenuItem onClick={() => handleProfileMenuChange()}>
                <Link
                  className={`profile-links ${
                    location.pathname === "/upload-session" ? "active" : ""
                  }`}
                  to="/upload-session"
                >
                  {t("Upload Session")}
                </Link>
              </MenuItem>
              <MenuItem onClick={() => handleProfileMenuChange()}>
                <Link
                  className={`profile-links ${
                    location.pathname === "/upload-live-event" ? "active" : ""
                  }`}
                  to="/upload-live-event"
                >
                  {t("Upload Live Event")}
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem onClick={logOut}>{t("Logout")}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AdminNav;
