import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import userService from "../services/user.service";

export const getInstructorSearchVideos = createAsyncThunk(
  "instructorSearchVideos/getInstructorSearchVideos",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getInstructorSearchVideos(queryParams);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = { videos: [], isFetching: false, isFetched: false, page: 2 };

const instructorSearchVideosSlice = createSlice({
  name: "instructorSearchVideos",
  initialState,
  reducers:{
    setInstructorVideos: (state, action) => {
      state.videos = action.payload;
    }
  },
  extraReducers: {
    [getInstructorSearchVideos.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isFetched = true;
      state.videos = [...state.videos, ...action.payload];
      state.page = state.page + 1;
    },
    [getInstructorSearchVideos.rejected]: (state, action) => {
      state.isFetching = false;
      state.isFetched = false;
    }
  },
});

const { reducer,actions } = instructorSearchVideosSlice;
export const { setInstructorVideos } = actions
export default reducer;
