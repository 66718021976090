import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import userService from "../services/user.service";

export const getDashboard = createAsyncThunk(
    "dashboard/data",
    async (queryParams, thunkAPI) => {
      try {
        const response = await userService.getDashboardData(queryParams)
        thunkAPI.dispatch(setMessage(response.data))
        return {LiveEvents : response.data.liveEvent, Videos: response.data.videos}
      } catch (err) {
        thunkAPI.dispatch(setMessage(err.response));
        return thunkAPI.rejectWithValue(err.response.data);
      }
    }
  )
 
  const initialState = { dashboard: {}, isFetching: true };

  const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    extraReducers: {
      [getDashboard.fulfilled]: (state, action) => {
        let data = {...action.payload};
        data.Videos.forEach(element => {
          element.isLiveEvent = false;
        });
        if(data.LiveEvents) data.LiveEvents.isLiveEvent = true;
        if(data.LiveEvents) {
          data.Videos.push(data.LiveEvents); 
          delete data.LiveEvents;
        }
        state.dashboard = data;
        state.isFetching = false;
      },
      [getDashboard.rejected]: (state, action) => {
        state.isFetching = false;
      }
    },
  });
  
  const { reducer } = dashboardSlice;
  export default reducer;