import { Grid, FormControl, FormLabel, Card, CardContent, Button, Hidden, Select, ListItemText, MenuItem, OutlinedInput, Badge, Tooltip, Box, IconButton, CardActions, Dialog, DialogTitle, DialogContent, DialogActions, MenuList, Menu, Snackbar, Alert, FormGroup, FormControlLabel } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import React, {Suspense, useEffect, useState} from 'react';
import { PlayArrow, ArrowUpward } from '@mui/icons-material';
import WorkOutImage from "../../img/Home-img/After Work Cardio/After Work Cardio 3000x1300 .png";
import ActiveBreaksImage from "../../img/Home-img/Morgenbevægelse/Morgenbevægelse 3000x1300.png";
import RespiratoryTrainingImage from "../../img/Home-img/Åndedrætstræning/Åndedrætstræning 4 3000x1300.png";
import YogaImage from "../../img/Home-img/Yoga/Yoga 4 3000x1300 .png";
import "./Sessions.css";
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getAllSessions, setPage } from '../../slices/all-sessions';
import { getAllTags } from '../../slices/tags';
import { getTeams, getInstructors } from '../../slices/uploadData';
import Spinner from '../ProgressBar/Spinner';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import userService from '../../services/user.service';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Sessions() {
    const {t} = useTranslation();
    const [visible, setVisible] = useState(false)
	const [isFetching, setIsFetching] = useState(false);
    const { sessions } = useSelector((state) => state.allSessions);
    const [selectedInstructor, setInstructor] = useState(null);
    const [selectedTeam, setTeam] = useState(null);
    const [selectedLanguage, setLanguage] = useState(null);
    const [showInstructor, setInstructorVisibility] = useState(false);
    const [showTeam, setTeamVisibility] = useState(false);
    const [showTags, setTagsVisibility] = useState(false);
    const { tags } = useSelector((state) => state.allTags);
    const [tagsData, setTags] = useState([]);
    const [showLanguage, setLanguageVisibility] = useState(false);
    const { page } = useSelector((state) => state.allSessions);
    const token  = useSelector((state) => state.message.token);
    const Teams = useSelector((state) => state.uploadData.teams);
    const instructors = useSelector((state) => state.uploadData.instructors);
    const isLoading = useSelector((state) => !(state.allSessions.isFetched));
    const dispatch = useDispatch();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [sessionToDelete, setSessionToDelete] = useState(null);
    const [deleteResponse, setDeleteResponse] = useState({status:'',successMessage:'',errors:[]});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [includeSessionVideos, setIncludeSessionVideos] = useState(false);

    const { userDetails } = useSelector((state) => state.userData);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const role = userDetails ? userDetails.userType : "";

    const handleTagsChange = async (event) => {     
        await dispatch(setPage(1));
        const {
          target: { value },
        } = event;
        setTags(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const setTagsNameData = (selected) => {
        if(selected[0] === "Select" && selected.length === 1) 
        {
            return <p>Select</p>;
        }
          let namesArray=[];
            selected.forEach(item => {
                tags.forEach(obj => {
                    if(item===obj.id)
                        namesArray.push(t(obj.name))
                })
            })
            return namesArray.join(", ");
    };


    const handleRadioOptionChange = (event,id) => {
        console.info(event);
        switch (id) {
          case 'instructor':
              setInstructor(event.target.value);
              fetchData(event.target.value,selectedTeam,selectedLanguage,1);
              break;
          case 'team':
              setTeam(event.target.value);
              fetchData(selectedInstructor,event.target.value,selectedLanguage,1);
              break;
          case 'language':
              setLanguage(event.target.value);
              fetchData(selectedInstructor,selectedTeam,event.target.value,1);
              break;
          default:
              break;
        }
        dispatch(setPage(1));
      };

      useEffect(() => {
        fetchData();
		window.addEventListener('scroll', handleScroll);
        dispatch(getTeams());
        dispatch(getInstructors());
        dispatch(getAllTags());
        // eslint-disable-next-line
	}, [token, selectedTeam, selectedInstructor, selectedLanguage, tagsData]);

    const resetData = async () => {
        setInstructor(null)
        setTeam(null);
        setLanguage(null);
        setTags([]);
      }
    
        let setPagePromise = new Promise(function(resolve,reject) {
            if(dispatch(setPage(1)))
                resolve();
            else
                reject();
        });  
    
      const clearFilters = async () => {
        resetData().then(()=>{
            setPagePromise.then(
                function() {
                    setIsFetching(true);
                 },
                function(error) { console.log(error) }
            )
        })
      }

    useEffect(() => {
		if (!isFetching) return;
		fetchMoreListItems();
        // eslint-disable-next-line
	}, [isFetching]);

	const fetchMoreListItems = () => {
		fetchData();
		setIsFetching(false);
	};

    const fetchData = async (instructor,team,language,currentPage,tags) => {
        let data = {
            ...((instructor || selectedInstructor) && {"userId": instructor ? instructor : (selectedInstructor ? selectedInstructor : '')}),
            ...((team || selectedTeam) && {"typeId": team? team : (selectedTeam ? selectedTeam : '')}),
            ...((language || selectedLanguage) && {"language": language ? language : (selectedLanguage ? selectedLanguage : '')}),
            ...((tagsData && tagsData.length > 0) && {"tagIdList": tagsData}),
            "favourites": false,
            "page": currentPage ? currentPage : page,
        }
		setTimeout(async () => {
            await dispatch(getAllSessions(data));
            setIsFetching(false);
		}, 1000);
	};

    const handleScroll = () => {
		if (
			Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight ||
			isFetching
		)
			return;
		setIsFetching(true);
	};

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
    };

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
    };

    const expandFilterPanel = (id,show) =>{
        switch (id) {
          case 'instructor':
              setInstructorVisibility(() => {return show});
          break;
          case 'team':
              setTeamVisibility(() => {return show});
          break;
          case 'language':
              setLanguageVisibility(() => {return show});
          break;
          case 'tags':
            setTagsVisibility(() => {return show});
          break;
          default:
              break;
        }
    }

    window.addEventListener('scroll', toggleVisible);

    const createFilters = () => {
        return(
            <div>
                <Button color="primary" className="scroll-to-top-button" style={{display: visible ? 'inline' : 'none'}}>
                    <ArrowUpward onClick={scrollToTop} 
                    style={{display: visible ? 'inline' : 'none'}} />
                </Button>

                <p className='clear-filter'>{ (selectedInstructor || selectedTeam || selectedLanguage || tagsData.length > 0) && 
                    <Button size="small" onClick={clearFilters} variant="outlined" style={{borderRadius:'20px', fontSize:'10px', fontWeight:'bold', backgroundColor:'#404040', color:'#ffffff', borderColor:'#404040'}} startIcon={<NotInterestedIcon style={{fontSize:'16px', verticalAlign:'top'}}/>}>
                        <span>{t('Clear filters')}</span>
                    </Button>}
                </p>

                <FormControl component="fieldset" className='form-control'>
                    <FormLabel component="legend" className="filter-label">{t('Team')}
                        <Hidden mdUp>
                            {!showTeam ? <ExpandMoreIcon onClick={() => expandFilterPanel('team',true)} className="expand-icon"/>
                            : <ExpandLessIcon onClick={() => expandFilterPanel('team',false)} className="expand-icon"/>}
                        </Hidden>
                        <p className='clear-filter-btn'>{ ( selectedTeam ) && 
                            <Button size="small" onClick={() => {setTeam(null)}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                            startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                            </Button>}
                        </p>
                    </FormLabel>
                    <Select
                    labelId="demo-multiple-checkbox-label"
                    id='team'
                    value={selectedTeam ? selectedTeam : "Select"}
                    onChange={(event) => handleRadioOptionChange(event,'team')}
                    input={<OutlinedInput/>}
                    className={`${!showTeam && 'd-none'} filter-radio-group tags-input`}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        let namesArray=""
                            Teams.forEach(obj => {
                                if(selected===obj.id)
                                    namesArray = obj.type
                            })
                        return namesArray ? namesArray : "Select"
                    }}
                    >
                    {Teams && Teams.length > 0 &&
                        Teams.map((team, index) => {
                        return <MenuItem key={index} value={team.id}>
                        <ListItemText primary={t(team.type)} />
                        </MenuItem> 
                        }
                    )}
                    </Select>
                </FormControl>

                <FormControl component="fieldset" className='form-control'>
                <FormLabel component="legend" className="filter-label">{t('Tags')}
                    <Hidden mdUp>
                    {!showTags ? <ExpandMoreIcon onClick={() => expandFilterPanel('tags',true)} className="expand-icon"/>
                        : <ExpandLessIcon onClick={() => expandFilterPanel('tags',false)} className="expand-icon"/>}
                    </Hidden>
                    <p className='clear-filter-btn'>{ ( tagsData.length > 0 ) && 
                        <Button size="small" onClick={() => {setTags([])}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                        startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                        </Button>}
                    </p>
                </FormLabel>
                
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="tags"
                    multiple
                    value={tagsData && tagsData.length > 0 ? tagsData : ["Select"]}
                    onChange={handleTagsChange}
                    input={<OutlinedInput/>}
                    className={`${!showTags && 'd-none'} filter-radio-group tags-input`}
                    // renderValue={(selected) => selected ? setTagsNameData(selected) : "Select"}
                    renderValue={(selected) => {
                        return setTagsNameData(selected);
                    }}
                    MenuProps={MenuProps}
                    >
                    {tags.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={tagsData.indexOf(item.id) > -1} />
                        <ListItemText primary={t(item.name)} />
                        </MenuItem>
                    ))}
                </Select>
                        
                </FormControl>

                <FormControl component="fieldset" className='form-control'>
                    <FormLabel component="legend" className="filter-label">{t('Instructor')} 
                        <Hidden mdUp>
                            {!showInstructor ? <ExpandMoreIcon onClick={() => expandFilterPanel('instructor',true)} className="expand-icon"/>
                            : <ExpandLessIcon onClick={() => expandFilterPanel('instructor',false)} className="expand-icon"/>}
                        </Hidden>
                        <p className='clear-filter-btn'>{ ( selectedInstructor ) && 
                            <Button size="small" onClick={() => {setInstructor(null)}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                            startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                            </Button>}
                        </p>
                    </FormLabel>
                    <Select
                    id='instructor'
                    className={`${!showInstructor && 'd-none'} filter-radio-group tags-input`}
                    value={selectedInstructor ? selectedInstructor : "Select"}
                    onChange={(event) => handleRadioOptionChange(event,'instructor')}
                    input={<OutlinedInput/>}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        let namesArray=""
                            instructors.forEach(obj => {
                                if(selected===obj.id)
                                    namesArray = obj.firstName +' '+ obj.lastName
                            })
                        return namesArray ? namesArray : "Select"
                    }}
                    >
                    {instructors && instructors.length > 0 && 
                        instructors.map((instructor,index) => {
                            return <MenuItem key={index} value={instructor.id} onClick={(event) => event.target.value===selectedInstructor ? setInstructor(null) : null}>
                            <ListItemText primary={instructor.firstName +' '+ instructor.lastName} />
                            </MenuItem>
                    })}
                    </Select>
                </FormControl>
                
                <FormControl component="fieldset" className='form-control'>
                <FormLabel component="legend" className="filter-label">{t('Language')}
                    <Hidden mdUp>
                        {!showLanguage ? <ExpandMoreIcon onClick={() => expandFilterPanel('language',true)} className="expand-icon"/>
                        : <ExpandLessIcon onClick={() => expandFilterPanel('language',false)} className="expand-icon"/>}
                    </Hidden>
                    <p className='clear-filter-btn'>{ ( selectedLanguage ) && 
                        <Button size="small" onClick={() => {setLanguage(null)}} variant="outlined" style={{ color:'#ffffff', borderColor:'#404040'}} 
                        startIcon={<NotInterestedIcon style={{ right:0, marginRight:0 }} /> }>
                        </Button>}
                    </p>
                </FormLabel>
                <Select
                    id='language'
                    className={`${!showLanguage && 'd-none'} filter-radio-group tags-input`}
                    aria-label="Language"
                    value={selectedLanguage ? selectedLanguage : "Select"}
                    onChange={(event) => handleRadioOptionChange(event,'language')}
                    input={<OutlinedInput/>}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        return selected ? selected : "Select"
                    }}
                    >
                    <MenuItem value="da" onClick={(event) => event.target.value===selectedLanguage ? setLanguage(null) : null}>
                        <ListItemText primary={`${t('Danish')}`}></ListItemText>
                    </MenuItem>
                    <MenuItem value="en" onClick={(event) => event.target.value===selectedLanguage ? setLanguage(null) : null}>
                        <ListItemText primary={`${t('English')}`}></ListItemText>
                    </MenuItem>
                    
                </Select>
                </FormControl>
            </div>
        );
    };

    const handleDialogClose = () => {
        setOpenDeleteDialog(false);
        setSessionToDelete(null);
        setIncludeSessionVideos(false);
    }

    const handleDeleteSession = async () => {
        setOpenDeleteDialog(false);
        setIncludeSessionVideos(false);

        await userService.deleteSession({
            "id": sessionToDelete,
            "includeSessionVideos": includeSessionVideos
        }).then(
            (response) => {
                setDeleteResponse(response.data);
                setSnackbarOpen(true);
                dispatch(setPage(1));
            },
            (error) => {
                setDeleteResponse(error.response.data);
                setSnackbarOpen(true);
            }
        );
        fetchData();
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setIncludeSessionVideos(false);
    }

    const handleDeleteConfirmation = (sessionId) => {
        setOpenDeleteDialog(true);
        setSessionToDelete(sessionId);
        setIncludeSessionVideos(false);
    }

    return(
        <div>
            { isLoading ? <Spinner/> : null }
            
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={deleteResponse && deleteResponse.status  === 'SUCCESS' ? 'success' : 'error' } sx={{ width: '100%' }}>
                    {t(deleteResponse.status === 'SUCCESS' ? deleteResponse.successMessage : (deleteResponse.errors ? deleteResponse.errors[0] : (deleteResponse.data && deleteResponse.data.errors ? deleteResponse.data.errors[0] : 'Something went wrong, Please try again')))}
                </Alert>
            </Snackbar>

            <Grid container className="session-page">
            <Grid item xs={12} sm={12} md={3} lg={3}>
                {createFilters()}
            </Grid>
            
            <Grid item xs={12} sm={12} md={8.7} lg={8.7} className='session-list'>
                <p className='font-weight-bold video-block-title'>{t('Training Sessions')}</p>
                    <Grid container spacing={3}>
                        {(!sessions || sessions.length == 0) && 
                            <Grid item><p>{t('No Sessions Found')}</p></Grid>
                        }

                        {sessions.map((listItem, index) => {
                            return <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                
                                    <Card className="video-card" style={{position:'relative'}}>
                                        <div className='overlay'></div>
                                        <Link to={`/session-player/${listItem.id}`} style={{ textDecoration: 'none' }}>
                                            <button
                                                disabled={false}
                                                style={{
                                                display:'none'
                                                }}
                                                className="arrow-buttons play-button"
                                            >
                                                <PlayArrow />
                                            </button>
                                        </Link>
                                        <CardContent className='video-card-content'>
                                            <Suspense fallback={<img src={
                                                listItem.videoType === "Work Out" ? WorkOutImage : 
                                                listItem.videoType === "Yoga" ? YogaImage : 
                                                listItem.videoType === "Active Breaks" ? ActiveBreaksImage : 
                                                listItem.videoType === "Respiratory Training" ? RespiratoryTrainingImage : ''
                                            } alt='Avatar' className='video-slider-media' />}>
                                                <img src={
                                                    listItem.videoType === "Work Out" ? WorkOutImage : 
                                                    listItem.videoType === "Yoga" ? YogaImage : 
                                                    listItem.videoType === "Active Breaks" ? ActiveBreaksImage : 
                                                    listItem.videoType === "Respiratory Training" ? RespiratoryTrainingImage : ''
                                                } alt='Avatar' className='video-slider-media' />
                                            </Suspense>
                                            <p className='video-scroll-menu-text font-weight-bold'>{t(listItem.sessionTitle)}</p>
                                            <p className='video-scroll-menu-text font-weight-bold'>{t(listItem.videoType)}</p>
                                            <p className='video-scroll-menu-text'>{`${listItem.firstName} ${listItem.lastName}`}</p>
                                            <Tooltip title={listItem.tags && listItem.tags.map((el) => el && el.name).join(',')}>
                                                <p className='video-scroll-menu-text ellipsis' style={{fontSize: "10px"}}>
                                                    {
                                                        listItem.tags && listItem.tags.map((item, index) => {
                                                            return item && <Badge className='tag-badge'>{t(item.name)}</Badge>
                                                        }) 
                                                    }    
                                                </p>
                                            </Tooltip>
                                            
                                        </CardContent>
                                        <CardActions>
                                            {userDetails && isLoggedIn && role === "Admin" &&
                                            <Box sx={{display:`flex`,justifyContent:`flex-end`,width:`100%`}}>
                                                <Link to={`/sessions/${listItem.id}`} style={{ textDecoration: 'none' }}>
                                                    <IconButton >
                                                        <EditIcon color='#51c0db' />
                                                    </IconButton>
                                                </Link>
                                                <IconButton onClick={() => handleDeleteConfirmation(listItem.id)}><DeleteIcon color='#51c0db' /></IconButton>
                                            </Box>
                                            }
                                            
                                        </CardActions>
                                    </Card> 
                                
                            </Grid>
                        })}
                        </Grid>
                    {isFetching && <h3>Fetching more videos...</h3>}
            </Grid>
            </Grid>
            <Dialog open={openDeleteDialog} onClose={handleDialogClose}>
                <DialogTitle>{t('Delete Session')}</DialogTitle>
                <DialogContent>
                    <p>{t('Are you sure you want to delete this session?')}</p>
                    <FormGroup>
                        <FormControlLabel 
                            control={<Checkbox checked={includeSessionVideos} 
                            onChange={(event) => setIncludeSessionVideos(event.target.checked)} />} 
                            label={t('Include Session Videos')} 
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>
                        {t('Cancel')}
                    </Button>   
                    <Button onClick={handleDeleteSession}>
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Sessions