import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import userService from "../services/user.service";

export const getAllTags = createAsyncThunk(
  "allTags/getAllTags",
  async (queryParams, thunkAPI) => {
    try {
      const response = await userService.getTags();
      thunkAPI.dispatch(setMessage(response.data));
      return response.data.details;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = { tags:[] };

const allTagsSlice = createSlice({
  name: "allTags",
  initialState,
  extraReducers: {
    [getAllTags.fulfilled]: (state, action) => {
      state.tags = action.payload;
    },
    [getAllTags.rejected]: (state, action) => {
    }
  },
});

const { reducer } = allTagsSlice;
export default reducer;
