import { Alert, Button, Container, Grid, Snackbar, Table, TableHead, TableCell, TableContainer,TableRow, Paper, TableBody  } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import './Admin-dashboard.css';
import userService from '../../services/user.service';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../slices/auth';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function AdminDashboard() {
    const {t} = useTranslation();
    const [instructors, setInstructors] = useState([]);
    const [open, setOpen] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({status:'',successMessage:'',errors:[]});
    const dispatch = useDispatch();
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    useEffect(() => {
        userService.getInstructors()
          .then((response) => {
            setInstructors(response.data.details);
          })
          .catch((error) => {
            console.log(error);
          });
    },[uploadResponse]);

    const deleteInstructor = (userId) => {
        dispatch(deleteUser(userId))
        .unwrap()
        .then((response) => {
            setUploadResponse(response);
            setOpen(true);
        })
        .catch((error) => {
            setUploadResponse(error);
            setOpen(true);
        });
    };

    return (
            <div style={{paddingBottom:'5em'}}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={uploadResponse && uploadResponse.status === 'SUCCESS' ? 'success' : 'error'} sx={{ width: '100%' }}>
                        {t(uploadResponse.status === 'SUCCESS' ? uploadResponse.successMessage : (uploadResponse.errors ? uploadResponse.errors[0] : (uploadResponse.data && uploadResponse.data.errors ? uploadResponse.data.errors[0] : 'Something went wrong, Please try again.')))}
                    </Alert>
                </Snackbar>
                <Container maxWidth="md">
                    <div className='user-detail-block'>
                        <p className='users-section-title'>{t('Users')} :</p>
                        {(!instructors || instructors.length === 0) &&
                            <p>{t('No Records Found')}</p>
                        }
                        <TableContainer component={Paper}>
                            <Table>
                                <TableBody>
                                {instructors && instructors.length > 0 && 
                                    instructors.map((element,index) => {
                                        // return (
                                        //     <Grid container key={`instructor${index}`} spacing={3} className='user-detail-row align-items-center'>
                                        //         <Grid item xs={8} sm={8} md={9} lg={9}>
                                        //             {element.firstName + ' ' + element.lastName}
                                        //         </Grid>
                                        //         <Grid item xs={4} sm={4} md={3} lg={3}>
                                        //             <Button className="remove-user-button" onClick={() => deleteInstructor(element.id)}>{t('Remove')}</Button>
                                        //         </Grid>
                                        //     </Grid>
                                        // );
                                        return (
                                            <TableRow key={`instructor${index}`}>
                                                <TableCell component="th" scope="row">
                                                    {element.firstName + ' ' + element.lastName}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Link to={`/user/${element.id}`} >
                                                        <Button className="edit-user-button" >
                                                            <EditIcon />
                                                        </Button>
                                                    </Link>
                                                    <Button onClick={() => deleteInstructor(element.id)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        

                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p className='users-section-title'>{t('Create New User')} :</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Link to='/add-user' className='router-link'>
                                {t('Create New User')}
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    
};

export default AdminDashboard;
