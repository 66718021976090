import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Spinner from '../ProgressBar/Spinner';
import { sendResetPasswordLink } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import "./ForgotForm.css";

function ForgotForm() {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
      username: ''
  });
  const [open, setOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({status:'', genericMessage:'', errors:''});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  

    const handleForgotPass = (event) => {
        event.preventDefault();
        if(!values.username){
            setOpen(true);
            setUploadResponse({status:'error',errors: ['FORM_FIELD_DATA_MISSING']});
            return;
        }
        setLoading(true);
        dispatch(sendResetPasswordLink({userDto: [{Email: values.username}]}))
          .unwrap()
          .then((response) => {
            setUploadResponse(response);
            setOpen(true);
            setLoading(false);
            setIsFormSubmitted(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setUploadResponse(error)
            setOpen(true);
            setIsFormSubmitted(false);
          });
    }

    const handleInputChange = (event) => {
      let formValues = values;
      switch (event.target.id) {
        case 'email':
          formValues.username = event.target.value;
          break;
        default:
          break;
      }
      setValues(formValues);
    };

    return (
      <div>
        { loading?<Spinner/>:null }
        <div className="forgotForm">
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={uploadResponse && uploadResponse.status === 'SUCCESS' ? 'success' : 'error'} sx={{ width: '100%' }}>
                  {t(uploadResponse.genericMessage ? uploadResponse.genericMessage : uploadResponse.errors ? uploadResponse.errors[0]: "Something went wrong, Please try again.")}
              </Alert>
          </Snackbar>

            <div className="forgotFormContent">
                {
                  !isFormSubmitted && 
                  <form onSubmit={handleForgotPass} autoComplete='off'>
                    <h1>Forgot Password</h1>
                    <input type="email" placeholder="Email" id='email' onChange={handleInputChange} autoComplete='new-email' required />
                    <button type="submit">Reset Password</button>
                  </form>
                }

                {
                  isFormSubmitted && 
                  <form>
                    <h1>E-Mail Sent!</h1>
                    <p>Please check your inbox {values.username} for further instructions!</p>
                  </form>
                }
            </div>
            <div className="forgotFormFadeBottom" />
        </div>
      </div>
        
    )
}

export default ForgotForm
