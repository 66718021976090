import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

// views
import LoginScreen from '../screens/Login/LoginScreen';
import HomeScreen from '../screens/Homescreen/HomeScreen';
import ForgotScreen from '../screens/Forgot/ForgotScreen';
import ResetScreen from '../screens/Reset/ResetScreen';
import AutoLogin from '../screens/Login/AutoLogin';

function PublicRoute (){

  let location=useLocation();

  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [location]); 

    return (
      <Switch>
        <Route exact path="/homepage" component={HomeScreen} />
        <Route exact path="/login" component={LoginScreen} />
        <Route exact path="/forgot-password" component={ForgotScreen} />
        <Route exact path="/reset-password" component={ResetScreen} />
        <Route exact path="/auto-login" component={AutoLogin} />
        <Redirect to="/homepage" />
      </Switch>
    );
}

export default PublicRoute;