import React, { useEffect } from 'react';
import "./ForgotScreen.css";

// Billeder
import Footer from '../../components/Footer/Footer';
import NavForm from '../../components/Navbar/NavForm';
import ForgotForm from '../../components/Forgot/ForgotForm';


function ForgotScreen() {
    useEffect(() => {
        document.title = `Glemt adgangskode`;
      });

    return (
        <div className="forgotScreen">
            <NavForm />
            <ForgotForm />
            <Footer />
        </div>  
    );
}

export default ForgotScreen;
