import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import userService from "../services/user.service";

export const getInstructors = createAsyncThunk(
  "uploadData/getInstructors",
  async (queryParams, thunkAPI) => {
      
    try
    {
        const response=await userService.getInstructors();
        return response.data;
    }
    catch(error) {
        thunkAPI.dispatch(setMessage(error.response));
    };
  }
);

export const getTeams = createAsyncThunk(
  "uploadData/getTeams",
  async (queryParams, thunkAPI) => {
      
    try
    {
        const response=await userService.getTeams();
        return response.data;
    }
    catch(error) {
      thunkAPI.dispatch(setMessage(error.response));  
    };
  }
);

export const getVideoSubType = createAsyncThunk(
  "uploadData/getVideoSubType",
  async (queryParams, thunkAPI) => {      
    try
    {
        const response=await userService.getVideoSubType(queryParams);
        return response.data;
    }
    catch(error) {
      thunkAPI.dispatch(setMessage(error.response));  
    };
  }
);

const initialState = { teams:[], instructors:[], subtypes:[], isLoading:true };

const uploadDataSlice = createSlice({
  name: "uploadData",
  initialState,
  extraReducers: {
    [getTeams.fulfilled]: (state, action) => {
        state.teams = [...action.payload.details];
        state.isLoading = false;
    },
    [getTeams.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getInstructors.fulfilled]: (state, action) => {
        state.instructors = [...action.payload.details];
        state.isLoading = false;
    },
    [getInstructors.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getVideoSubType.fulfilled]: (state, action) => {
        state.subtypes = action.payload ? [...action.payload.details] : [];
        state.isLoading = false;
    },
    [getVideoSubType.rejected]: (state, action) => {
      state.isLoading = false;
    }
  }
});

const { reducer } = uploadDataSlice;
export default reducer;